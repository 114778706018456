<template>
  <div>
    <div class="col-md-12 pt-3 d-flex">
      <button
        class="jem-btn-primary ml-auto mb-3 mr-3"
        @click="showBookModal = true;"
      >Book Appointment</button>

      <candidate-booked-services-modal
        v-if="showBookModal"
        @booked="onBooking"
        @close="showBookModal = false"
        :candidate="candidate"
        :calendars="calendars"
        :services="services"
      ></candidate-booked-services-modal>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Description</th>
            <th>Notes</th>
            <th>Location</th>
            <th style="min-width:250px">Appointment Time</th>
            <th>Booked By</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in candidate_services" :key="index">
            <td>
              <span>{{service.name}}</span>
            </td>
            <td>
              <span>{{service.description || 'N/A'}}</span>
            </td>
            <td>
              <span>{{service.notes || 'N/A'}}</span>
            </td>
            <td>
              <span>{{service.location || 'N/A'}}</span>
            </td>
            <td>
              <span>
                <el-date-picker
                  class="w-100"
                  v-model="service.local_appointment_time"
                  @change="updateBookingToUtc(service)"
                  :picker-options="dateOptions"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:00"
                  format="dd/MM/yyyy HH:mm"
                  placeholder="Select date and time"
                ></el-date-picker>
              </span>
            </td>
            <td>
              <span>{{service.user.full_name}}</span>
            </td>
            <td>
              <span>{{service.booked_service_status.name}}</span>
            </td>
            <td class="d-flex">
              <span class="red-icon-btn" @click="onDelete(service)">
                <i class="far fa-trash-alt"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Candidate from "../../../models/Candidate";
import BookedService from "../../../models/BookedService";

export default {
  props: {
    services: {
      type: Array
    },
    candidate: {
      type: Object
    },
    calendars: {
      type: Array
    }
  },
  data() {
    return {
      candidate_services: this.candidate.booked_services || [],
      showBookModal: false,
      dateOptions: {
        start: "08:30",
        step: "00:15",
        end: "18:30",
        disabledDate(time) {
          return time.addDays(1).getTime() < Date.now();
        }
      },
    };
  },
  methods: {
    async onBooking(candidate_services) {
      this.candidate_services = await BookedService.include(
        "user",
        "candidate",
        "booked-service-status"
      )
        .for(new Candidate({ id: this.candidate.id }))
        .get();
    },
    onDelete(passed_booking) {
      let index = this.candidate_services.indexOf(passed_booking);
      let booking = new BookedService(passed_booking).for(
        new Candidate(this.candidate)
      );
      booking.delete().then(res => {
        this.$message({
          type: "success",
          message:
            "Booking removed. Please allow a few minutes for this to be reflected in Acuity Scheduling"
        });
        this.candidate_services.splice(index, 1);
      });
    },
    updateBookingToUtc(booking) {
      this.$confirm('This will change the appointment time in Acuity. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          let index = this.candidate_services.indexOf(booking);
          let calendar = this.calendars.filter(calendar => calendar.id == booking.calendar_id)[0];
          this.candidate_services[index].location = calendar.timezone
          this.candidate_services[index].appointment_time = moment.tz(
            booking.local_appointment_time,
            "YYYY-MM-DD HH:mm:ss",
            calendar.timezone
            )
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
            let booked_service = new BookedService(this.candidate_services[index]).for(
              new Candidate(this.candidate)
            );
            booked_service.save().then((res) => {
              this.$message({
                type: 'success',
                message: 'Updated! This may take a few minutes to be reflected in acuity'
              });  
            })
            
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Was not updated in acuity'
          });          
        });
      
    },
  }
};
</script>

<style>
</style>
