<template>
    <div>
        <h2 class="mb-4 mt-5 pt-3">Notes</h2>
        <div class="mt-4">
            <textarea
                class="form-control"
                v-model="candidate.notes"
                row="5"
            ></textarea>
            <button
                @click="onSubmit"
                :disabled="disabled"
                class="mt-2 btn btn-lg btn-primary"
            >
                Save
            </button>
        </div>
    </div>
</template>
<script>
import Candidate from "../../models/Candidate";

export default {
    props: {
        candidate_id: {
            type: Number,
        },
        initial_notes: {
            type: String,
        },
    },
    data() {
        return {
            candidate: new Candidate({
                id: this.candidate_id,
                notes: this.initial_notes,
            }),
            disabled: false,
        };
    },
    methods: {
        onSubmit() {
            this.candidate.save().then((res) => {
                this.$message({
                    type: "success",
                    message: "Saved",
                });
                this.disabled = true;
            });
        },
    },
};
</script>
