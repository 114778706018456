<template>
  <div id="mk-candidate-index">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="">Candidates</h1>
      <a class="jem-btn-primary" href="/candidates/create">Book A Candidate</a>
    </div>

    <div v-if="user.company_id">
      <div class="card">
        <div class="card-body">
          <div class="row mt-3 mx-3">
            <div class="col-md-12 px-0">
              <div class="d-flex flex-wrap align-items-baseline justify-center mt-3">
                <h3>Quick filters:</h3>
                <button
                  @click="quickFilter(-1)"
                  :style="{
                    'background-color': selectedQuickFilter == -1 ? '#DBE640' : 'inherit'
                  }"
                  class="filterButton ml-3 mb-3"
                >
                  Open Candidates Only
                </button>
                <button
                  v-if="this.roleName !== 'recruiter'"
                  @click="quickFilter(0)"
                  :style="{
                    'background-color': selectedQuickFilter == 0 ? '#DBE640' : 'inherit'
                  }"
                  class="filterButton ml-3 mb-3"
                >
                  My Candidates
                </button>
                <button
                  v-for="department in user.departments"
                  :key="department.id"
                  @click="quickFilter(department.id)"
                  :style="{
                    'background-color': selectedQuickFilter == department.id ? '#DBE640' : 'inherit'
                  }"
                  class="filterButton ml-3 mb-3"
                >
                  {{ department.name }}
                </button>
              </div>
              <button class="jem-btn-primary" type="text" @click="dialogVisible = true">
                More filters
              </button>
              <div
                v-if="
                  selectedDepartments.length > 0 ||
                  selectedRecruiters.length > 0 ||
                  selectedCandidateStatus
                "
                class="d-flex flex-wrap align-items-baseline justify-center mt-3"
              >
                <h3>Filters applied:</h3>
                <!-- DEPARTMENT BUTTONS -->
                <el-button
                  v-for="department in selectedDepartments"
                  @click="handleRemoveDepartment(department.id)"
                  class="selectedFilter ml-3"
                  :key="department.id"
                >
                  {{ department.name }}
                </el-button>
                <!-- RECRUITER BUTTONS -->
                <el-button
                  v-for="recruiter in selectedRecruiters"
                  @click="handleRemoveRecruiter(recruiter.id)"
                  class="selectedFilter ml-3"
                  :key="recruiter.id"
                >
                  {{ recruiter.full_name }}
                </el-button>
                <!-- CANDIDATESTATUS BUTTON -->
                <el-button
                  v-if="selectedCandidateStatus"
                  class="selectedFilter ml-3"
                  @click="handleClearSelectedCandidateStatus"
                >
                  <span v-if="selectedCandidateStatus">
                    {{ selectedCandidateStatus.name }}
                  </span>
                </el-button>
                <!-- CLEAR ALL -->
                <el-button type="text" @click="handleClearAll">Clear all</el-button>
              </div>
              <hr />
            </div>
          </div>

          <paginated-table
            v-if="candidates.data"
            class="jem-paginated-table"
            :tableData="tableData"
            :paginatedData="candidates"
            @pagination-change-page="getCandidates"
          />
        </div>
      </div>
    </div>
    <!-- end company -->

    <div v-else>
      <div class="row mt-3 mx-3">
        <div class="col-md-12">
          <p>Please await to be linked to a company before booking a candidate.</p>
        </div>
      </div>
    </div>

    <!-- FILTER DIALOG -->
    <el-dialog title="Filters" :visible.sync="dialogVisible" width="30%">
      <!-- BOOKING -->
      <div class="form-group col-12">
        <label for="booking">Candidate Completion Status</label>
        <el-select id="booking" v-model="showAll" placeholder="Status" class="w-100">
          <el-option :key="0" :label="'Open Candidates'" :value="false"></el-option>
          <el-option :key="1" :label="'All Candidates'" :value="true"></el-option>
        </el-select>
      </div>

      <!-- DEPARTMENTS -->
      <div class="form-group col-12">
        <label for="departments">Department(s)</label>
        <el-select
          id="departments"
          multiple
          v-model="selectedDepartmentsIds"
          placeholder="Departments"
          class="w-100"
        >
          <el-option
            v-for="department in user.departments"
            :key="department.id"
            :label="department.name"
            :value="department.id"
            >{{ department.name }}</el-option
          >
        </el-select>
      </div>

      <!-- RECRUITERS -->
      <div class="form-group col-12">
        <label for="recruiters">Recruiters</label>
        <el-select
          id="recruiters"
          multiple
          v-model="selectedRecruiterIds"
          placeholder="Recruiters"
          class="w-100"
          filterable
        >
          <el-option
            v-for="recruiter in this.recruiters"
            :key="recruiter.id"
            :label="recruiter.full_name"
            :value="recruiter.id"
            >{{ recruiter.full_name }}</el-option
          >
        </el-select>
      </div>

      <!-- STATUS -->
      <div class="form-group col-12">
        <label for="status">Status</label>
        <el-select
          id="status"
          v-model="selectedCandidateStatusId"
          placeholder="Status"
          class="w-100"
        >
          <el-option
            v-for="status in this.candidatestatus"
            :key="status.id"
            :label="status.name"
            :value="status.id"
            >{{ status.name }}</el-option
          >
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelModeFilters">Cancel</el-button>
        <el-button type="primary" @click="handleMoreFilter">Apply</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Candidate from '../../models/Candidate'

export default {
  mounted() {
    this.getCandidates()
  },
  props: {
    user: {
      type: Object
    },
    recruiters: {
      type: Array
    },
    candidatestatus: {
      type: Array
    },
    roleName: {
      type: String
    }
  },
  beforeMount() {
    if (this.getRoleByName('recruitment manager')) {
      this.tableData = {
        columns: [
          { label: 'Full Name', name: 'full_name', sortable: true },
          { label: 'Department', name: 'department.name', sortable: true },
          {
            label: 'Recruiter',
            name: 'user.full_name',
            sortable: false
          },
          { label: 'Email', name: 'email', sortable: true },
          {
            label: 'Booking Time',
            name: 'latest_booking_time',
            sortable: false
          },
          {
            label: 'Priority',
            name: 'priority',
            sort_name: 'urgent',
            sortable: true
          },
          { label: 'PO Number', name: 'po_number', sortable: true },
          { label: 'Site Code', name: 'site_code', sortable: true },
          {
            label: 'Status',
            name: 'candidate_status.name',
            sortable: true
          }
        ],
        showSearch: true,
        showPerPage: true,
        viewUrl: '/candidates'
      }
    }
  },
  data() {
    return {
      selectedCandidateStatusId: null,
      selectedCandidateStatus: null,
      selectedRecruiterIds: [],
      selectedRecruiters: [],
      selectedDepartmentsIds: [],
      selectedDepartments: [],
      selectedQuickFilter: null,
      dialogVisible: false,
      candidates: {},
      showAll: true,
      allCompany: false,
      tableData: {
        columns: [
          { label: 'Full Name', name: 'full_name', sortable: true },
          { label: 'Department', name: 'department.name', sortable: true },
          { label: 'Email', name: 'email', sortable: true },
          {
            label: 'Booking Time',
            name: 'latest_booking_time',
            sortable: false
          },
          {
            label: 'Priority',
            name: 'priority',
            sort_name: 'urgent',
            sortable: true
          },
          { label: 'PO Number', name: 'po_number', sortable: true },
          { label: 'Site Code', name: 'site_code', sortable: true },
          {
            label: 'Status',
            name: 'candidate_status.name',
            sortable: true
          }
        ],
        showSearch: true,
        showPerPage: true,
        viewUrl: '/candidates'
      }
    }
  },
  methods: {
    handleClearSelectedCandidateStatus() {
      this.selectedCandidateStatus = null
      this.getCandidates(
        1,
        null,
        null,
        15,
        false,
        this.selectedDepartmentsIds,
        this.selectedRecruiterIds
      )
    },
    handleClearAll() {
      this.selectedRecruiterIds = []
      this.selectedDepartmentsIds = []
      this.selectedCandidateStatusId = null
      this.getCandidates(1, null, null, 15, false, this.selectedDepartmentsIds)
      this.updateSelectedDepartments()
      this.updateSelectedRecruiters()
      this.updateSelectedCandidateStatus()
    },
    handleRemoveDepartment(departmentId) {
      const index = this.selectedDepartmentsIds.indexOf(departmentId)
      if (index !== -1) {
        this.selectedDepartmentsIds.splice(index, 1)
      }
      this.getCandidates(
        1,
        null,
        null,
        15,
        false,
        this.selectedDepartmentsIds,
        this.selectedRecruiterIds
      )
      this.updateSelectedDepartments()
    },
    handleRemoveRecruiter(recruiterId) {
      const index = this.selectedRecruiterIds.indexOf(recruiterId)
      if (index !== -1) {
        this.selectedRecruiterIds.splice(index, 1)
      }
      this.getCandidates(
        1,
        null,
        null,
        15,
        false,
        this.selectedDepartmentsIds,
        this.selectedRecruiterIds
      )
      this.updateSelectedRecruiters()
    },
    handleCancelModeFilters() {
      this.dialogVisible = false
      // this.selectedDepartmentsIds = []
    },
    handleMoreFilter() {
      this.selectedQuickFilter = null
      this.getCandidates(
        1,
        null,
        null,
        15,
        false,
        this.selectedDepartmentsIds,
        this.selectedRecruiterIds,
        this.selectedCandidateStatusId
      )
      this.updateSelectedDepartments()
      this.updateSelectedRecruiters()
      this.updateSelectedCandidateStatus()
      this.dialogVisible = false
    },
    updateSelectedCandidateStatus() {
      this.selectedCandidateStatus = this.candidatestatus.find(
        (c) => c.id === this.selectedCandidateStatusId
      )
    },
    updateSelectedDepartments() {
      this.selectedDepartments = this.user.departments.filter((d) =>
        this.selectedDepartmentsIds.includes(d.id)
      )
    },
    updateSelectedRecruiters() {
      this.selectedRecruiters = this.recruiters.filter((r) =>
        this.selectedRecruiterIds.includes(r.id)
      )
    },
    quickFilter(filterId) {
      this.selectedDepartmentsIds = []
      this.selectedDepartments = []

      this.selectedQuickFilter = this.selectedQuickFilter === filterId ? null : filterId
      this.getCandidates(1, null, null, 15, false, [this.selectedQuickFilter])
    },
    getRoleByName(name) {
      let permission = false
      if (this.user) {
        permission = this.user.roles.find((permission) => {
          return permission.name === name
        })
      }

      return permission
    },
    getCandidates(
      page = 1,
      sortColumn = null,
      query = null,
      per_page = 15,
      desc = false,
      department_ids = [],
      recruiter_ids = [],
      candidate_status_id = null
    ) {
      let candidates = Candidate.page(page)
        .include('user', 'candidateStatus', 'department', 'bookingTypes')
        .append('latest_booking_time')
        .orderBy('candidate_latest_booking_time')

      // department_id === 0 means return only my candidates
      if (department_ids.length > 0) {
        candidates.where(
          'candidates.department_id',
          department_ids.filter((id) => id !== 0 && id !== -1).join(',')
        )
      }
      if (department_ids.includes(0)) {
        candidates.where('user_id', this.user.id)
      }

      if (recruiter_ids.length > 0) {
        candidates.where('user_id', recruiter_ids.join(','))
      }

      if (candidate_status_id) {
        candidates.where('candidates.candidate_status_id', candidate_status_id)
      } else {
        if (this.showAll) {
          candidates.where('candidates.candidate_status_id', '1,2,3,4,5,6,7,8')
        } else {
          candidates.where('candidates.candidate_status_id', '1,2,3')
        }
      }

      if (desc && sortColumn) {
        candidates.orderBy('-' + (sortColumn.sort_name || sortColumn.name))
      } else if (sortColumn) {
        candidates.orderBy(sortColumn.sort_name || sortColumn.name)
      }

      if (query) {
        candidates.where('where_like', query)
      }
      // department_id === -1 return all open candidates
      if (department_ids.includes(-1)) {
        candidates.where('candidates.candidate_status_id', '1,2,3')
      }

      candidates.get().then((res) => {
        this.candidates = res
      })
    }
  }
}
</script>

<style>
.filterButton {
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--Border-500, #dcdcdc);
  background: var(--White, #fff);
}

.selectedFilter {
  border-radius: 5px;
  border: 1px solid var(--Border-500, #dcdcdc);
  background: #f4f4f5;
  padding: 4px;
}

label {
  display: block;
}
</style>
