import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import DoctorReviewReport from '../../backend/models/DoctorReviewReport';

const useEditReport = () => {
    const editReport = async (description, doctorReview, selectedFiles, filesToDelete, report, setReports, reports, onClose) => {
        const formData = new FormData();
        formData.append('description', description);
        formData.append('doctor_review_id', doctorReview.id);
        formData.append('_method', 'PUT');

        selectedFiles.forEach((item, index) => {
            if (item instanceof File) {
                formData.append(`selectedFiles[${index}]`, item)
            }
        });

        filesToDelete.forEach((file, index) => {
            formData.append(`filesToDelete[${index}]`, file?.id)
            formData.append('filesToDelete[]', file.id);
        });

        try {
            const response = await axios.post(`/api/v1/admin/doctor-review-reports/${report.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const updatedReports = reports.map((r) => (r.id === report.id ? response.data : r));
            setReports(updatedReports);

            onClose();
        } catch (error) {
        }
    };

    return editReport;
};

export default useEditReport;
