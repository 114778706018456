/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '@coreui/coreui'
import '../bootstrap';
import '../plugins';
import { Model } from 'vue-api-query'
import {Tabs, Tab} from 'vue-tabs-component';


// inject global axios instance as http client to Model
Model.$http = axios



Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.component('candidate-timeline', require('../global/layouts/timeline/CandidateTimeline.vue').default)
Vue.component('paginated-table', require('../global/layouts/PaginatedTable.vue').default)
Vue.component('base-table', require('../global/layouts/BaseTable.vue').default)
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('pagination', require('laravel-vue-pagination'));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});