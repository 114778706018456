<template>
  <paginated-table
    v-if="locations.data"
    :tableData="tableData"
    :paginatedData="locations"
    @pagination-change-page="getLocations"
  />
</template>

<script>
import Location from "../../models/Location";

export default {
  mounted() {
    this.getLocations();
  },
  data() {
    return {
      locations: {},
      tableData: {
        columns: [
          { label: "Title", name: "title", sortable: true },
        ],
        showSearch: true,
        showPerPage: true,
        edit:true,
        viewUrl: "/admin/locations"
      }
    };
  },
  methods: {
    getLocations(page = 1, sortColumn = null, query = null, per_page = 15, sortDirection = 'desc') {
      let locations = Location.page(page).params({per_page:per_page});
      
      if (sortColumn) {
        locations.orderBy(sortColumn.name);
        locations.params({'sort_direction':sortDirection})
      } else {
        locations.orderBy('title')
      }
      if (query) {
        locations.where("where_like", query);
      }
      locations.get().then(response => {
        this.locations = response;
      });
    }
  }
};
</script>