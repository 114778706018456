<template>
  <paginated-table
    v-if="recruiters.data"
    :tableData="tableData"
    :paginatedData="recruiters"
    @pagination-change-page="getRecruiters"
  />
</template>

<script>
import User from "../../models/User";

export default {
  mounted() {
    this.getRecruiters();
  },
  data() {
    return {
      recruiters: {},
      tableData: {
        columns: [
          { label: "Name", name: "full_name", sortable: true },
          { label: "Email", name: "email", sortable: true },
          { label: "Company", name: "company.name", sortable: true },
          { label: "Open Candidates", name: "open_candidates_count", sortable: true },
          { label: "Closed Candidates", name: "closed_candidates_count", sortable: true }
        ],
        showSearch: true,
        edit:true,
        showPerPage: true,
        viewUrl: "/admin/recruiters"
      }
    };
  },
  methods: {
    getRecruiters(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
      let recruiters = User.page(page).include('company').params({per_page:per_page}).where('role', 'recruiter').append('open_candidates_count', 'closed_candidates_count');
      
      if(desc && sortColumn) {
        recruiters.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        recruiters.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        recruiters.orderBy('first_name');
      }
      
      if (query) {
        recruiters.where("where_like", query);
      }
      recruiters.get().then(response => {
        this.recruiters = response;
      });
    }
  }
};
</script>