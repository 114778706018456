<template>
  <div id="mk-fe-candidate-form">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <h1 class="mb-3">Book Candidate</h1>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="isSubmitted" class="candidate-spinner">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="row mt-4 mb-5">
          <div class="col-md-12">
            <el-steps :active="active" align-center>
              <el-step title="Details" description="Tell us about the candidate"></el-step>
              <el-step title="Services" description="What do we need to do"></el-step>
            </el-steps>
          </div>
        </div>

        <div class="alert alert-danger" v-if="form.errors.has('booking_types')" role="alert">
          You must choose at least 1 service for this candidate!
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <transition name="fade" mode="out-in">
          <div v-if="active == 1" key="stepone" style="min-height: 500px" class="mb-2">
            <div class="row">
              <div class="form-group col-12 col-md-4">
                <label for="name">First Name</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="first_name"
                    :class="[
                      {
                        'is-invalid': form.errors.has('first_name')
                      },
                      'form-control'
                    ]"
                    id="first-name-input"
                    @keydown="form.errors.clear('first_name')"
                    v-model="candidate.first_name"
                    placeholder="First Name"
                  />
                  <div class="invalid-feedback">Please enter a name.</div>
                </div>
              </div>

              <!-- Last name -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Last Name</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="last_name"
                    :class="[
                      {
                        'is-invalid': form.errors.has('last_name')
                      },
                      'form-control'
                    ]"
                    id="last_name-input"
                    @keydown="form.errors.clear('last_name')"
                    v-model="candidate.last_name"
                    placeholder="Name"
                  />
                  <div class="invalid-feedback">Please enter a last name.</div>
                </div>
              </div>

              <!-- Date Of Birth -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Date of Birth</label>
                <div class="input-group">
                  <input
                    type="date"
                    name="dob"
                    :class="[
                      {
                        'is-invalid': form.errors.has('dob')
                      },
                      'form-control'
                    ]"
                    id="dob"
                    @keydown="form.errors.clear('dob')"
                    v-model="candidate.dob"
                    placeholder="Date of Birth"
                  />
                  <div class="invalid-feedback">Please provide a valid date of birth value</div>
                </div>
              </div>

              <!-- Phone number input -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Number</label>
                <div class="input-group">
                  <vue-tel-input
                    @onValidate="onNumberValidate"
                    @onInput="form.errors.clear('number')"
                    :class="[
                      {
                        'is-invalid': form.errors.has('number') || (!validated && candidate.number)
                      },
                      'w-100'
                    ]"
                    :onlyCountries="['AU', 'GB', 'NZ', 'US']"
                    v-model="candidate.number"
                  ></vue-tel-input>
                  <small
                    class="text-red"
                    v-if="form.errors.has('number') || (!validated && candidate.number)"
                    >Please provide a valid number</small
                  >
                </div>
              </div>

              <!-- email -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Email</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="email"
                    :class="[
                      {
                        'is-invalid': form.errors.has('email')
                      },
                      'form-control'
                    ]"
                    id="email-input"
                    @keydown="form.errors.clear('email')"
                    v-model="candidate.email"
                    placeholder="Email"
                  />
                  <div class="invalid-feedback">Please provide a valid email</div>
                </div>
              </div>

              <div class="form-group col-12 col-md-4">
                <label for="name">Position Applied For</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="last_name"
                    :class="[
                      {
                        'is-invalid': form.errors.has('position_applied_for')
                      },
                      'form-control'
                    ]"
                    id="position_applied_for-input"
                    @keydown="form.errors.clear('position_applied_for')"
                    v-model="candidate.position_applied_for"
                  />
                  <div class="invalid-feedback">Please enter a valid position.</div>
                </div>
              </div>

              <!-- PO Number -->
              <div class="form-group col-12 col-md-4">
                <label for="name">PO Number</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="email"
                    :class="[
                      {
                        'is-invalid': form.errors.has('po_number'),
                        'is-disabled': isPoNumberDisabled
                      },
                      'form-control'
                    ]"
                    id="email-input"
                    @keydown="form.errors.clear('po_number')"
                    v-model="candidate.po_number"
                    :placeholder="poNumberPlaceholder"
                    :disabled="isPoNumberDisabled"
                  />
                  <div class="invalid-feedback">Please provide a valid PO Number</div>
                </div>
              </div>

              <!-- Site code -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Site Code</label>
                <div class="input-group">
                  <input
                    type="text"
                    name="email"
                    :class="[
                      {
                        'is-invalid': form.errors.has('site_code')
                      },
                      'form-control'
                    ]"
                    id="email-input"
                    @keydown="form.errors.clear('site_code')"
                    v-model="candidate.site_code"
                    placeholder="Site code"
                  />
                  <div class="invalid-feedback">Please provide a valid Site Code</div>
                </div>
              </div>

              <!-- Work Location -->
              <div class="form-group col-12 col-md-4">
                <label for="name"
                  >Work location
                  <i
                    class="fa fa-info-circle text-dark-grey"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title='<p class="text-left mt-1 pt-1"></i><strong>Metro:</strong> Locations within close proximity to emergency medical facilities and hospitals <br/><br/><strong>Remote:</strong> Locations with limited access to emergency medical facilities and hospitals</p>'
                  ></i
                ></label>
                <div class="input-group mt-2 position-relative">
                  <label class="jem-checkbox-wrap">
                    <input type="radio" v-model="candidate.work_location" value="metro" />
                    <span class="jem-checkmark"></span>
                  </label>
                  <label class="mt-1 ml-2 mr-3">Metro</label>

                  <label class="jem-checkbox-wrap">
                    <input type="radio" v-model="candidate.work_location" value="remote" />
                    <span class="jem-checkmark"></span>
                  </label>
                  <label class="mt-1 ml-2">Remote</label>
                </div>
              </div>

              <!-- DEPARTMENTS -->
              <div class="form-group col-12 col-md-4">
                <label for="name">Departments</label>
                <div class="input-group">
                  <el-select
                    class="w-100"
                    v-model="candidate.department_id"
                    placeholder="Departments"
                  >
                    <el-option
                      v-for="department in this.recruiterDepartments"
                      :key="department.id"
                      :label="department.name"
                      :value="department.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 col-md-8 mr-auto">
                <label for="notes">Notes</label>
                <textarea v-model="candidate.notes" class="w-100 form-control"></textarea>
              </div>
            </div>
          </div>

          <div v-else-if="active == 2" class="row mb-2" key="steptwo" style="min-height: 500px">
            <div class="col-md-12">
              <div class="row">
                <div class="form-group col-12">
                  <h3>Medical Type or Components</h3>

                  <div class="jem-paginated-table">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Select</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-if="selected_booking_types.length == 0">
                            <td>
                              <p class="mb-0">
                                Please select a service from the below dropdown and add.
                              </p>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr v-for="(service, index) in selected_booking_types" :key="index">
                            <td>
                              <p class="mb-0">
                                {{ service.title }}
                              </p>
                            </td>

                            <td>
                              <button class="red-icon-btn" @click="onRemove(service)">
                                <i class="fas fa-trash-alt fa-lg"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- select service dropdown -->
                  <el-select
                    v-model="selected_booking_type_id"
                    @change="onSelect"
                    placeholder="Select Service"
                  >
                    <el-option
                      v-for="item in booking_types.filter((x) => x.booking_category_id == 1)"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select
                    class="ml-2"
                    clearable
                    filterable
                    v-model="candidate.location"
                    placeholder="Select location"
                  >
                    <el-option-group v-for="group in states" :key="group.name" :label="group.name">
                      <el-option
                        v-for="item in group.cities"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                </div>

                <div class="form-group col-12 mt-4">
                  <hr />
                  <h3>Standalone or Additional Drug and Alcohol Screening</h3>
                  <div class="jem-paginated-table">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Select</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(service, index) in booking_types.filter(
                              (x) => x.booking_category_id == 2
                            )"
                            :key="index"
                          >
                            <td>
                              <p class="mb-0">
                                {{ service.title }}
                              </p>
                            </td>

                            <td>
                              <label class="jem-checkbox-wrap">
                                <input
                                  type="checkbox"
                                  v-model="enabled_booking_types"
                                  :value="service.id"
                                />
                                <span class="jem-checkmark"></span>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 mt-4">
                  <div class="input-group">
                    <el-checkbox v-model="candidate.urgent"
                      >Please tick here if the candidates booking should be marked as urgent for the
                      Jem Health team</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div class="col-12 d-flex justify-content-end mb-2">
          <button @click="back" class="jem-btn-secondary" :disabled="active == 1">Back</button>

          <button
            @click="next"
            class="ml-3 jem-btn-primary"
            v-if="active != 2"
            :disabled="active == 2"
          >
            Next Step
          </button>

          <button
            @click="onSubmit"
            v-if="active == 2"
            class="ml-3 jem-btn-primary"
            :disabled="isSubmitted"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Candidate from '../../models/Candidate'
import Form from 'form-backend-validation'
import VueTelInput from 'vue-tel-input'

export default {
  components: {
    VueTelInput
  },
  props: {
    booking_types: {
      type: Array
    },
    states: {
      type: Array
    },
    recruiterDepartments: {
      type: Array
    }
  },
  computed: {
    isPoNumberDisabled() {
      return !this.currentUser?.company?.is_po_required
    },
    poNumberPlaceholder() {
      return this.isPoNumberDisabled ? 'Not Required' : 'PO Number'
    }
  },
  data() {
    return {
      candidate: new Candidate({ booking_types: [] }),
      enabled_booking_types: [],
      selected_booking_types: [],
      selected_booking_type: '',
      validated: true,
      selected_booking_type_id: '',
      active: 1,
      form: new Form(),
      currentUser: null,
      isSubmitted: false
    }
  },
  created() {
    axios
      .get('/api/user')
      .then((res) => {
        this.currentUser = res.data
      })
      .catch((error) => {
        console.error('error fetching user data:', error)
      })
  },
  methods: {
    onSubmit() {
      this.isSubmitted = true
      let booking_types = this.enabled_booking_types
      booking_types = booking_types.concat(this.selected_booking_types.map((x) => x.id))

      this.candidate.booking_types = booking_types

      this.candidate
        .save()
        .then((res) => {
          this.$message({
            type: 'success',
            message: 'Saved'
          })
          window.location.href = `/candidates/${res.id}`
          this.candidate = new Candidate(res)
        })
        .catch((errors) => {
          this.isSubmitted = false
          this.active = 1
          this.form.errors.record(errors.response.data.errors)
        })
    },
    next() {
      if (this.active++ > 1) this.active = 1
    },
    back() {
      if (this.active-- <= 1) this.active = 1
    },
    onSelect() {
      this.selected_booking_type = this.booking_types.filter(
        (x) => x.id == this.selected_booking_type_id
      )[0]
      if (this.selected_booking_type) {
        this.selected_booking_types.push(this.selected_booking_type)
        this.selected_booking_type_id = ''
        this.selected_booking_type = ''
      }
    },
    onNumberValidate(validated_information) {
      this.validated = validated_information.isValid
    },
    onRemove(passed_index) {
      let index = this.selected_booking_types.indexOf(passed_index)
      this.selected_booking_types.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* set width of dropdown and content
.el-select-dropdown {
  max-width: 475px !important;
}

.el-select-dropdown__item {
  height: 45px !important;
  max-width: 475px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  line-height: 18px !important;
}

.el-select-dropdown__item > span {
  display: inline-block !important;
  max-width: 475px !important;
  height: 70px !important;
  white-space: normal !important;
}*/
</style>
