import { 
    Box, 
    Button, 
    Divider, 
    Flex, 
    Heading, 
    Spacer,
    useToast,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text, 
    UnorderedList, 
    ListItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Select,
    Input,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useState, useRef } from "react";
import User from '../../../../backend/models/User';
import DoctorReview from '../../../../backend/models/DoctorReview';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { FaRegAddressCard } from "react-icons/fa";
import moment from 'moment';
import { set } from "lodash";

const CandidateFormTopNavBar = ({ 
    onSave, 
    candidate, 
    onCommentsOpen, 
    doctorReview, 
    onMoreInfoClick, 
    onPendingReportClick, 
    onFinaliseClick, 
    onSubmitDoctorReview, 
    bookingTypes, 
    bookingTypeId, 
    doctorReviews,
    user 
}) => {
    const [doctors, setDoctors] = useState([]);
    const [doctorId, setDoctorId] = useState(doctorReview?.doctor_id ?? null);
    const [isDoctorChanged, setIsDoctorChanged] = useState(false);
    const [defaultDoctor, setDefaultDoctor] = useState('');
    const [apiDoctorReview, setApiDoctorReview] = useState(new DoctorReview(doctorReview));
    const toast = useToast();
    const isFirstRender = useRef(true);

    const hasManageAssessmentPermission = user?.permissions?.some(permission => permission.name === "Manage Assessment");
    const hasManageDoctorPermission = user?.permissions?.some(permission => permission.name === "Manage Doctor");
    const hasSuperAdminRole = user?.roles?.some(role => role.name === "super_admin");


    const getDoctors = useCallback(async () => {
        try {
            const response = await User.page(1)
                .params({ per_page: 1000 })
                .where("permission", "Manage Doctor")
                .get();
            
            setDoctors(response.data);
            const selectedDoctor = response.data.find(doc => doc.id === doctorId);
            if (selectedDoctor) {
                setDefaultDoctor(selectedDoctor.full_name);
            }
        } catch (error) {
            toast({
                title: "Error loading doctors",
                status: "error",
                duration: 3000,
            });
        }
    }, [doctorId]);

    useEffect(() => {
        getDoctors();
    }, [getDoctors]);

    useEffect(() => {
        if (doctorReview) {
            setDoctorId(doctorReview.doctor_id);
        }
    }, [doctorReview]);

    useEffect(() => {
        if (!isFirstRender.current && apiDoctorReview.doctor_id) {
            handleApiDoctorReviewUpdate();
        }
        isFirstRender.current = false;
    }, [apiDoctorReview]);

    const handleSave = () => {
        if (doctorReview?.id && isDoctorChanged) {
            setApiDoctorReview(new DoctorReview({
                id: doctorReview.id, 
                doctor_id: doctorId
            }));
        }
        onSave();
    };

    const handleApiDoctorReviewUpdate = async () => {
        setIsDoctorChanged(false);
        try {
            await apiDoctorReview.save();
            toast({
                title: "Doctor assigned successfully",
                status: "success",
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: "Error assigning doctor",
                status: "error",
                duration: 3000,
            });
        }
    };

    const handleServiceChange = (e) => {
        window.location.href = `/admin/candidates/${candidate.id}/forms?booking_type_id=${e.target.value}`;
    };

    const getStatusText = (statusId) => {
        const statusMap = {
            1: "Pending Review",
            2: "More info required",
            3: "Pending reports",
            4: "Finalised",
            5: "Rejected"
        };
        return statusMap[statusId] ?? "Pending";
    };

    return (
        <Box bg="#fff" mt="-1.5rem" py={4} px={6}>
            <Flex 
                direction={{ base: "column", md: "row" }} 
                gap={{ base: 4, md: 2 }}
            >
                <Flex direction="column" gap="2">
                    <Flex alignItems="center" gap="2">
                        <Heading size="md">{candidate?.name}</Heading>
                        <CandidateInfo candidate={candidate} />
                        {doctorReview?.id && doctorReview?.doctor_review_status_id !== null && (
                            <>
                                <Divider 
                                    orientation="vertical" 
                                    borderWidth="1px"
                                    h="25px"
                                    borderColor="gray.300"
                                />
                                <DoctorSelect 
                                    doctors={doctors}
                                    onChange={(doctorId) => {
                                        setDoctorId(doctorId);
                                        setIsDoctorChanged(true);
                                    }}
                                    isDisabled={doctorReview?.doctor_review_status_id === 4}
                                    defaultValue={defaultDoctor}
                                />
                            </>
                        )}
                    </Flex>
                </Flex>
                <Spacer display={{ base: "none", md: "block" }} />
                <Flex 
                    justify="flex-end" 
                    align="center" 
                    gap={4}
                    flex="1 1 auto"
                    minWidth="max-content"
                >
                    <Button 
                        colorScheme="teal" 
                        variant="outline" 
                        size="md" 
                        onClick={onCommentsOpen}
                        flexShrink={0}
                        borderColor="teal.500"
                        _focus={{ boxShadow: 'none', outline: 'none' }}
                    >
                        Comments
                    </Button>
                    
                    <Button
                        colorScheme="teal"
                        onClick={handleSave}
                        flexShrink={0}
                        isDisabled={doctorReview?.doctor_review_status_id === 4}
                    >
                        Save
                    </Button>
                    <Divider 
                        orientation="vertical"
                        borderWidth="1px"
                        h="25px"
                        borderColor="gray.300"
                    />
                    
                    <Flex gap={4} flexShrink={0}>
                            <>
                                <Menu>
                                    <MenuButton 
                                        as={Button} 
                                        colorScheme="teal" 
                                        variant="outline" 
                                        borderColor="teal.500"
                                        rightIcon={<ChevronDownIcon />}
                                        _focus={{ boxShadow: 'none', outline: 'none' }}
                                        isDisabled={doctorReview?.doctor_review_status_id === 4 || doctorReview?.doctor_review_status_id === null}
                                    >
                                        {getStatusText(doctorReview?.doctor_review_status_id)}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={onMoreInfoClick}>
                                            More info required
                                        </MenuItem>
                                        <MenuItem onClick={onPendingReportClick}>
                                            Pending reports
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                {(hasManageDoctorPermission || hasSuperAdminRole) && (
                                    <Button 
                                        colorScheme="teal" 
                                        size="md" 
                                        onClick={onFinaliseClick}
                                        flexShrink={0}
                                        isDisabled={doctorReview?.doctor_review_status_id === 4}
                                        className="jem-btn-primary"
                                    >
                                        Finalise
                                    </Button>
                                )}
                            </>
                            <>
                                {(hasManageAssessmentPermission || hasSuperAdminRole) && (
                                    <Button 
                                        colorScheme="teal" 
                                        size="md" 
                                        onClick={onSubmitDoctorReview}
                                        isDisabled={doctorReview?.doctor_review_status_id === 1}
                                        flexShrink={0}
                                    >
                                        Submit for review
                                    </Button>
                                )}
                            </>
                    </Flex>
                </Flex>
            </Flex>

            {bookingTypes?.length > 0 && (
                <ServiceSelect 
                    bookingTypes={bookingTypes}
                    bookingTypeId={bookingTypeId}
                    onChange={handleServiceChange}
                />
            )}
        </Box>
    );
};

export default CandidateFormTopNavBar;

const ServiceSelect = ({ 
    bookingTypes, 
    bookingTypeId, 
    isDisabled, 
    onChange 
}) => (
    <Flex 
        align="center" 
        gap={2} 
        flexShrink={0}
        alignItems="center"
        width={{ base: "100%", md: "60%" }}
    >
        <Text whiteSpace="nowrap" className="my-2">Service: </Text>
        
        {bookingTypes.length === 1 ? (
            <Input
                style={{paddingBottom: "4px", paddingTop: "4px"}}
                value={bookingTypes[0].title}
                contentEditable={false}
            />
        ) : (
            <Select
                variant="outline"
                defaultValue={bookingTypeId || ''}
                onChange={onChange}
                isDisabled={isDisabled}
                width="100%"
                style={{paddingBottom: "4px", paddingTop: "4px"}}
            >
                {bookingTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                        {type.title}
                    </option>
                ))}
            </Select>
        )}
    </Flex>
);

const DoctorSelect = ({ 
    doctors, 
    onChange, 
    isDisabled, 
    defaultValue 
}) => {
    return (
        <Flex 
            align="center" 
            gap={2} 
            flexShrink={0}
            alignItems="center"
            height="40px"
        >
            <Text whiteSpace="nowrap" className="my-2">Assigned to: </Text>
            <AutoComplete 
                openOnFocus
                onChange={onChange}
                value={defaultValue}
                isDisabled={isDisabled}
            >
                <AutoCompleteInput 
                    variant="outline" 
                    placeholder="Search doctors..."
                    isDisabled={isDisabled}
                />
                <AutoCompleteList>
                    {doctors.map((doctor, cid) => (
                        <AutoCompleteItem
                            key={`option-${cid}`}
                            value={doctor}
                            label={doctor.full_name}
                        >
                            {doctor.full_name}
                        </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
            </AutoComplete>
        </Flex>
    );
}

const CandidateInfo = ({ candidate }) => (
    <Popover placement="bottom-start">
        <PopoverTrigger>
            <Button variant="ghost">
                <FaRegAddressCard color="gray.500" />
            </Button>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody p={4}>
                <Text mb={0}>DOB: {candidate?.dob ? moment(candidate.dob).format('DD/MM/YYYY') : 'n/a'}</Text>
                <Text mb={0}>Company: {candidate?.user?.company?.name || 'n/a'}</Text>
                <Text mb={2}>Position: {candidate?.position_applied_for || 'n/a'}</Text>
                <Text mb={0}>Services requested:</Text>
                <UnorderedList>
                    {candidate?.booking_types?.map((service, index) => (
                        <ListItem key={index}>{service.title}</ListItem>
                    ))}
                </UnorderedList>
            </PopoverBody>
        </PopoverContent>
    </Popover>
);