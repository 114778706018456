<template>
<div>
  <div class="d-flex m-3">
    <a :href="'/admin/recruiters/create?company_id='+this.company_id" class="ml-auto jem-btn-primary">Create Recruiter</a>
  </div>
  <paginated-table
    v-if="recruiters.data"
    :tableData="tableData"
    :paginatedData="recruiters"
    @pagination-change-page="getRecruiters"
  />
</div>

</template>

<script>
import User from "../../../models/User";

export default {
  props:{
      company_id:{
          type: Number
      }
  },
  mounted() {
    this.getRecruiters();
  },
  data() {
    return {
      recruiters: {},
      tableData: {
        columns: [
          { label: "Full Name", name: "full_name", sort_name:'first_name', sortable: true },
          { label: "Email", name: "email", sortable: true },
        ],
        showSearch: true,
        showPerPage: true,
        viewUrl: "/admin/recruiters"
      }
    };
  },
  methods: {
    getRecruiters(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
      let recruiters = User.page(page).where('company_id', this.company_id).params({per_page:per_page});
      
      if(desc && sortColumn) {
        recruiters.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        recruiters.orderBy(sortColumn.sort_name || sortColumn.name);
      }

      if (query) {
        recruiters.where("where_like", query);
      }
      recruiters.get().then((response) => {
        this.recruiters = response;
      });
    }
  }
};
</script>