import React, {useState, useEffect} from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button, Text, Textarea, RadioGroup, VStack, Radio, useToast } from '@chakra-ui/react';

const CandidateMoreInfoModal = ({ isOpen, onClose, doctorReview, onSubmit, onLocalDoctorReviewChange }) => {
    const toast = useToast();
    const [localDoctorReview, setLocalDoctorReview] = useState(doctorReview);
    const emailRecipients = ['results@jemhealth.com.au', 'waresults@jemhealth.com.au'];
    const [selectedEmail, setSelectedEmail] = useState(undefined);

    useEffect(() => {
        onLocalDoctorReviewChange(localDoctorReview);
    }, [localDoctorReview]);

    useEffect(() => {
        setLocalDoctorReview({...localDoctorReview, email_sent_to: selectedEmail});
    }, [selectedEmail]);

    useEffect(() => {
        if (isOpen) {
            setSelectedEmail(emailRecipients[0]);
        }
    }, [isOpen]);

    const onMoreInfoSubmit = () => {
        if (localDoctorReview.more_info_comments === '') {
            toast({
                title: 'Error saving doctor review. Please try again.',
                status: 'error',
            });
            return;
        }else{
            onSubmit(localDoctorReview);
        }
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottomWidth="1px" borderColor="gray.200">More info required</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={2} mt={4}>
                    <Text>Change status to “More info required”. Use the field below to communicate the results team what outstanding information is required.</Text>

                    <Text mb={0}>Please specify the information required</Text>
                    <Textarea
                        value={localDoctorReview.more_info_comments}
                        onChange={(e) => setLocalDoctorReview({...localDoctorReview, more_info_comments: e.target.value})}
                        placeholder="Enter more information here..."
                        size="md"
                        rows={4}
                        mb={4}
                    />

                    <Text mb={0}>Send email notification to:</Text>
                    <RadioGroup value={selectedEmail} onChange={setSelectedEmail} mb={4}>
                        <VStack align="flex-start" spacing={0}>
                            {emailRecipients.map((email) => (
                                <Radio key={email} value={email} colorScheme="teal">
                                    {email}
                                </Radio>
                            ))}
                        </VStack>
                    </RadioGroup>
                </ModalBody>

                <ModalFooter borderColor="gray.200" justifyContent="space-between" display="flex">
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="teal" onClick={onMoreInfoSubmit}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CandidateMoreInfoModal;