import { render, staticRenderFns } from "./CandidateForm.vue?vue&type=template&id=10fd82db&scoped=true"
import script from "./CandidateForm.vue?vue&type=script&lang=js"
export * from "./CandidateForm.vue?vue&type=script&lang=js"
import style0 from "./CandidateForm.vue?vue&type=style&index=0&id=10fd82db&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fd82db",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.jemhealth.2mm.cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10fd82db')) {
      api.createRecord('10fd82db', component.options)
    } else {
      api.reload('10fd82db', component.options)
    }
    module.hot.accept("./CandidateForm.vue?vue&type=template&id=10fd82db&scoped=true", function () {
      api.rerender('10fd82db', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/views/candidate/CandidateForm.vue"
export default component.exports