<template>
  <div class="container-fluid">
    <div class="row" id="mk-candidate-timeline">
      <!-- candidate info belt? -->
      <div class="col-md-12 pt-3">
        <div>
          <button
            v-if="is_admin"
            @click="showTimelineModal = true"
            class="jem-btn-primary ml-auto mb-3"
          >
            Create Entry
          </button>

          <a
            v-if="is_admin"
            :href="'/admin/candidates/' + this.candidate.id + '/export/timeline?dates=' + date"
            class="jem-btn-primary ml-auto mb-3 mr-3 float-right"
            >Export Timeline</a
          >
          <div class="col-md-4 float-right">
            <el-date-picker
              class="w-100"
              v-model="date"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              start-placeholder="Start date"
              end-placeholder="End date"
            ></el-date-picker>
          </div>
        </div>

        <send-activity-modal
          v-if="is_admin && showTimelineModal"
          @close="showTimelineModal = false"
          :candidate="candidate"
        ></send-activity-modal>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div v-if="hasResultsPermission && hasAnyResult">
              <h2 class="result-files-title">Result Files</h2>
              <div class="result-files-container">
                <div v-for="activity in filteredActivities" :key="activity.id">
                  <div
                    v-if="
                      activity.description && activity.properties && activity.properties.attachments
                    "
                  >
                    <div class="media-files-wrapper">
                      <media-files
                        v-for="(attachment, index) in Object.values(
                          activity.properties.attachments
                        )"
                        :key="attachment.uuid"
                        :file="attachment"
                        :result_id="activity.properties.result_id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="selected-activity-container">
              <div>
                <h2 class="mb-4 mt-5 pt-3">Notes</h2>
                <div class="mt-4">
                  <textarea class="form-control" v-model="notes" row="5"></textarea>
                  <button
                    @click="onSubmit"
                    :disabled="disabled"
                    class="mt-2 btn btn-lg btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- timeline -->
            <h1 class="mb-4 mt-5">
              Timeline for {{ candidate.name }}
              <small v-if="candidate.location">({{ candidate.location }})</small>
            </h1>

            <div v-for="(activity, index) in filteredActivities" :key="index">
              <div class="timeline-row d-flex">
                <div class="timeline-icon ml-2 mr-3">
                  <div class="timeline-indicator"></div>
                </div>
                <div class="timeline-card p-4">
                  <div class="description_container">
                    <strong>{{ activity.description }}</strong>
                  </div>
                  <!-- Not in design <p>{{activity.causer ? activity.causer.full_name : ''}}</p> -->
                  <p class="mb-0 d-flex justify-content-between">
                    {{
                      moment
                        .utc(activity.created_at, 'YYYY-MM-DD HH:mm:ss')
                        .local()
                        .format('DD/MM/YY, hh:mma')
                    }}
                    <button
                      class="btn btn-sm btn-danger"
                      v-if="is_admin"
                      @click="onDelete(activity)"
                    >
                      Delete
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaFiles from './components/MediaFiles'
import axios from 'axios'

export default {
  components: {
    MediaFiles
  },
  props: {
    is_admin: {
      type: Boolean
    },
    candidate: {
      type: Object
    },
    user_permissions: {
      type: Array
    },
    fit_slip_options: {
      type: Array
    }
  },
  data() {
    return {
      showTimelineModal: false,
      date: [],
      selectedActivity: null,
      disabled: false,
      resultId: null,
      filteredActivities: [],
      notes: ''
    }
  },
  computed: {
    hasResultsPermission() {
      return this.user_permissions?.includes('results recruiter access')
    },
    hasAnyResult() {
      for (const activity of this.candidate.latest_activities) {
        if (activity.properties && activity.properties.result_id) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    filterActivities() {
      // Filter activities based on the presence of 'result_fit_slip' property and user's permission
      this.filteredActivities = this.candidate.latest_activities
    },
    onSubmit() {
      axios
        .put(`/api/v1/candidates/${this.candidate.id}`, {
          notes: this.notes
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: 'Saved'
          })
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.message
          })
        })
    },
    onDelete(activity) {
      this.$confirm('You are about to delete this timeline entry. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          axios
            .delete(`/api/v1/admin/candidate/${this.candidate.id}/activity/${activity.id}`)
            .then((res) => {
              this.$message({
                type: 'success',
                message: 'Timeline entry for this candidate was delete'
              })
              window.location.reload()
            })
            .catch((errors) => {
              this.form.errors.record(errors.response.data.errors)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Entry not deleted.'
          })
        })
    }
  },
  created() {
    this.notes = this.candidate.notes
    if (this.candidate.latest_activities && this.candidate.latest_activities.length > 0) {
      this.filterActivities()
    }
  },
  watch: {
    user_permissions: 'filterActivities'
  }
}
</script>

<style scoped>
.media-files-wrapper {
  max-height: 300px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
}
.description_container {
  display: flex;
  justify-content: space-between;
  color: #a1a1a1;
}
.selected-activity-container {
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 40px; /* Adjusted top margin to move it down */
}

/* Add styling for the activity details */
.activity-details {
  margin-top: 15px;
}

.result-files-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.result-files-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

/* Style for media files */
.media-file {
  margin-bottom: 10px;
}
</style>
