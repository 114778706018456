<template>
  <div class="row">
    <div class="col-md-12">
      <hr />
    </div>
    <div class="col-md-12">
        <h3>Payments</h3>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(payment, index) in payments">
              <th>{{payment.id}}</th>
              <td>{{payment.dollar_amount}}</td>
              <td>{{payment.status}}</td>
              <td>{{moment.utc(payment.created_at, "YYYY-MM-DD HH:mm:ss").local().format('DD/MM/YYYY HH:mm:ss')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        payments:{
            type: Array
        }
    }
};
</script>
