import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    Text,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

const ConfirmDownloadModal = ({ isOpen, onClose, onConfirm }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Download File</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>The file you are about to download contains private, confidential, and possibly sensitive information. The candidate (individual) has consented to disclose this information and for you to have access to this information</Text>
                    <Text>By pressing download, you are required by law (The Privacy Act 1988) to save the information in a secure environment. You must take steps, as are reasonable in the circumstances, to protect the information:</Text>
                    <UnorderedList>
                        <ListItem>from misuse, interference and loss.</ListItem>
                        <ListItem>from unauthorised access, modification or disclosure.</ListItem>
                    </UnorderedList>
                    <Text>Once downloaded, Jem Health is no longer responsible for the security of the file / information.</Text>
                    <Text fontWeight={'bold'}>Are you sure you want to download?</Text>
                </ModalBody>
                <ModalFooter justifyContent="space-between">
                    <Button onClick={onClose}>No, View Only</Button>
                    <Button colorScheme="teal" onClick={onConfirm}>Yes</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmDownloadModal;
