import React, { useState, useEffect } from 'react';
import {
    Flex,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Box,
    Text,
    HStack,
    VStack,
    Icon,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import { FaFilePdf, FaFileImage, FaFileUpload, FaTimes, FaPen, FaTrashAlt } from 'react-icons/fa';
import CandidateReportModal from './CandidateReportModal';
import ConfirmDownloadModal from './ConfirmDownloadModal';
import DoctorReviewReport from '../../../../backend/models/DoctorReviewReport';

const DoctorReviewReportsIndex = ({ candidate, doctorReview, doctorReviewReports }) => {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [reports, setReports] = useState(doctorReviewReports);
    const [formReport, setFormReport] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [reportToDelete, setReportToDelete] = useState(null);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [fileToDownload, setFileToDownload] = useState(null);
    const toast = useToast();

    useEffect(() => {
        setReports(doctorReviewReports);
    }, [doctorReviewReports]);

    const onEdit = (report) => {
        setFormReport(report);
        setIsUploadModalOpen(true);
    };

    const onDelete = (report) => {
        setReportToDelete(report);
        setIsDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        const doctorReviewReport = new DoctorReviewReport(reportToDelete);
        doctorReviewReport.delete().then(() => {
            setReports(reports.filter(r => r.id !== reportToDelete.id));
            toast({
                title: 'Success',
                description: 'Report deleted successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
        }).catch(() => {
            toast({
                title: 'Error',
                description: 'Report not deleted.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
        }).finally(() => {
            setIsDeleteModalOpen(false);
            setReportToDelete(null);
        });
    };

    const onDownload = (file) => {
        setFileToDownload(file);
        setIsDownloadModalOpen(true);
    };

    const confirmDownload = () => {
        window.open(fileToDownload.url, '_blank');
        setIsDownloadModalOpen(false);
        setFileToDownload(null);
    };

    return (
        <Box bg="white" p={4} rounded="md" shadow="sm">
            <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">Reports</Heading>
                <HStack>
                    {doctorReview?.result_pdf_url && (
                        <Button
                            as="a"
                            href={doctorReview.result_pdf_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            leftIcon={<FaFilePdf />}
                            colorScheme="teal"
                            variant="outline"
                            size="md"
                        >
                            View Result PDF
                        </Button>
                    )}
                    <Button 
                        leftIcon={<FaFileUpload />} 
                        colorScheme="teal"
                        onClick={() => setIsUploadModalOpen(true)}
                    >
                        Upload Report
                    </Button>
                </HStack>
            </Flex>

            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Description</Th>
                            <Th>Attachments</Th>
                            <Th>Date uploaded</Th>
                            <Th>Uploaded By</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {reports && reports.map(report => (
                            <Tr key={report.id}>
                                <Td>{report.description}</Td>
                                <Td>
                                    <VStack align="flex-start" spacing={1}>
                                        {report.attachments && report.attachments.map((file, index) => (
                                            <HStack 
                                                key={index} 
                                                border="1px solid #CBD5E0" 
                                                borderRadius="8px" 
                                                p="8px"
                                                mb="5px"
                                                onClick={() => onDownload(file)}
                                                cursor={'pointer'}
                                            >
                                                <Icon 
                                                    as={file.type === 'application/pdf' ? FaFilePdf : FaFileImage} 
                                                    color={file.type === 'application/pdf' ? 'red.500' : 'blue.500'} 
                                                />
                                                <Text fontSize="sm" mb={0} _hover={{ textDecoration: 'underline' }}>{file.name}</Text>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </Td>
                                <Td>{new Date(report.dateUploaded).toLocaleDateString('en-CA')}</Td>
                                <Td>{report.uploadedBy}</Td>
                                <Td>
                                    <HStack spacing={2}>
                                        <IconButton
                                            icon={<FaPen />}
                                            size="md"
                                            variant="ghost"
                                            colorScheme="teal"
                                            onClick={() => onEdit(report)}
                                        />
                                        <IconButton
                                            icon={<FaTrashAlt />}
                                            size="md"
                                            variant="ghost"
                                            colorScheme="red"
                                            onClick={() => onDelete(report)}
                                        />
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <CandidateReportModal 
                isOpen={isUploadModalOpen}
                onClose={() =>{
                    setFormReport(null);
                    setIsUploadModalOpen(false);
                }}
                report={formReport}
                doctorReview={doctorReview}
                setReports={setReports}
                reports={reports}
            />
            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Report</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to delete this report?</Text>
                    </ModalBody>
                    <ModalFooter justifyContent="space-between">
                        <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                        <Button colorScheme="red" onClick={confirmDelete}>Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ConfirmDownloadModal 
                isOpen={isDownloadModalOpen}
                onClose={() => setIsDownloadModalOpen(false)}
                onConfirm={confirmDownload}
            />
        </Box>
    );
};

export default DoctorReviewReportsIndex;