<template>
  <div class="container-fluid">
    <div class="row" id="mk-candidate-timeline">
      <!-- candidate info belt? -->
      <div class="col-md-12 pt-3">
        <div>
            <button
            v-if="is_admin"
            @click="showTimelineModal = true"
            class="jem-btn-primary ml-auto mb-3"
            >Create Entry</button>

            <a v-if="is_admin" :href="'/admin/candidates/'+this.candidate.id+'/export/timeline?dates='+date" class="jem-btn-primary ml-auto mb-3 mr-3 float-right">Export Timeline</a>
            <div class="col-md-4 float-right">
                <el-date-picker
                class="w-100"
                v-model="date"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                start-placeholder="Start date"
                end-placeholder="End date"
                ></el-date-picker>
            </div>
        </div>

        <send-activity-modal
          v-if="is_admin && showTimelineModal"
          @close="showTimelineModal = false"
          :candidate="candidate"
        ></send-activity-modal>
      </div>

      <!-- timeline -->
      <div class="col-md-12">
        <h1 class="mb-4 mt-5">Timeline for {{candidate.name}} <small v-if="candidate.location">({{candidate.location}})</small></h1>

        <div v-for="(activity, index) in candidate.activities" :key="index">
          <div class="timeline-row d-flex">
            <div class="timeline-icon ml-2 mr-3">
              <div class="timeline-indicator"></div>
            </div>
            <div class="timeline-card p-4">
              <p>
                <strong>{{activity.description}}</strong>
              </p>
              <!-- Not in design <p>{{activity.causer ? activity.causer.full_name : ''}}</p> -->
              <p class="mb-0 d-flex justify-content-between">
                {{moment.utc(activity.created_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YY, hh:mma')}}
                <button
                  class="btn btn-sm btn-danger"
                  v-if="is_admin"
                  @click="onDelete(activity)"
                >Delete</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    is_admin: {
      type: Boolean
    },
    candidate: {
      type: Object
    }
  },
  data() {
    return {
      showTimelineModal: false,
      date: []
    };
  },
  methods: {
    //
    onAdd() {
      //
    },
    onDelete(activity) {
      this.$confirm(
        "You are about to delete this timeline entry. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          axios
            .delete(`/api/v1/admin/candidate/${this.candidate.id}/activity/${activity.id}`)
            .then(res => {
              this.$message({
                type: "success",
                message: "Timeline entry for this candidate was delete"
              });
            })
            .catch(errors => {
              this.form.errors.record(errors.response.data.errors);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Entry not deleted."
          });
        });
    }
  }
};
</script>
