<template>
  <div id="mk-service-form">
    <div class="row mb-3">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <h1>{{service.name || 'Create Service'}}</h1>

        <div class="d-flex align-items-center">
          <button @click="onSubmit" :disabled="form.errors.any()" class="jem-btn-primary">Update</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label for="name">Name</label>
        <div class="input-group">
          <input
            type="text"
            disabled="disabled"
            name="name"
            :class="[{ 'is-invalid': form.errors.has('name') }, 'form-control']"
            id="name-input"
            @keydown="form.errors.clear('name');"
            v-model="service.name"
            placeholder="Name"
          />
          <div class="invalid-feedback">Please choose a name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="email">Description</label>
        <div class="input-group">
          <input
            type="text"
            disabled="disabled"
            name="description"
            :class="[{ 'is-invalid': form.errors.has('email') }, 'form-control']"
            id="description-input"
            v-model="service.description"
            @keydown="form.errors.clear('description');"
            placeholder="Description"
          />
          <div class="invalid-feedback">Description invalid.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="email">Booking Link</label>
        <div class="input-group">
          <input
            type="text"
            name="description"
            :class="[{ 'is-invalid': form.errors.has('booking_link') }, 'form-control']"
            id="description-input"
            v-model="service.booking_link"
            @keydown="form.errors.clear('booking_link');"
            placeholder="https://google.com"
          />
          <div class="invalid-feedback">Booking Link invalid.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="email">Booking Link Text</label>
        <div class="input-group">
          <input
            type="text"
            name="description"
            :class="[{ 'is-invalid': form.errors.has('booking_link_text') }, 'form-control']"
            id="description-input"
            v-model="service.booking_link_text"
            @keydown="form.errors.clear('booking_link_text');"
            placeholder="Book Brisbane"
          />
          <div class="invalid-feedback">Booking Link Text invalid.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for>Location</label>
        <el-select class="w-100" v-model="service.location_id" clearable filterable placeholder="Select">
          <el-option
            v-for="item in locations"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../../models/Service";
import ServiceLink from "../../models/ServiceLink";
import Form from "form-backend-validation";

export default {
  props: {
    service_types: {
      type: Array
    },
    locations:{
      type: Array
    },
    companies: {
      type: Array
    },
    initial_service: {
      type: Object
    }
  },
  data() {
    return {
      service: new Service(this.initial_service || {}),
      form: new Form()
    };
  },
  methods: {
    onSubmit() {
      this.service
        .save()
        .then(res => {
          this.$message({
            type: "success",
            message:
              "Saved! However whilst Acuity Scheduling is enabled we are unable to edit the name and description."
          });
          this.service = new Service(res);
          var url = window.location.toString();
          url = url.replace("create", `${res.id}/edit`);
          window.history.pushState(null, null, url);
        })
        .catch(errors => {
          this.form.errors.record(errors.response.data.errors);
        });
    }
  }
};
</script>

<style>
</style>
