import React, { useState, useEffect } from 'react';
import { ReactFormGenerator, ElementStore } from 'sprint-digital-react-form-builder';
import CustomFormResults from '../../frontend/models/CustomFormResults';
import { applyButtonStyles, applyCustomStyleForHeading } from '../utils/formUtils';
import { applyMultiStepForm } from '../utils/multiStepForm';
import Swal from 'sweetalert2';

const FormRender = ({ variables }) => {
  const [formTemplate, setFormTemplate] = useState([]);
  const [formResult, setFormResult] = useState([]);
  const [formCustomStyles, setFormCustomStyles] = useState({});
  const [customFormResult, setCustomFormResults] = useState(new CustomFormResults());
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [isDataShown, setIsDataShown] = useState(false); // For debugging purposes
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [isValidationSkip, setIsValidationSkip] = useState(true);
  const navSubmitTracker = document.getElementById('nav-submit-tracker');

  useEffect(() => {
      try {
          const formElement = document.getElementById('form-render');
          const variables = JSON.parse(formElement.dataset.variables);
          const urlParams = new URLSearchParams(window.location.search);
          const signature = urlParams.get('signature');
          setFormResult(variables.formResult || []);
          setFormTemplate(variables.formTemplate.task_data || []);
          setFormCustomStyles(variables.formTemplate.custom_styles || {});
          setCustomFormResults(new CustomFormResults({id: variables.formId, signature: signature}));
          setIsSubmittable(variables.isSubmittable || false);
          setIsPreview(variables.isPreview || false);
          setShowConfirmation(variables.isCompleted || false);
          setIsPdf(variables.isPdf || false);
      } catch (error) {
          console.error('Error parsing form data:', error);
      }
  }, []);

  useEffect(() => {
    if (formTemplate.length > 0) {
      // Apply custom styles to headings (your existing functionality)
      applyCustomStyleForHeading(formTemplate, formCustomStyles);
      if (!isPdf) {
        applyMultiStepForm(isPreview);
      }
      applyButtonStyles(formTemplate, formCustomStyles);

      const validationSkipTracker = document.getElementById('validation-skip-tracker');
      if (validationSkipTracker) {
          const observer = new MutationObserver((mutations) => {
              mutations.forEach((mutation) => {
                  if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
                      if (validationSkipTracker.value === 'true') {
                          setIsValidationSkip(true);
                          validationSkipTracker.value = 'false'; // Reset after handling
                      }
                  }
              });
          });
  
          observer.observe(validationSkipTracker, { attributes: true });
          return () => observer.disconnect();
      }
    }
  }, [formTemplate]);

  const handleSubmit = (data) => {
    const pageTracker = document.getElementById('page-tracker');
    const [currentPage = 1, totalPages = 1] = pageTracker?.value?.split('/')?.map(Number) || [];
    if (currentPage == totalPages || (currentPage == 1 && totalPages == 1)) {
      setIsValidationSkip(false);
    }else{
      setIsValidationSkip(true);
    }

    const updatedResult = navSubmitTracker?.value === 'true' || (currentPage == 1 && totalPages == 1)
      ? { ...customFormResult, results: data, custom_form_result_status_id: 2 }
      : { ...customFormResult, results: data };

    setTimeout(() => {
      setCustomFormResults(new CustomFormResults(updatedResult));
      setFormResult(data);
    }, 500);
  };

  const handleApiCustomerFormResultUpdate = () => {
    customFormResult.save()
      .then((response) => {
        if (response.custom_form_result_status_id === 2) {
          setShowConfirmation(true);
        }

        // Enable submit buttons
        document.getElementById('submit-button')?.removeAttribute('disabled');
        document.getElementById('nav-submit-button')?.removeAttribute('disabled');
      })
      .catch((e) => {
        Swal.fire({
          title: "Error saving form data. Please try again.",
          icon: 'error',
        });
      });
  };

  useEffect(() => {
    if (customFormResult.results) {
      handleApiCustomerFormResultUpdate();
    }
  }, [customFormResult]);


  return (
    <div>
      {showConfirmation && (
        <div className="thank-you-message text-center text-black">
          <h1 className="text-bold mb-4 text-black">Thank you for completing your medical history questionnaire.</h1>
          <p>Please refer to your booking confirmation email for further information about your upcoming appointment.</p>
          <p>We look forward to seeing you soon.</p>
          <p>Jem Health</p>
        </div>
      )}
      {!showConfirmation && (
        <div className="form-render-container">
          {formTemplate.length > 0 && (
            <ReactFormGenerator
              answer_data={formResult}
              action_name="Save"
              data={formTemplate}
              onSubmit={handleSubmit}
              read_only={isPreview}
              skip_validations={isValidationSkip}
              show_internal={false}
              submitButton={
                <button
                  id="submit-button"
                  className="btn btn-primary" 
                  style={{ visibility: 'hidden' }}
                >
                  Submit
                </button>
              }
            />
          )}
          {isDataShown && (
            <>
              <div className="mt-4">
                {formTemplate.length > 0 && (
                  <div className="mb-3">
                    <h4>Form Data:</h4>
                    <textarea 
                      className="form-control font-monospace"
                      value={JSON.stringify(formTemplate, null, 2)}
                      readOnly
                      rows={5}
                    />
                  </div>
                )}
                {Object.keys(formResult).length > 0 && (
                  <div>
                    <h4>Answer Data:</h4>
                    <textarea
                      className="form-control font-monospace"
                      value={JSON.stringify(formResult, null, 2)}
                      readOnly
                      rows={5}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FormRender;