import React from "react";
import { useEffect } from "react";

const StyleEditor = ({ styles, onStyleChange }) => {
  const handleInputChange = (componentType, property, value) => {
    onStyleChange(componentType, property, value);
  };

  return (
    <div className="style-editor">
      <h3>Heading Styles</h3>
      <div className="style-group">
        {/* Text Color Picker */}
        <div className="style-item">
          <label>Text Colour</label>
          <input
            type="color"
            value={styles.heading.color}
            onChange={(e) => handleInputChange("heading", "color", e.target.value)}
          />
        </div>

        {/* Background Color Picker */}
        <div className="style-item">
          <label>Background Colour</label>
          <input
            type="color"
            value={styles.heading.backgroundColor}
            onChange={(e) =>
              handleInputChange("heading", "backgroundColor", e.target.value)
            }
          />
        </div>
      </div>

      <h3>Button Styles</h3>
      <div className="style-group">
        {/* Button Text Color Picker */}
        <div className="style-item">
          <label>Text Colour</label>
          <input
            type="color"
            value={styles.button.color}
            onChange={(e) => handleInputChange("button", "color", e.target.value)}
          />
        </div>

        {/* Button Background Color Picker */}
        <div className="style-item">
          <label>Background Colour</label>
          <input
            type="color"
            value={styles.button.backgroundColor}
            onChange={(e) =>
              handleInputChange("button", "backgroundColor", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StyleEditor;
