import React, { useEffect, useState, useCallback } from 'react';
import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, 
    ModalCloseButton, Text, ModalFooter, Button, List, ListItem, 
    ListIcon, Box, Avatar, Tag, TagLabel, TagCloseButton
} from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import { debounce } from 'lodash';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from "@choc-ui/chakra-autocomplete";
import User from '../../../../backend/models/User';

const CandidateFinaliseModal = ({ isOpen, onClose, doctorReview, onSubmit, onLocalDoctorReviewChange }) => {
    const [localDoctorReview, setLocalDoctorReview] = useState(doctorReview);
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isFitslipEnabled = localDoctorReview?.is_fitslip_enabled === 1;

    const searchRecipients = useCallback(
        debounce(async (query) => {
            if (!query) {
                setRecipients([]);
                return;
            }
            
            setIsLoading(true);
            try {
                const response = await User.where('where_like', query).get();
                setRecipients(response.data || []);
            } catch (error) {
                console.error('Error searching recipients:', error);
                setRecipients([]);
            } finally {
                setIsLoading(false);
            }
        }, 300),
        []
    );

    const handleRecipientSelect = (recipient) => {
        if (!selectedRecipients.find(r => r.id === recipient.originalValue.id)) {
            const newRecipients = [...selectedRecipients, recipient.originalValue];
            setSelectedRecipients(newRecipients);
            setLocalDoctorReview({
                ...localDoctorReview,
                recipients: newRecipients.map(r => r.id)
            });
            onLocalDoctorReviewChange({
                ...localDoctorReview,
                recipients: newRecipients.map(r => r.id)
            });
        }
    };

    const handleRemoveRecipient = (recipientId) => {
        const newRecipients = selectedRecipients.filter(r => r.id !== recipientId);
        setSelectedRecipients(newRecipients);
        setLocalDoctorReview({
            ...localDoctorReview,
            recipients: newRecipients.map(r => r.id)
        });
        onLocalDoctorReviewChange({
            ...localDoctorReview,
            recipients: newRecipients.map(r => r.id)
        });
    };

    useEffect(() => {
        setLocalDoctorReview(doctorReview);
    }, [doctorReview]);

    useEffect(() => {
        // 
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottomWidth="1px" borderColor="gray.200">
                    Finalise candidate
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={2}>
                    <Text>You are about to finalise this medical and submit the results to the referring client. Are you sure you want to finalise?</Text>
                    <Text><b>Important:</b> Ensure ALL requested services and reports are complete.</Text>

                    {isFitslipEnabled && !localDoctorReview?.signature_url && (
                        <List spacing={3}>
                            <ListItem color="red.500" display="flex" alignItems="center">
                                <ListIcon as={MdError} color="red.500" />
                                Doctor's signature is missing
                            </ListItem>
                        </List>
                    )}

                    <Text>Enter the emails of the portal users who should receive this notification.</Text>

                    <Text mb={2} mt={4} fontWeight="medium">Search portal user emails</Text>
                    <AutoComplete
                        pt={2}
                        openOnFocus
                        multiple
                        emptyState="Search for recipients"
                        isLoading={isLoading}
                        onSelectOption={({ item }) => handleRecipientSelect(item)}
                    >
                        <AutoCompleteInput 
                            placeholder="Search..." 
                            variant="outline" 
                            onChange={(e) => searchRecipients(e.target.value)}
                            p={2}
                        >
                            {selectedRecipients.map((selectedRecipient) => (
                                <AutoCompleteTag
                                    key={selectedRecipient.id}
                                    label={selectedRecipient.email}
                                    onRemove={() => handleRemoveRecipient(selectedRecipient.id)}
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        height: '24px',
                                        fontSize: 'sm',
                                        lineHeight: '1',
                                        backgroundColor: '#EDF2F7',
                                        borderRadius: 'full',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                />
                            ))}
                        </AutoCompleteInput>
                        <AutoCompleteList>
                            {recipients.map((recipient) => (
                                <AutoCompleteItem
                                    key={recipient.id}
                                    value={recipient}
                                    label={recipient.full_name}
                                >
                                    {recipient.full_name} - {recipient.email}
                                </AutoCompleteItem>
                            ))}
                        </AutoCompleteList>
                    </AutoComplete>

                </ModalBody>

                <ModalFooter borderColor="gray.200" justifyContent="space-between" display="flex" pt={0}>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button 
                        colorScheme="teal" 
                        onClick={onSubmit} 
                        isDisabled={
                            (!localDoctorReview?.signature_url && isFitslipEnabled) || 
                            selectedRecipients.length === 0
                        }
                    >
                        Finalise candidate
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CandidateFinaliseModal;