<template>
  <div>
    <el-dropdown @command="handleCommand" placement="bottom-start">
      <el-button class="el-button--secondary">
        Actions <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown" style="min-width: 160px">
        <el-dropdown-item command="edit">Edit Form</el-dropdown-item>
        <el-dropdown-item command="rename">Rename</el-dropdown-item>
        <el-dropdown-item command="duplicate">Duplicate</el-dropdown-item>
        <el-dropdown-item v-if="isFormActive" command="disable" style="color: #e53e3e"
          >Disable</el-dropdown-item
        >
        <el-dropdown-item v-else command="enable" style="color: #38a169">Enable</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog title="Rename Form" :visible.sync="showModal" width="30%" :before-close="resetForm">
      <div class="dialog-body">
        <el-form
          :model="form"
          :rules="rules"
          ref="formRef"
          label-position="top"
          @submit.native.prevent
        >
          <el-form-item label="Form Title" prop="title">
            <el-input v-model="form.title" placeholder="Enter form title" />
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <el-button @click="showModal = false">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    formId: {
      type: Number,
      required: true
    },
    isFormActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      form: {
        title: ''
      },
      rules: {
        title: [{ required: true, message: 'Please enter a form title', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'edit') {
        window.location.href = `/admin/forms/${this.formId}/edit`
      } else if (command === 'rename') {
        this.showModal = true
      } else if (command === 'duplicate') {
        this.duplicateForm()
      } else if (command === 'disable') {
        this.disableForm()
      } else if (command === 'enable') {
        this.enableForm()
      }
    },
    onSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const endpoint = `/api/v1/admin/forms/${this.formId}`
          const payload = {
            title: this.form.title
          }

          axios({
            method: 'PUT',
            url: endpoint,
            data: payload
          })
            .then(() => {
              this.resetForm()
              this.showModal = false
              this.$emit('form-updated')
              this.$message({
                message: 'Form renamed successfully!',
                type: 'success'
              })
            })
            .catch((error) => {
              this.$message({
                message: 'Failed to rename form',
                type: 'error'
              })
            })
        }
      })
    },
    resetForm(done) {
      this.form.title = ''
      this.$refs.formRef && this.$refs.formRef.resetFields()
      if (done) done()
    },
    duplicateForm() {
      const endpoint = `/api/v1/admin/forms/${this.formId}/duplicate`

      axios({
        method: 'POST',
        url: endpoint
      })
        .then((response) => {
          this.$emit('form-updated')
          this.$message({
            message: 'Form duplicated successfully!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.$message({
            message: 'Failed to duplicate form',
            type: 'error'
          })
        })
    },
    disableForm() {
      let endpoint = `/api/v1/admin/forms/${this.formId}`
      const payload = {
        is_active: false
      }

      axios({
        method: 'PUT',
        url: endpoint,
        data: payload
      })
        .then((response) => {
          this.$emit('form-updated')
          this.$message({
            message: 'Form disabled successfully!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.$message({
            message: 'Failed to disable form',
            type: 'error'
          })
        })
    },
    enableForm() {
      let endpoint = `/api/v1/admin/forms/${this.formId}`
      const payload = {
        is_active: true
      }

      axios({
        method: 'PUT',
        url: endpoint,
        data: payload
      })
        .then((response) => {
          this.$emit('form-updated')
          this.$message({
            message: 'Form enabled successfully!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.$message({
            message: 'Failed to enable form',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style scoped>
.dialog-body {
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

.el-form-item__label {
  display: inline;
}

::v-deep(.el-dialog) {
  border-radius: 10px;
}

.el-dropdown-menu__item {
  font-size: 14px;
}
</style>
