<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center">
          <div class="col-md-11">
            <div class="card shadow">
              <div class="row px-3 pt-3">
                <div class="col-12">
                  <h1 class="my-4">Book Candidate Services</h1>
                  <hr>
                </div>
              </div>

              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-md-12">
                    <p class="mb-4">Here you are able to book services into the Acuity System</p>
                    <p>Here is a summary of the requested services for this candidate:</p>
                    <ul>
                      <li :key="index" v-for="(service, index) in candidate.booking_types">{{service.title}}</li>
                    </ul>
                  </div>

                  <div class="col-md-12 modal-scroll">
                    <div class="row mb-3" v-for="(booking, index) in booked_services" :key="index">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for>Calendar</label>
                          <el-select
                            class="w-100"
                            v-model="booking.calendar_id"
                            filterable
                            @change="setSelectedCalendar(booking)"
                            placeholder="Select"
                          >
                            <el-option
                              v-for="item in calendars"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for>Appointment Type</label>
                          <el-select
                            class="w-100"
                            v-model="booking.service_id"
                            filterable
                            placeholder="Select"
                            @change="save_attempted = false"
                          >
                            <el-option
                              v-for="item in booking.services"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>

                          <span v-if="save_attempted && !booking.service_id" class="text-red">Please Select a service</span>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label for>Timeline Description</label>
                          <el-autocomplete
                            class="inline-input w-100"
                            v-model="booking.description"
                            :fetch-suggestions="querySearch"
                            placeholder="Please Input"
                          ></el-autocomplete>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for>Notes</label>
                          <el-input
                            class="inline-input w-100"
                            v-model="booking.notes"
                          ></el-input>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div v-if="booking.calendar_id">
                          <div class="form-group">
                            <label for>Appointment time</label>
                            <el-date-picker
                              class="w-100"
                              v-model="booking.local_appointment_time"
                              @change="updateBookingToUtc(booking); save_attempted = false"
                              :picker-options="dateOptions"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:00"
                              format="dd/MM/yyyy HH:mm"
                              placeholder="Select date and time"
                            ></el-date-picker>
                          </div>
                        </div>

                        <span v-else>Please Select a calendar</span>
                        <span
                          v-if="save_attempted && !booking.appointment_time"
                          class="text-red"
                        >Please Select an appointment time</span>
                      </div>

                      <div class="col-md-1">
                        <span
                          class="red-icon-btn"
                          style="margin-top: 28px;"
                          @click="onRemoveService(booking)"
                        >
                          <i class="far fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="row mb-3">
                      <div class="col-md-12 d-flex my-3">
                        <button
                          class="jem-btn-primary d-flex align-items-center"
                          @click="booked_services.push({selectedCalendar:{},services:[]})"
                        >
                          Add Another
                          <i class="fas fa-plus text-white ml-3"></i>
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 d-flex justify-content-between">
                        <span class="jem-btn-secondary" @click="$emit('close')">Close</span>
                        <button class="jem-btn-primary" :disabled="save_attempted" @click="onSubmit">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Candidate from "../../../../models/Candidate";
import BookedService from "../../../../models/BookedService";

export default {
  props: {
    services: {
      type: Array
    },
    candidate: {
      type: Object
    },
    calendars: {
      type: Array
    }
  },
  data() {
    return {
      booked_services: [{ selectedCalendar: {}, services: [] }],
      links: [
        { value: "Booked for appointment" },
        { value: "Booked for first appointment" },
        { value: "Booked for second appointment" },
        { value: "Booked for third appointment" }
      ],
      dateOptions: {
        start: "08:30",
        step: "00:15",
        end: "18:30",
        disabledDate(time) {
          return time.addDays(1).getTime() < Date.now();
        }
      },
      save_attempted: false
    };
  },
  methods: {
    onSubmit() {
      this.save_attempted = true;

      let services = new BookedService({
        booked_services: this.booked_services
      }).for(new Candidate({ id: this.candidate.id }));

      services.save().then(res => {
        this.$message({
          type: "success",
          message:
            "Bookings Created! Please allow a few minutes for them to appear in Acuity Scheduling."
        });
        this.$emit("booked");
        this.$emit("close");
      });
    },
    onRemoveService(service) {
      let index = this.booked_services.indexOf(service);
      this.booked_services.splice(index, 1);
    },
    updateBookingToUtc(booking) {
      let index = this.booked_services.indexOf(booking);
      let calendar = this.calendars.filter(calendar => calendar.id == booking.calendar_id)[0];
      this.booked_services[index].location = calendar.timezone
      this.booked_services[index].appointment_time = moment.tz(
        booking.local_appointment_time,
        "YYYY-MM-DD HH:mm:ss",
        calendar.timezone
        )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    },
    setSelectedCalendar(booking) {
      let index = this.booked_services.indexOf(booking);

      delete this.booked_services[index].service_id;
      this.booked_services[index].services = this.calendars.filter(
        x => x.id == booking.calendar_id
      )[0].services;
    },
    querySearch(queryString, cb) {
      var links = this.links;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1950;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>