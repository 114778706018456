import Model from './AdminModel'

export default class CustomForm extends Model {
  resource() {
    return 'forms'
  }

  static custom(endpoint) {
    return new this().customRequest(endpoint);
  }

  customRequest(endpoint) {
    return {
      post: () => this.$http.post(`/${this.resource()}/${endpoint}`)
    };
  }
}