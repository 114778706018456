<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive" style="max-height: 400px; overflow-y: scroll;">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
              <tr v-for="(candidate, index) in candidates" :key="index">
                  <td>
                      {{candidate.name}}
                  </td>
                  <td>
                      <a :href="'/admin/candidates/'+candidate.id+'/edit'" class="btn btn-sm btn-primary">View</a>
                  </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    candidates: {
      type: Array
    }
  }
};
</script>

<style>
</style>
