<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>
            Actions
            <input type="checkbox" v-model="selectAll">
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(service, index) in booking_types" :key="index">
          <td>
            <span>{{service.title}}</span>
          </td>
          <td class="d-flex">
            <div class="d-flex align-items-center">
              <p class="mb-0 mr-3">Enable</p>
                <label class="jem-checkbox-wrap">
                  <input
                    type="checkbox"
                    @change="onTick"
                    v-model="enabled_booking_types"
                    :value="service.id"
                  >
                  <span class="jem-checkmark"></span>
                </label>
              </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props:{
        booking_types:{
            type:Array
        },
        company:{
            type: Object
        },
    },
    computed: {
      selectAll: {
        get: function() {
          return this.booking_types
            ? this.enabled_booking_types.length == this.booking_types.length
            : false;
        },
        set: function(value) {
          var selected = [];
          if (value) {
            this.booking_types.forEach(function(product) {
              selected.push(product.id);
            });
          }
          this.enabled_booking_types = selected;
          this.onTick();
        }
      }
    },
    data(){
        return {
            enabled_booking_types:this.company.booking_types ? this.company.booking_types.map(item => item.id) : []
        }
    },
    methods: {
        onTick() {
            //
            axios.post(`/api/v1/admin/companies/${this.company.id}/booking-types`, {booking_types: this.enabled_booking_types}).then((res) => {
                this.$message({
                    type:'success',
                    message:'Services Synced'
                })
            });
        },
    }
};
</script>

<style>
</style>
