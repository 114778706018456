<template>
  <span
    class="attachment-download"
    :style="{
      display: inline ? 'block' : 'inline-block',
      marginBottom: '10px'
    }"
  >
    <button @click="showDisclaimer">
      <div class="selected-file-item">
        <i class="fas fa-file fa-sm"></i>
        <el-tooltip class="item" effect="dark" :content="this.file.file_name" placement="top-start">
          <span style="margin-left: 10px">{{ this.truncatedFilename }}</span>
        </el-tooltip>
      </div>
    </button>

    <div v-if="showDisclaimerModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center mx-2">
          <div class="card shadow scrollable-modal-container">
            <button class="close" @click="disagree">
              <i class="fas fa-window-close"></i>
            </button>
            <div class="row pt-3">
              <div class="col-12">
                <p>
                  The file you are about to download contains private, confidential, and possibly
                  sensitive information. The candidate (individual) has consented to disclose this
                  information and for you to have access to this information
                </p>

                <p>
                  By pressing download, you are required by law (The Privacy Act 1988) to save the
                  information in a secure environment. You must take steps, as are reasonable in the
                  circumstances, to protect the information:
                </p>
                <ul>
                  <li>from misuse, interference and loss; and</li>
                  <li>from unauthorised access, modification or disclosure.</li>
                </ul>
                <p>
                  Once downloaded, Jem Health is no longer responsible for the security of the file
                  / information.
                </p>

                <h3>Are you sure you want to download?</h3>

                <hr />
                <div class="row mt-3">
                  <div class="col-12 d-flex justify-content-between">
                    <div class="mt-2">
                      <span class="jem-btn-secondary" @click="previewFile"> No, View Only </span>
                    </div>
                    <div class="mt-2">
                      <span class="jem-btn-primary" @click="agree">Yes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal dialog for displaying PDF -->
    <div v-if="showPdf" class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center mx-2">
          <div class="card shadow scrollable-modal-container">
            <button class="close" @click="closeModal">
              <i class="fas fa-window-close"></i>
            </button>
            <div class="row pt-3">
              <div class="col-12">
                <vue-pdf-embed :source="pdfUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed
  },
  props: {
    file: Object,
    result_id: Number,
    inline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDisclaimerModal: false,
      showPdf: false,
      pdfUrl: '',
      page: 1
    }
  },

  computed: {
    getDownloadUrl() {
      return `/api/v1/admin/results/${this.result_id}/attachments/${this.file.uuid}`
    },
    truncatedFilename() {
      const maxLength = 38
      const fileName = this.file.file_name

      const extension = fileName.includes('.') ? fileName.substring(fileName.lastIndexOf('.')) : ''
      const baseName = fileName.replace(extension, '')

      if (baseName.length + extension.length > maxLength) {
        const truncatedBaseName = baseName.substring(0, maxLength - extension.length - 3) // Adjust for "..."
        return truncatedBaseName + '...' + extension
      }

      return fileName
    }
  },

  methods: {
    closeModal() {
      this.showPdf = false
    },
    async previewFile() {
      if (this.showPdf) {
        this.showPdf = false
        return
      }
      try {
        const fileExtension = this.file.file_name.split('.').pop().toLowerCase()

        if (fileExtension === 'pdf' || fileExtension === 'docx') {
          const response = await axios.get(this.getDownloadUrl, {
            responseType: 'blob'
          })

          const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
          this.pdfUrl = URL.createObjectURL(pdfBlob)
          this.showPdf = true
        } else {
          alert('File not supported for preview.')
        }
      } catch (error) {
        console.error('Error previewing file:', error)
      }
    },
    async downloadAttachment() {
      try {
        // Make an API request to the download URL
        const response = await axios.get(this.getDownloadUrl, {
          responseType: 'blob' // Set the response type to blob for file downloads
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.file.file_name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Error downloading file:', error)
      }
    },
    showDisclaimer() {
      this.showDisclaimerModal = true
    },
    agree() {
      this.showDisclaimerModal = false
      this.downloadAttachment()
    },
    disagree() {
      this.showDisclaimerModal = false
    }
  }
}
</script>

<style scoped>
.close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999; /* Ensure it's on top of other elements */
  background: transparent;
  border: none;
  cursor: pointer;
}

.close span {
  font-size: 1.5rem;
  color: #000;
}

.modal-mask {
  position: fixed;
  z-index: 1950;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.attachment-download button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.attachment-download button:hover {
  text-decoration: underline;
}

.selected-file-item {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px; /* Adjust the margin between files */
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  max-height: 800px;
  overflow-y: auto;
}

.row {
  margin: 0;
}

.col-4,
.col-md-3 {
  padding-right: 15px;
  padding-left: 15px;
}

.col-12,
.col-md-4,
.col-md-2,
.col-md-1,
.col-md-6 {
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.invalid-feedback {
  color: red;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .card,
.modal-leave-active .card {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.preview-button {
  background: none;
  border: 1px solid #007bff; /* Add border */
  border-radius: 4px; /* Add border radius */
  padding: 3px; /* Add padding */
  font: inherit;
  cursor: pointer;
  color: #007bff;
  margin-left: 5px;
}

/* Tooltip styling */
.preview-button[title] {
  position: relative;
}
</style>
