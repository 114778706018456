<template>
  <div>
    <!-- title and btn row -->
    <div class="row" id="mk-company-form">
      <div class="col-12 d-flex justify-content-between">
        <h1>{{company.name || 'Create Company'}}</h1>

        <div>
          <button @click="onSubmit" :disabled="form.errors.any()" class="jem-btn-primary">Update</button>

          <button v-if="company.id" @click="onDelete" class="jem-btn-secondary ml-3">Delete</button>
        </div>
      </div>
    </div>

    <!-- inputs -->
    <div class="form-row">
      <div class="form-group mb-3 col-12 col-md-4">
        <label for="name">Name</label>
        <div :class="[{ 'jem-is-invalid': form.errors.has('email') }, 'jemform-control']">
          <!--  class="input-group" -->
          <input
            type="text"
            name="name"
            :class="[{ 'is-invalid': form.errors.has('name') }, 'form-control']"
            id="name-input"
            @keydown="form.errors.clear('name');"
            v-model="company.name"
            placeholder="Name"
          />
          <div class="invalid-feedback">Please enter a name.</div>
        </div>
      </div>

      <div class="form-group mb-3 col-12 col-md-4">
        <label for="email">Email</label>
        <div class="input-group">
          <input
            type="email"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('email') }, 'form-control']"
            id="email-input"
            v-model="company.email"
            @keydown="form.errors.clear('email');"
            placeholder="Email"
          />
          <div class="invalid-feedback">Email invalid.</div>
        </div>
      </div>
      <div class="form-group mb-3 col-12 col-md-4">
        <label for="email">Provider Number</label>
        <div class="input-group">
          <input
            type="email"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('provider_number') }, 'form-control']"
            id="email-input"
            v-model="company.provider_number"
            @keydown="form.errors.clear('provider_number');"
            placeholder="#"
          />
          <div class="invalid-feedback">Provider Number invalid.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="name">Xero Contact</label>
        <div class="input-group">
          <el-select class="w-100" filterable v-model="company.xero_contact_id" placeholder="Xero Contact">
            <el-option
              v-for="item in xero_contacts"
              :key="item.id"
              :label="(item.first_name || item.email_address || item.id) + ' ' + (item.last_name || '')"
              :value="item.id"
            ></el-option>
          </el-select>
          <div
            v-if="form.errors.has('xero_account_id')"
            class="invalid-feedback"
          >Please enter a valid xero account.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="name">Preferences</label>
        <div class="input-group">
          <el-checkbox v-model="company.show_on_invoice">Show Company Information on Invoices</el-checkbox>
        </div>
      </div>
    </div>

    <hr class="my-5" />

    <!-- company table -->
    <div class="row pt-3">
      <div v-if="company.id" class="col-md-12">
        <tabs>
          <tab name="Recruiters">
            <company-recruiters :company_id="company.id"></company-recruiters>
          </tab>
          <tab name="Enabled Services">
            <company-services :company="company" :booking_types="booking_types"></company-services>
          </tab>
          <tab name="Invoices">
            <company-invoice :company="company" :invoice_payment_types="invoice_payment_types" :xero_accounts="xero_accounts"></company-invoice>
          </tab>
          <tab name="Candidates">
            <candidate-index :company_id="company.id" :candidate_statuses="candidate_statuses"></candidate-index>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Company from "../../models/Company";
import Form from "form-backend-validation";

export default {
  props: {
    booking_types: {
      type: Array
    },
    initial_company: {
      type: Object
    },
    invoice_payment_types:{
      type: Array
    },
    xero_accounts:{
      type: Array
    },
    xero_contacts: {
      type: Array
    },
    candidate_statuses: {
      type: Array
    }
  },
  data() {
    return {
      company: new Company(this.initial_company || {}),
      form: new Form()
    };
  },
  methods: {
    onSubmit() {
      // removing eager loaded relationships
      let postCompany = this.company;
      delete postCompany.booking_types;
      delete postCompany.invoices;

      postCompany
        .save()
        .then(res => {
          this.$message({
            type: "success",
            message: "Saved!"
          });
          this.company = new Company(res);
          var url = window.location.toString();
          url = url.replace("create", `${res.id}/edit`);
          window.history.pushState(null, null, url);
        })
        .catch(errors => {
          this.form.errors.record(errors.response.data.errors);
        });
    },
    onDelete() {
      this.company.delete().then(res => {
        window.location.href = "/admin/companies";
      });
    }
  }
};
</script>

<style>
</style>
