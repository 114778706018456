import React, { useEffect, useState } from 'react';
import { ReactFormGenerator, ElementStore } from 'sprint-digital-react-form-builder';

const Demobar = (props) => {
  const [data, setData] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [shortPreviewVisible, setShortPreviewVisible] = useState(false);
  const [roPreviewVisible, setRoPreviewVisible] = useState(false);
  const [answerData, setAnswerData] = useState([]);

  useEffect(() => {
    const subscription = ElementStore.subscribe(state => setData(state.data));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const showPreview = () => setPreviewVisible(true);
  const showShortPreview = () => setShortPreviewVisible(true);
  const showRoPreview = () => setRoPreviewVisible(true);
  const closePreview = () => {
    setPreviewVisible(false);
    setShortPreviewVisible(false);
    setRoPreviewVisible(false);
  };

  const onSubmit = (data) => {
    setAnswerData(data);
    setShortPreviewVisible(false);
  };

  let modalClass = 'modal';
  if (previewVisible) {
    modalClass += ' show d-block';
  }

  let shortModalClass = 'modal short-modal';
  if (shortPreviewVisible) {
    shortModalClass += ' show d-block';
  }

  let roModalClass = 'modal ro-modal';
  if (roPreviewVisible) {
    roModalClass += ' show d-block';
  }

  return (
    <div className="clearfix" style={{ margin: '10px', width: '70%' }}>
      <h4 className="float-left">Preview</h4>
      <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={showPreview}>Preview Form</button>
      <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={showShortPreview}>Alternate/Short Form</button>
      <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={showRoPreview}>Read Only Form</button>

      {previewVisible &&
        <div className={modalClass} role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action="/"
                back_name="Back"
                answer_data={{}}
                action_name="Save"
                variables={props.variables}
                data={data}
                onSubmit={onSubmit}
              />
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePreview}>Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      {roPreviewVisible &&
        <div className={roModalClass}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action="/"
                back_name="Back"
                answer_data={{}}
                action_name="Save"
                form_action="/"
                form_method="POST"
                read_only={true}
                variables={props.variables}
                hide_actions={true}
                data={data}
              />
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePreview}>Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      {shortPreviewVisible &&
        <div className={shortModalClass}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action=""
                answer_data={{}}
                form_action="/"
                form_method="POST"
                data={data}
                display_short={true}
                variables={props.variables}
                hide_actions={false}
              />
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePreview}>Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      {data && (
        <textarea value={JSON.stringify(data, null, 2)} readOnly></textarea>
      )}

      {answerData && (
        <>
          answer_data:
          <textarea value={JSON.stringify(answerData, null, 2)} readOnly></textarea>
        </>
      )}
    </div>
  );
};

export default Demobar;