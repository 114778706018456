<template>
  <div>
    <div v-if="!submitted" class="row">
      <div class="col-md-12 mb-3">
        <h1>Welcome to the Jem Health Portal {{ candidate.name }}</h1>
        <hr />
        <h3>
          Please select your preferences below and a member of the Jem Health Team will be in touch.
        </h3>
      </div>
      <div class="col-md-3">
        <label>Location</label>
        <el-select filterable class="w-100" v-model="location" placeholder="Select">
          <el-option-group v-for="group in states" :key="group.name" :label="group.name">
            <el-option
              v-for="item in group.cities"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="col-md-3">
        <label>Preferred Dates</label>
        <el-date-picker
          type="dates"
          value-format="dd/MM/yyyy"
          format="dd/MM/yyyy"
          class="w-100"
          v-model="day"
          :picker-options="dateOptions"
          placeholder="Pick minimum 5 dates"
        ></el-date-picker>
      </div>
      <div class="col-md-3">
        <label>Time Preference</label>
        <el-select multiple class="w-100" v-model="time" placeholder="Select">
          <el-option
            v-for="item in times"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <!-- Date Of Birth -->
      <div class="form-group col-12 col-md-3">
        <label for="name">Date of Birth</label>
        <div class="input-group">
          <input
            type="date"
            name="dob"
            :class="[
              {
                'is-invalid': form.errors.has('dob')
              },
              'form-control'
            ]"
            @keydown="form.errors.clear('dob')"
            id="dob"
            v-model="dob"
            placeholder="Date of Birth"
          />
          <div class="invalid-feedback">Please provide a valid date of birth value</div>
        </div>
      </div>

      <div class="col-md-12 mt-3 d-flex justify-content-center">
        <button class="btn btn-md btn-primary" @click="onSubmit">Send Preferences</button>
      </div>
    </div>
    <div v-else>
      <h2>Thanks for submitting, a member of the Jem Health team will be in touch.</h2>
    </div>
  </div>
</template>

<script>
import Form from 'form-backend-validation'
export default {
  props: {
    candidate: {
      type: Object
    },
    states: {
      type: Array
    }
  },
  data() {
    return {
      submitted: false,
      dateOptions: {
        disabledDate(time) {
          return time.addDays(1).getTime() < Date.now() || time.getDay() == 0 || time.getDay() == 6
        }
      },
      days: [
        {
          value: 'Monday',
          label: 'Monday'
        },
        {
          value: 'Tuesday',
          label: 'Tuesday'
        },
        {
          value: 'Wednesday',
          label: 'Wednesday'
        },
        {
          value: 'Thursday',
          label: 'Thursday'
        },
        {
          value: 'Friday',
          label: 'Friday'
        },
        {
          value: 'Saturday',
          label: 'Saturday'
        }
      ]
    }
  },
  data() {
    return {
      submitted: false,
      dateOptions: {
        disabledDate(time) {
          // Disable weekends (Saturday and Sunday)
          const day = time.getDay()
          return day === 0 || day === 6 || time.addDays(1).getTime() < Date.now()
        }
      },
      days: [
        {
          value: 'Monday',
          label: 'Monday'
        },
        {
          value: 'Tuesday',
          label: 'Tuesday'
        },
        {
          value: 'Wednesday',
          label: 'Wednesday'
        },
        {
          value: 'Thursday',
          label: 'Thursday'
        },
        {
          value: 'Friday',
          label: 'Friday'
        },
        {
          value: 'Saturday',
          label: 'Saturday'
        },
        {
          value: 'Sunday',
          label: 'Sunday'
        }
      ],
      times: [
        {
          value: 'Anytime',
          label: 'Anytime'
        },
        {
          value: 'Early Morning',
          label: 'Early Morning'
        },
        {
          value: 'Mid Morning',
          label: 'Mid Morning'
        },
        {
          value: 'Early Afternoon',
          label: 'Early Afternoon'
        },
        {
          value: 'Late Afternoon',
          label: 'Late Afternoon'
        }
      ],
      location: '',
      time: '',
      day: '',
      dob: null,
      form: new Form()
    }
  },
  created() {
    if (this.candidate && this.candidate.dob) {
      this.dob = moment(this.candidate.dob).format('YYYY-MM-DD')
    }
  },
  methods: {
    onSubmit() {
      let data = {
        location: this.location,
        preferred_day: this.day ? this.day.join() : null,
        preferred_time: this.time.join(),
        dob: this.dob
      }

      if (this.day.length < 5) {
        this.$message({
          type: 'error',
          message: 'You have not selected 5 dates'
        })
        return
      } else if (data.location && data.preferred_day && data.preferred_time) {
        axios
          .post(`/api/v1/candidate/${this.candidate.id}/preferences`, data)
          .then((res) => {
            this.submitted = true
            this.$message({
              type: 'success',
              message: 'Your preferences were sent to Jem Health'
            })
          })
          .catch((errors) => {
            this.form.errors.record(errors.response.data.errors)
            this.$message({
              type: 'error',
              message: 'Whoops! Something went wrong.'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: 'You have not entered a location, date or time yet'
        })
      }
    }
  }
}
</script>
