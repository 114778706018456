<template>
  <paginated-table
    v-if="booking_types.data"
    :tableData="tableData"
    :paginatedData="booking_types"
    @pagination-change-page="getBookingTypes"
  />
</template>

<script>
import BookingType from "../../models/BookingType";

export default {
  mounted() {
    this.getBookingTypes();
  },
  data() {
    return {
      booking_types: {},
      tableData: {
        columns: [
          { label: "Title", name: "title", sortable: true },
        ],
        showSearch: true,
        showPerPage: true,
        edit:true,
        viewUrl: "/admin/booking_types"
      }
    };
  },
  methods: {
    getBookingTypes(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
      let booking_types = BookingType.page(page).params({per_page:per_page});
      
      if(desc && sortColumn) {
        booking_types.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        booking_types.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        booking_types.orderBy('title');
      }
      if (query) {
        booking_types.where("where_like", query);
      }
      booking_types.get().then(response => {
        this.booking_types = response;
      });
    }
  }
};
</script>