<template>
  <div>
    <div class="col-md-12 pt-3 d-flex align-items-end">
      <candidate-invoices-modal
        v-if="showInvoiceModal"
        @close="selected_invoice = {}; showInvoiceModal = false"
        @added="onAdd"
        @deleted="onDelete"
        :invoice_payment_types="invoice_payment_types"
        :initial_invoice="selected_invoice"
        :companies="companies"
        :candidate="candidate"
        :xero_accounts="xero_accounts"
      ></candidate-invoices-modal>
    </div>

    <div class="col-md-12">
        <paginated-table
        v-if="invoices.data"
        :tableData="tableData"
        :paginatedData="invoices"
        @pagination-change-page="getInvoices"
        @on-view="onView"
        />

    </div>
  </div>
</template>

<script>
import Invoice from "../../../models/Invoice";

export default {
  props: {
    company: {
      type: Object
    },
    companies:{
        type: Array
    },
    xero_accounts: {
        type: Array
    },
    invoice_payment_types:{
      type: Array
    }
  },
  data() {
    return {
      showInvoiceModal: false,
      selected_invoice: { candidate: {} },
      invoices: {},
      candidate: {},
      tableData: {
        columns: [
          { label: "Xero ID", name: "xero_id", sortable: true },
          { label: "Ref", name: "name", sortable: true },
          { label: "Company", name: "company.name", sortable: true },
          { label: "Recruiter", name: "recruiter.full_name", sortable: true },
          { label: "Candidate", name: "candidate.name", sortable: true },
          { label: "Status", name: "status", sortable: true },
          {
            label: "Amount (excl. GST)",
            name: "dollar_total.formatted",
            sortable: true
          },
          {
            label: "Created At",
            name: "created_at",
            date: true,
            sortable: true
          }
        ],
        edit: true,
        showSearch: true,
        overrideView: true,
        showPerPage: true,
        viewUrl: "/admin/invoices"
      }
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    onAdd(invoice) {
      this.invoices.push(invoice);
    },
    onDelete(passed_invoice)
    {
      this.invoices = this.invoices.filter(x => x.id != passed_invoice.id);
    },
    getInvoices(
      page = 1,
      sortColumn = null,
      query = null,
      per_page = 15,
      desc = false
    ) {
      let invoices = Invoice.page(page).include(
                        "candidate,xero-payments,line-items,recruiter,company,invoice-payment-type"
                    ).where('company_id', this.company.id);

      if (desc && sortColumn) {
        invoices.orderBy("-" + (sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        invoices.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        invoices.orderBy("-created_at");
      }

      if (query) {
        invoices.where("where_like", query);
      }

      invoices.get().then(res => {
        this.invoices = res;
      });
    },
    onView(invoice) {
      this.selected_invoice = invoice;
      this.candidate = invoice.candidate;
      this.showInvoiceModal = true;
    }
  }
};
</script>

<style>
</style>
