import React, { useEffect, useState, useRef, createRef } from 'react';
import { Box, VStack, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, Grid, GridItem, Text, Heading, Button, ButtonGroup, MenuList, MenuItem, Menu, HStack, Divider } from '@chakra-ui/react';
import CandidateFormTopNavBar from './includes/CandidateFormTopNavBar';
import CandidateCommentDrawer from './includes/CandidateCommentDrawer';
import CandidateMoreInfoModal from './includes/CandidateMoreInfoModal';
import CandidateFinaliseModal from './includes/CandidateFinaliseModal';
import CandidateCustomFormTableOfContent from './includes/CandidateCustomFormTableOfContent';
import CandidateSubmitDoctorReviewModal from './includes/CandidateSubmitDoctorReviewModal';
import CandidatePendingReportModal from './includes/CandidatePendingReportModal';
import DoctorReviewReportsIndex from './includes/DoctorReviewReportsIndex';
import DoctorReviewForm from './includes/DoctorReviewForm';
import { ReactFormGenerator } from 'sprint-digital-react-form-builder';
import CustomFormResult from '../../../backend/models/CustomFormResult';
import DoctorReview from '../../../backend/models/DoctorReview';
import { FaStethoscope, FaClipboard, FaUser, FaFileMedical } from "react-icons/fa6";
import { applyCustomStyleForHeading } from '../../utils/formUtils';
import ChangeHistory from './includes/ChangeHistory';

const CandidateForms = ({ candidateId, candidate, customFormResults, doctorReview, doctorReviews, doctorReviewReports, bookingTypeId, user }) => {
    // State management
    const [isCommentsOpen, setIsCommentsOpen] = useState(false);
    const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
    const [isPendingReportOpen, setIsPendingReportOpen] = useState(false);
    const [isFinaliseOpen, setIsFinaliseOpen] = useState(false);
    const [isSubmitDoctorReviewOpen, setIsSubmitDoctorReviewOpen] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [customFormResult, setCustomFormResult] = useState(new CustomFormResult({}));
    const [doctorReviewData, setDoctorReviewData] = useState(doctorReview);
    const [apiDoctorReview, setApiDoctorReview] = useState(new DoctorReview({}));
    const [localCustomFormResults, setLocalCustomFormResults] = useState(customFormResults);
    const doctorReviewFormHeadings = doctorReview?.is_fitslip_enabled || false ? ['Summary', 'Recommendation'] : [];
    const [activeDoctorReviewHeading, setActiveDoctorReviewHeading] = useState(0);
    const bookingTypes = candidate?.booking_types || [];
    const toast = useToast();
    const formRefs = useRef([]);
    const formGeneratorRef = useRef(null);
    const doctorReviewCustomFormResult = customFormResults.find(result => result.custom_form.form_type_id === 3) || undefined;
    const hasManageAssessmentPermission = user?.permissions?.some(permission => permission.name === "Manage Assessment");
    const hasManageDoctorPermission = user?.permissions?.some(permission => permission.name === "Manage Doctor");
    const hasSuperAdminRole = user?.roles?.some(role => role.name === "super_admin");
    useEffect(() => {
        setDoctorReviewData(doctorReview);
    }, [doctorReview]);

    // Initialize form refs
    useEffect(() => {
        formRefs.current = localCustomFormResults.map((_, i) => formRefs.current[i] ?? createRef());
        if (localCustomFormResults[activeTabIndex]?.custom_form_version) {
            applyCustomStyleForHeading(localCustomFormResults[activeTabIndex].custom_form_version.form_template.task_data, localCustomFormResults[activeTabIndex].custom_form_version.form_template.custom_styles);
        }
    }, [localCustomFormResults]);

    // Set the width and height attributes on canvas elements after render
    useEffect(() => {
        const setCanvasAttributes = () => {
            const canvasElements = document.querySelectorAll('.react-form-builder-form canvas');
            canvasElements.forEach(canvas => {
                canvas.setAttribute('width', '302');
                canvas.setAttribute('height', '152');
            });
        };

        // Wait a bit to ensure the form is rendered
        const timer = setTimeout(() => {
            setCanvasAttributes();
        }, 500);

        return () => clearTimeout(timer);
    }, [activeTabIndex, customFormResults]);

    // Update active form when tab changes
    useEffect(() => {
        setCustomFormResult(new CustomFormResult(localCustomFormResults[activeTabIndex]));
        if (localCustomFormResults[activeTabIndex]?.custom_form_version) {
            applyCustomStyleForHeading(localCustomFormResults[activeTabIndex].custom_form_version.form_template.task_data, localCustomFormResults[activeTabIndex].custom_form_version.form_template.custom_styles);
        
        }
    }, [activeTabIndex, localCustomFormResults]);

    // Handle form submission
    const handleSubmit = (data) => {
        setCustomFormResult(new CustomFormResult({ ...customFormResult, results: data }));
    };

    // Save form data
    useEffect(() => {
        if (!customFormResult.results) return;

        customFormResult.save()
            .then(() => {
                toast({
                    title: "Form data saved successfully.",
                    status: 'success',
                    duration: 3000
                });
                updateLocalCustomFormResults();
            })
            .catch(() => {
                toast({
                    title: "Error saving form data. Please try again.",
                    status: 'error',
                    duration: 3000
                });
            });
    }, [customFormResult]);


    const updateLocalCustomFormResults = () => {
        const customFormResult = new CustomFormResult();
        customFormResult.where('candidate_id', candidateId).where('booking_type_id', bookingTypeId).params({per_page: 100}).get().then((response) => {
            // Update only the result property of matching forms
            setLocalCustomFormResults(prevResults => 
                prevResults.map(prevResult => {
                    const newResult = response.data.find(newResult => newResult.id === prevResult.id);
                    if (newResult) {
                        return { ...prevResult, result: newResult.result };
                    }
                    return prevResult;
                })
            );
        });
    }


    // Handle save button click
    const handleSave = (saveDoctorReview = false, doctorReviewStatusId = undefined) => {
        const formCount = localCustomFormResults.filter(
            result => result.custom_form.form_type_id !== 3
        ).length;
        const maxTabs = formCount + (doctorReview ? 2 : 0);

        // Skip saving on reports tab
        if (activeTabIndex + 1 === maxTabs && doctorReview) return;

        // Handle doctor review status changes
        if (saveDoctorReview) {
            setApiDoctorReview(new DoctorReview({
                ...doctorReviewData, 
                doctor_id: undefined, 
                doctor_review_status_id: doctorReviewStatusId
            }));
            return;
        }

        // Save doctor review form if enabled
        const isDoctorReviewTab = doctorReview && activeTabIndex + 2 === maxTabs;
        if (isDoctorReviewTab && doctorReview.is_fitslip_enabled === 1) {
            setApiDoctorReview(new DoctorReview({
                ...doctorReviewData, 
                doctor_id: undefined, 
                doctor_review_status_id: undefined
            }));
        }

        submitCurrentForm();
    };

    const submitCurrentForm = () => {
        const currentFormRef = formRefs.current[activeTabIndex];
        if (!currentFormRef?.current) return;
        
        try {
            const formElement = currentFormRef.current
                .getElementsByClassName('react-form-builder-form')[0]
                ?.querySelector('form');

            formElement?.dispatchEvent(new Event('submit', { 
                bubbles: true, 
                cancelable: true 
            }));
        } catch (error) {
            toast({
                title: "Error submitting form",
                status: 'error',
                duration: 3000
            });
        }
    };

    useEffect(() => {
        if (!apiDoctorReview.id) return;

        apiDoctorReview.save()
            .then((response) => {
                toast({
                    title: "Doctor review saved successfully.",
                    status: 'success',
                    duration: 3000
                });

                setDoctorReviewData(response);
            })
            .catch(() => {
                toast({
                    title: "Error saving doctor review. Please try again.",
                    status: 'error',
                    duration: 3000
                });
            });
    }, [apiDoctorReview]);

    const tabStyles = {
        border: "1px",
        borderColor: "gray.200",
        _selected: { 
            bg: '#EDF2F7',
            color: 'teal.500',
            borderColor: 'gray.200'
        },
        _focus: {
            boxShadow: 'none',
            outline: 'none'
        }
    };

    return (
        <>
            <Box minH="100vh">
                <Box width="100%">
                    <CandidateFormTopNavBar 
                        onSave={handleSave}
                        onCancel={() => setIsCommentsOpen(true)}
                        onCommentsOpen={() => setIsCommentsOpen(true)}
                        candidate={candidate}
                        bookingTypes={bookingTypes}
                        bookingTypeId={bookingTypeId}
                        doctorReviews={doctorReviews}
                        doctorReview={doctorReviewData}
                        onMoreInfoClick={() => {setIsMoreInfoOpen(true)}}
                        onPendingReportClick={() => {setIsPendingReportOpen(true)}}
                        onFinaliseClick={() => {
                            handleSave();
                            setTimeout(() => {
                                setIsFinaliseOpen(true);
                            }, 1500);
                        }}
                        onSubmitDoctorReview={() => {setIsSubmitDoctorReviewOpen(true)}}
                        user={user}
                    />
                    <Box mx={0} px={0}>
                        <VStack spacing={6} align="stretch">
                            <Box bg="white" rounded="md" shadow="sm">
                                <Tabs 
                                    index={activeTabIndex} 
                                    onChange={setActiveTabIndex}
                                >
                                    <TabList px={6}>
                                        {localCustomFormResults.length === 0 && !doctorReview && (
                                            <Tab {...tabStyles}>
                                                <FaFileMedical color="#718096" style={{ marginRight: '4px' }} />
                                                N/A
                                            </Tab>
                                        )}
                                        {localCustomFormResults.filter((result) => result.custom_form.form_type_id !== 3).map((result) => (
                                            <Tab key={result.id} {...tabStyles}>
                                                {result.custom_form?.form_type_id === 1 && (
                                                    <FaUser color="#ED8936" style={{ marginRight: '4px' }} />
                                                )}
                                                {result.custom_form?.form_type_id === 2 && (
                                                    <FaClipboard color="#3182CE" style={{ marginRight: '4px' }} />
                                                )}
                                                {result.title}
                                            </Tab>
                                        ))}
                                        {doctorReview && 
                                            <Tab {...tabStyles}><FaStethoscope color="teal.500" style={{ marginRight: '4px' }} />Doctor Review</Tab>
                                        }
                                        
                                        {doctorReview && 
                                            <Tab {...tabStyles}>
                                                <FaFileMedical color="#718096" style={{ marginRight: '4px' }} />
                                                Reports
                                            </Tab>
                                        }
                                        
                                    </TabList>
                                    <TabPanels bg="gray.100" p={2} rounded="md">
                                        {localCustomFormResults.length === 0 && !doctorReview && (
                                            <TabPanel>
                                                <Box 
                                                    p={6}
                                                    height="70vh" 
                                                    display="flex" 
                                                    justifyContent="center"
                                                >
                                                    <Heading size="md">No forms or Doctor Review available</Heading>
                                                </Box>
                                            </TabPanel>
                                        )}
                                        {localCustomFormResults.filter((result) => result.custom_form.form_type_id !== 3).map((result, index) => (
                                            <TabPanel key={result.id} ref={formRefs.current[index]}>
                                                <Grid templateColumns={{ base: "1fr", md: "3fr 1fr" }} gap={6}>
                                                    {/* Form Section */}
                                                    <GridItem>
                                                        <Box 
                                                            bg="white" 
                                                            p={4} 
                                                            rounded="md" 
                                                            shadow="sm"
                                                            sx={{
                                                                '.react-form-builder-form .rfb-item .row': {
                                                                    border: 'none !important'
                                                                }
                                                            }}
                                                        >
                                                            <ReactFormGenerator
                                                                key={`form-${activeTabIndex}`}
                                                                ref={formGeneratorRef}
                                                                answer_data={result.result}
                                                                action_name="Save"
                                                                data={result.custom_form_version.form_template.task_data}
                                                                onSubmit={handleSubmit}
                                                                submitButton={
                                                                    <button className="btn btn-primary" style={{ visibility: 'hidden' }}>Submit</button>
                                                                }
                                                                show_internal={true}
                                                                skip_validations={true}
                                                            />
                                                        </Box>
                                                    </GridItem>
                                                    <GridItem>
                                                        <Box bg="white" p={4} rounded="md" shadow="sm">
                                                            <Tabs>
                                                                <TabList>
                                                                    <Tab>Form</Tab>
                                                                    <Tab>Change History</Tab>
                                                                </TabList>
                                                                <TabPanels>
                                                                    <TabPanel>
                                                                        <VStack align="stretch">
                                                                            <CandidateCustomFormTableOfContent 
                                                                                customFormResult={result}
                                                                                title={result.title}
                                                                            />
                                                                        </VStack>
                                                                    </TabPanel>
                                                                    <TabPanel>
                                                                        <VStack spacing={4} align="stretch">
                                                                            <ChangeHistory audits={result.audits} title={result.title}/>
                                                                        </VStack>
                                                                    </TabPanel>
                                                                </TabPanels>
                                                            </Tabs>
                                                        </Box>
                                                    </GridItem>
                                                </Grid>
                                            </TabPanel>
                                        ))}
                                        {doctorReview && (
                                        <TabPanel key="doctor-review-tab-panel" ref={formRefs.current[doctorReviewCustomFormResult ? customFormResults.length - 1 : customFormResults.length]}>
                                            <Grid templateColumns={{ base: "1fr", md: "3fr 1fr" }} gap={6}>
                                                <GridItem>
                                                    <Box 
                                                        bg="white" 
                                                        p={4} 
                                                        rounded="md" 
                                                        shadow="sm"
                                                    >
                                                        {doctorReview.is_fitslip_enabled === 1 && (
                                                            <DoctorReviewForm 
                                                                doctorReview={doctorReviewData} 
                                                                onDoctorReviewChange={(e) => {
                                                                    setDoctorReviewData(e)
                                                                }}
                                                            />
                                                        )}
                                                        {localCustomFormResults.filter((result) => result.custom_form.form_type_id === 3).map((result, index) => (
                                                            <ReactFormGenerator
                                                                key={`form-${activeTabIndex}`}
                                                                ref={formGeneratorRef}
                                                                answer_data={result.result}
                                                                action_name="Save"
                                                                data={result.custom_form_version.form_template.task_data}
                                                                onSubmit={handleSubmit}
                                                                submitButton={
                                                                    <button className="btn btn-primary" style={{ visibility: 'hidden' }}>Submit</button>
                                                                }
                                                                show_internal={true}
                                                                skip_validations={true}
                                                            />
                                                        ))}
                                                    </Box>
                                                </GridItem>
                                                <GridItem>
                                                    <Box bg="white" p={4} rounded="md" shadow="sm">
                                                        <Tabs>
                                                            <TabList>
                                                                <Tab>Form</Tab>
                                                                <Tab>Change History</Tab>
                                                            </TabList>
                                                            <TabPanels>
                                                                <TabPanel>
                                                                    <VStack align="stretch">
                                                                        {doctorReviewFormHeadings.length !== 0 && (
                                                                            <>
                                                                                <Text fontSize="sm" color="gray.500" my={1}>Jump to section</Text>
                                                                                {doctorReviewFormHeadings.map((section, index) => (
                                                                                    <Button 
                                                                                        key={`headings-${index}`}
                                                                                        fontSize="sm"
                                                                                        colorScheme="teal"
                                                                                        textAlign="left"
                                                                                        justifyContent="flex-start"
                                                                                        variant={activeDoctorReviewHeading === index ? 'solid' : 'ghost'}
                                                                                        onClick={() => {
                                                                                            setActiveDoctorReviewHeading(index);        
                                                                                            const elementId = index === 0 ? 'doctor-review-summary' : 'doctor-review-recommendation';
                                                                                            const element = document.getElementById(elementId);
                                                                                            if (element) {
                                                                                                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {section}
                                                                                    </Button> 
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                        {doctorReviewCustomFormResult && (
                                                                            <CandidateCustomFormTableOfContent 
                                                                                customFormResult={doctorReviewCustomFormResult}
                                                                                title={doctorReviewCustomFormResult?.title}
                                                                            />
                                                                        )}
                                                                    </VStack>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <VStack spacing={4} align="stretch">
                                                                        <ChangeHistory audits={doctorReview?.audits} title={'Doctor Review'} />
                                                                        {doctorReviewCustomFormResult && (
                                                                            <ChangeHistory audits={doctorReviewCustomFormResult?.audits} title={doctorReviewCustomFormResult?.title} />
                                                                        )}
                                                                    </VStack>
                                                                </TabPanel>
                                                            </TabPanels>
                                                        </Tabs>
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>
                                        )}

                                        {doctorReview && (
                                        <TabPanel key="report-tab-panel">
                                            <DoctorReviewReportsIndex 
                                                candidate={candidate}
                                                doctorReview={doctorReviewData}
                                                doctorReviewReports={doctorReviewReports}
                                            />
                                        </TabPanel>
                                        )}
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </VStack>
                    </Box>
                </Box>
            </Box>

            <CandidateCommentDrawer 
                isOpen={isCommentsOpen}
                onClose={() => setIsCommentsOpen(false)}
                candidateId={candidateId}
                doctorReviewId={doctorReview?.id}
            />
            {doctorReview && (
                <>
                    <CandidateMoreInfoModal 
                        isOpen={isMoreInfoOpen}
                        onClose={() => setIsMoreInfoOpen(false)}
                        doctorReview={doctorReview}
                        onSubmit={() => {
                            setIsMoreInfoOpen(false);
                            setDoctorReviewData({...doctorReviewData, doctor_review_status_id: 2});
                            handleSave(true, 2);
                        }}
                        onLocalDoctorReviewChange={(data) => {setDoctorReviewData(data);}}
                    />
                    <CandidatePendingReportModal 
                        isOpen={isPendingReportOpen}
                        onClose={() => setIsPendingReportOpen(false)}
                        doctorReview={doctorReview}
                        onSubmit={() => {
                            setIsPendingReportOpen(false);
                            setDoctorReviewData({...doctorReviewData, doctor_review_status_id: 3});
                            handleSave(true, 3);
                        }}
                        onLocalDoctorReviewChange={(data) => {setDoctorReviewData(data);}}
                    />
                    <CandidateFinaliseModal
                        isOpen={isFinaliseOpen}
                        onClose={() => setIsFinaliseOpen(false)}
                        doctorReview={doctorReviewData}
                        onSubmit={() => {
                            setIsFinaliseOpen(false);
                            setDoctorReviewData({doctorReviewData, doctor_review_status_id: 4});
                            handleSave(true, 4);
                        }}
                        onLocalDoctorReviewChange={(data) => {setDoctorReviewData(data);}}
                    />
                </>
            )}
            <CandidateSubmitDoctorReviewModal
                isOpen={isSubmitDoctorReviewOpen}
                onClose={() => setIsSubmitDoctorReviewOpen(false)}
                candidateId={candidateId}
                bookingTypeId={bookingTypeId}
                doctorReview={doctorReviewData}
            />
        </>
    );
};

export default CandidateForms;