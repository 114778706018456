import React from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    VStack,
    Text,
    Badge,
    Flex,
    Link,
    Tooltip
} from '@chakra-ui/react';
import PaginationButtons from '../PaginationButtons';
import { format } from 'date-fns';
import DoctorReviewStatus from '../../../enums/DoctorReviewStatus';

const truncateText = (text, maxLength = 100) => {
    if (!text || text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
};

const DoctorReviewTable = ({
    data,
    showComments = false,
    commentField = '',
    currentPage,
    itemsPerPage,
    lastPage,
    setCurrentPage,
    doctorReviews,
    doctor_review_status_id = 1
}) => {
    const formatAppointmentTime = (appointmentTime) => {
        return format(new Date(appointmentTime), 'MM/dd/yy, hh:mm a');
    };

    const sortedData = data.sort((a, b) => b?.candidate?.urgent - a?.candidate?.urgent);

    return (
        <Box overflowX="auto">
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Candidate name</Th>
                        <Th>Appointment details</Th>
                        {doctor_review_status_id !== DoctorReviewStatus.FINALISED && <Th>Urgency</Th>}
                        <Th>{doctor_review_status_id !== DoctorReviewStatus.FINALISED ? 'Assigned to' : 'Reviewed by'}</Th>
                        <Th>Services</Th>
                        {doctor_review_status_id !== DoctorReviewStatus.FINALISED&& showComments && <Th>Doctor's comment</Th>}
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedData.map((item) => (
                        <Tr key={item.id}>
                            <Td>{item?.candidate?.name}</Td>
                            <Td>
                                <VStack align="start">
                                    <Text>{item.location && item.location.title}</Text>
                                    <Text>{item.booked_service && formatAppointmentTime(item.booked_service.appointment_time)}</Text>
                                </VStack>
                            </Td>
                            {doctor_review_status_id !== DoctorReviewStatus.FINALISED && (
                              <Td>
                                {item?.candidate?.is_urgent_results == true && (
                                    <Badge 
                                        variant='solid' 
                                        sx={{
                                            backgroundColor: '#E53E3E',
                                            color: 'white'
                                        }}
                                    >
                                        Urgent
                                    </Badge>
                                )}
                                </Td>
                            )}
                            <Td>{item?.doctor?.full_name}</Td>
                            <Td>{item?.booking_type?.title}</Td>
                            {showComments && (
                                <Td>
                                    <Tooltip 
                                        label={
                                            <Box>
                                                <Text mb={2}>{item[commentField]}</Text>
                                                <Text>{item?.doctor?.full_name}</Text>
                                            </Box>
                                        }
                                        hasArrow 
                                        placement="bottom"
                                        maxW="400px"
                                        whiteSpace="pre-wrap"
                                    >
                                        <Text>{truncateText(item[commentField])}</Text>
                                    </Tooltip>
                                </Td>
                            )}
                            <Td>
                                    <Button size="md" variant="outlineSecondary" onClick={() => {
                                        window.open(`/admin/candidates/${item.candidate_id}/forms?booking_type_id=${item.booking_type_id}`);
                                    }}>
                                        Review
                                    </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Flex justifyContent="center" mt={4}>
                <PaginationButtons
                    currentPage={currentPage}
                    totalPages={lastPage}
                    setCurrentPage={setCurrentPage}
                />
            </Flex>
        </Box>
    );
};

export default DoctorReviewTable;
