import React, {useState, useEffect, useRef} from 'react';
import { Box, VStack, Text, Heading, Textarea, HStack, Checkbox, Input, SimpleGrid, Button, Image } from '@chakra-ui/react';
import { doctorReviewChecklists } from '../../../utils/formUtils';
import SignaturePad from 'react-signature-canvas';
import { FaUndo } from "react-icons/fa";

const DoctorReviewForm = ({ doctorReview, onDoctorReviewChange }) => {
    const [doctorReviewData, setDoctorReviewData] = useState(doctorReview);
    const isFormLocked = doctorReview.doctor_review_status_id == 4;
    const sigPad = useRef({});

    useEffect(() => {
        onDoctorReviewChange(doctorReviewData);
    }, [doctorReviewData]);

    const hasCheckedItem = () => {
        return doctorReviewChecklists.some(checklist => 
            doctorReviewData[checklist.value] === true || doctorReviewData[checklist.value] === 1
        );
    };

    const clearSignature = () => {
        if (doctorReviewData.signature_url) {
            setDoctorReviewData({
                ...doctorReviewData,
                signature: null,
                signature_url: null,
            });
        }
        sigPad.current.clear();
        setDoctorReviewData({
            ...doctorReviewData,
            signature: null
        });
    };

    const saveSignature = () => {
        setDoctorReviewData({
            ...doctorReviewData,
            signature: sigPad.current.toDataURL()
        });
    };

    return (
        <>
            <Box bg="white" p={4} rounded="md" shadow="sm">
                <VStack align="stretch" spacing={2}>
                    <Heading
                        id="doctor-review-summary"
                        colorScheme="teal" 
                        bg="teal.500"
                        color="white"
                        p={4}
                    >
                        Health, Medical and Physical Assessment Summary
                    </Heading>
                    {/* <Text mb={0} mt={2}>Other Comments</Text>
                    <Textarea 
                        isDisabled={isFormLocked}
                        placeholder="Enter comments here..." 
                        value={doctorReviewData.other_comments} 
                        onChange={(e)=> setDoctorReviewData({...doctorReviewData, other_comments: e.target.value})}
                    /> */}
                </VStack>
            </Box>
            <Box bg="white" p={4} rounded="md" shadow="sm">
                <VStack align="stretch" spacing={4}>
                    <Text textAlign="center">Based on the limitations of the particular assessments requested by the company, the health, medical and physical information provided and the results of the medical and / or physical assessments performed, the following recommendation has been made regarding the candidate's suitability to safely perform the role specified above NB: Drug and alcohol results are not assessed as part of this recommendation. Please see disclaimer below and refer to drug and alcohol result attached, if requested.</Text>

                    <Box rounded="md" borderWidth='1px' borderColor="gray.200" >
                        <Heading p={4} 
                            id="doctor-review-recommendation"
                            size="lg"
                            bg="teal.500"
                            borderBottomWidth="1px"
                            borderBottomColor="gray.200"
                            borderBottomStyle="solid"
                            textColor="white"
                        >
                            Recommendations following assessment
                        </Heading>
                        <VStack align="stretch" spacing={4}>
                            {doctorReviewChecklists.map((checklist, index) => (
                                <>
                                    <HStack spacing={4} alignItems="flex-start" p={4} 
                                        {...(!checklist.subChecklists && {
                                            borderBottomWidth: "1px",
                                            borderBottomColor: "gray.200",
                                            borderBottomStyle: "solid"
                                        })}
                                    >
                                        <Checkbox 
                                            size="lg"
                                            sx={{
                                                '[data-checked]': {
                                                    'width': '32px',
                                                    'height': '32px'
                                                },
                                                '.chakra-checkbox__control': {
                                                    'width': '32px',
                                                    'height': '32px'
                                                }
                                            }}
                                            isDisabled={isFormLocked || (hasCheckedItem() && !doctorReviewData[checklist.value])}
                                            colorScheme="teal"
                                            isChecked={doctorReviewData[checklist.value]}
                                            onChange={(e) => setDoctorReviewData({
                                                ...doctorReviewData, 
                                                [checklist.value]: e.target.checked ? true : false
                                            })}
                                        />
                                        <Text mb={0} dangerouslySetInnerHTML={{ __html: checklist.label }} />
                                    </HStack>
                                    {checklist.subChecklists && (
                                        <VStack px={12} pb={4} align="stretch" spacing={0} borderBottom="1px" borderColor="gray.200" borderBottomStyle="solid">
                                            {checklist.subChecklists.map((subChecklist, subIndex) => (
                                                <>
                                                    <HStack spacing={4} alignItems="flex-start">
                                                        <Checkbox
                                                            sx={{
                                                                '[data-checked]': {
                                                                    'width': '20px',
                                                                    'height': '20px'
                                                                },
                                                                '.chakra-checkbox__control': {
                                                                    'width': '20px',
                                                                    'height': '20px'
                                                                }
                                                            }}
                                                            isDisabled={isFormLocked || !doctorReviewData[checklist.value]}
                                                            colorScheme="teal"
                                                            isChecked={doctorReviewData[subChecklist.value]}
                                                            onChange={(e) => setDoctorReviewData({
                                                                ...doctorReviewData, 
                                                                [subChecklist.value]: e.target.checked ? true : false
                                                            })}
                                                        />
                                                        <Text mb={0}>{subChecklist.label}</Text>
                                                    </HStack>
                                                    <Input
                                                        value={doctorReviewData[subChecklist.descriptionValue] || ''}
                                                        placeholder="Please specify"
                                                        onChange={(e) => setDoctorReviewData({
                                                            ...doctorReviewData, 
                                                            [subChecklist.descriptionValue]: e.target.value
                                                        })}
                                                        isDisabled={isFormLocked || !doctorReviewData[checklist.value]}
                                                        ml={10}
                                                        mr={10}
                                                        mb={2}
                                                        py={2}
                                                        px={4}
                                                        width={{ base: "100%", md: "50%" }}
                                                    />
                                                </>
                                            ))}
                                        </VStack>
                                    )}
                                </>
                            ))}
                        </VStack>
                        <VStack align="stretch" spacing={0} borderBottom="1px" borderColor="gray.200" borderBottomStyle="solid">
                            <Text px={4} pt={4} pb={2} as='b'>Other Comments / Identified Risks / Restrictions:</Text>
                            <Textarea 
                                placeholder="Please specify" 
                                value={doctorReviewData.other_description} 
                                onChange={(e)=> setDoctorReviewData({...doctorReviewData, other_description: e.target.value})}
                                border="none"
                                _focus={{
                                    border: "none",
                                    boxShadow: "none"
                                }}
                                mt={0}
                                rows={5}
                                isDisabled={isFormLocked}
                            />
                        </VStack>
                        
                    </Box>

                    <VStack align="stretch" spacing={0}  >

                        <Text mb={1} mt={4}>Reviewing medical practitioner or occupational physician:</Text>
                        <Input
                            width={{ base: "300px" }}
                            value={doctorReviewData.reviewer_name}
                            placeholder="Enter reviewer name"
                            onChange={(e) => setDoctorReviewData({
                                ...doctorReviewData,
                                reviewer_name: e.target.value
                            })}
                            mb={4}
                            isDisabled={isFormLocked}
                        />

                        <Text mb={1} >Signature</Text>

                        <Box border="1px" borderColor="gray.200" p={4} mb={2} rounded="md" width={{ base: "300px" }}> 
                        {doctorReviewData.signature_url ? (
                            <Image 
                                src={doctorReviewData.signature_url}
                                alt="Doctor's signature"
                                width={280}
                                height={150}
                                objectFit="contain"
                            />
                        ) : (
                            <SignaturePad
                                ref={sigPad}
                                canvasProps={{
                                    width: 280,
                                    height: 150,
                                    className: 'signature-canvas',
                                    style: {
                                        pointerEvents: isFormLocked ? 'none' : 'auto',
                                        opacity: isFormLocked ? 0.5 : 1
                                    }
                                }}
                                onEnd={saveSignature}
                            />
                        )}
                        </Box>
                        <HStack mt={0} justify="space-between" align="center" width={{ base: "300px" }}>
                            <Text 
                                fontSize="sm" 
                                color="gray.600"
                                display="flex"
                                alignItems="center"
                                m={0}
                            >
                                Draw in the box to digitally sign
                            </Text>
                            <Button size="sm" onClick={clearSignature} colorScheme="teal" variant="ghost" gap={1} isDisabled={isFormLocked}>Clear <FaUndo color="#1F7055" /></Button>
                        </HStack>
                    </VStack>

                    <VStack align="stretch" spacing={0}>
                        <Text fontWeight="bold" textAlign="center" mb={0}>Disclaimer:</Text>
                        <Text textAlign="center">The Medical Assessor completing this pre-employment health, medical and physical assessment wishes to make it known that the sole purpose of the assessment, and the associated opinions, are in the interests of reducing occupational injury through placement of prospective employees in those positions best suited to their physical capabilities.</Text>
                        <Text textAlign="center" mb={0}>This assessment is not for the purpose of determining the success or otherwise of this person's application for employment.</Text>
                        <Text textAlign="center" mb={0}>All non-negative drug test results will undergo confirmatory testing via GCMS which will incur an additional charge. The result of the GCMS test is not assessed as part of this recommendation.</Text>
                    </VStack>

                </VStack>
            </Box>
        </>
    );
};

export default DoctorReviewForm;