import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Select,
  Flex,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import axios from 'axios';
import BookingType from '../../backend/models/BookingType';
import CustomForm from '../../backend/models/CustomForm';
import PaginationButtons from './PaginationButtons';

const ServiceTable = (props) => {
  const [formId, setFormId] = useState(props.formId || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all');
  const [pendingBookingTypeId, setPendingBookingTypeId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemsPerPage = 15;
  const toast = useToast();

  useEffect(() => {
    fetchBookingTypesData();
  }, [currentPage, searchQuery, filter]);

  const fetchBookingTypesData = async () => {
    setLoading(true);
    try {
      const params = {
        page: currentPage,
        per_page: itemsPerPage,
        'filter[where_like]': searchQuery || undefined,
        'filter[status]': filter !== 'all' ? filter : undefined,
        form_id: formId,
      };
  
      const response = await BookingType.params(params).get();
      setBookingTypes(response.data);
      setTotalPages(response.last_page || 1);
    } catch (error) {
      console.error('Error fetching services:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch services.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to page 1 when search changes
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to page 1 when filter changes
  };

  const handleToggle = async (bookingTypeId) => {
    try {
      const response = await axios.post(`/api/v1/admin/forms/${formId}/booking-type/${bookingTypeId}/toggle`);
      
      if (response.data.success) {
        updateBookingTypeState(bookingTypeId);
      } else if (!response.data.success) {
        setPendingBookingTypeId(bookingTypeId);
        onOpen();
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.error('Error toggling service:', error);
      toast({
        title: "Error",
        description: "There was an error updating the service.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleOverwrite = async () => {
    try {
      const response = await axios.post(
        `/api/v1/admin/forms/${formId}/booking-type/${pendingBookingTypeId}/toggle`,
        { is_overwrite: true }
      );
      
      if (response.data.success) {
        updateBookingTypeState(pendingBookingTypeId);
      }
      onClose();
    } catch (error) {
      console.error('Error overwriting service:', error);
      toast({
        title: "Error",
        description: "There was an error overwriting the service.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateBookingTypeState = (bookingTypeId) => {
    setBookingTypes((prevBookingTypes) =>
      prevBookingTypes.map((bookingType) =>
        bookingType.id === bookingTypeId 
          ? { ...bookingType, enabled: !bookingType.enabled } 
          : bookingType
      )
    );

    const bookingType = bookingTypes.find((bt) => bt.id === bookingTypeId);
    toast({
      title: "Form Updated",
      description: `Service ${bookingType.title} ${bookingType.enabled ? 'disabled' : 'enabled'}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <Box p={7} maxWidth={1024} mx="auto" bgColor="bgDefault.main" borderRadius="4px" boxShadow="boxShadow.main">
      <Box display="flex" flexDirection="column" gap={2} mb={7}>
        <h3>Services</h3>
        <text>Candidates with these services requested will automatically receive a link to complete this form when booked into an appointment.</text>
      </Box>
      <Box display="flex" mb={7} justifyContent="space-between">
        <InputGroup alignContent="center" width="25%" height="40px">
          <InputLeftElement pointerEvents="none" height="100%">
            <i class="fas fa-search search-icon" style={{ color: 'var(--chakra-colors-gray-500)' }}></i>
          </InputLeftElement>
          <Input
            height="100%"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Box display="flex" alignItems="center">
          <span style={{ marginRight: '10px', minWidth: '100px' }}>Filter by status:</span>
          <Select
            height="40px"
            width="120px"
            style={{ padding: '0 10px' }}
            value={filter}
            onChange={handleFilterChange}
          >
            <option value="all">All</option>
            <option value="enabled">Enabled</option>
            <option value="disabled">Disabled</option>
          </Select>
        </Box>
      </Box>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box gap={7}>
          <Table variant="simple">
            <Tbody>
              {bookingTypes.map((bookingType) => (
                <Tr key={bookingType.id}>
                  <Td width="85%" style={{paddingTop: '8px', paddingBottom: '8px', fontSize: 'medium'}}>{bookingType.title}</Td>
                  <Td width="15%">
                    <Box display="flex" justifyContent="flex-start">
                      <Checkbox
                        isChecked={bookingType.enabled}
                        onChange={() => handleToggle(bookingType.id)}
                        sx={{
                          width: '23px',
                          height: '23px',
                          borderRadius: '4px',
                          '& .chakra-checkbox__control': {
                            width: '23px',
                            height: '23px',
                            borderRadius: '4px',
                          },
                          '& .chakra-checkbox__control[data-checked], & .chakra-checkbox__control:hover': {
                            bg: 'teal.500',
                            border: 'none',
                            color: 'white',
                          },
                          '& .chakra-checkbox__label': {
                            fontSize: 'medium',
                          }
                        }}
                      >
                        Enable
                      </Checkbox>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Overwrite</ModalHeader>
          <ModalBody>
            This service is already connected to another Doctor form. Do you want to overwrite this connection?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleOverwrite}>
              Overwrite
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ServiceTable;