<template>
  <paginated-table
    v-if="companies.data"
    :tableData="tableData"
    :paginatedData="companies"
    @pagination-change-page="getCompanies"
  />
</template>

<script>
import Company from "../../models/Company";

export default {
  mounted() {
    this.getCompanies();
  },
  data() {
    return {
      companies: {},
      tableData: {
        columns: [
          { label: "Name", name: "name", sortable: true },
          { label: "Recruiters", name: "users_count", sortable: true },
          { label: "Open Candidates", name: "open_candidates_count", sortable: true },
          { label: "Close Candidates", name: "closed_candidates_count", sortable: true }
        ],
        showSearch: true,
        edit:true,
        showPerPage: true,
        viewUrl: "/admin/companies"
      }
    };
  },
  methods: {
    getCompanies(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
      let companies = Company.page(page).params({per_page:per_page}).append('users_count', 'open_candidates_count', 'closed_candidates_count');
      
      if(desc && sortColumn) {
        companies.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        companies.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        companies.orderBy('name');
      }

      if (query) {
        companies.where("where_like", query);
      }
      companies.get().then(response => {
        this.companies = response;
      });
    }
  }
};
</script>