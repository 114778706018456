<template>
  <div id="mk-booking_type-form">
    <div class="row mb-3">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <h1>{{booking_type.title || 'Create Service Type'}}</h1>

        <div class="d-flex align-items-center">
          <button v-if="booking_type.id" class="jem-btn-danger mr-2" @click="onDelete">Delete</button>
          <button @click="onSubmit" :disabled="form.errors.any()" class="jem-btn-primary">Update</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label for="name">Name</label>
        <div class="input-group">
          <input
            type="text"
            name="name"
            :class="[{ 'is-invalid': form.errors.has('title') }, 'form-control']"
            id="name-input"
            @keydown="form.errors.clear('title');"
            v-model="booking_type.title"
            placeholder="Name"
          >
          <div class="invalid-feedback">Please choose a name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="email">Type</label>
        <div class="input-group">
          <el-select class="w-100" v-model="booking_type.booking_category_id" @change="form.errors.clear('booking_category_id')" placeholder="Booking Category">
            <el-option
              v-for="item in booking_categories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="invalid-feedback">Description invalid.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="email">Xero Cost</label>
        <div class="input-group">
          <el-select class="w-100" filterable v-model="booking_type.xero_item_id" @change="form.errors.clear('xero_item_id')" placeholder="Booking Category">
            <el-option
              v-for="item in xero_items"
              :key="item.id"
              :label="item.code ? item.code + ' (' + item.name +')': item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="invalid-feedback">Description invalid.</div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div v-if="booking_type.id" class="row">
      <div class="col-md-12">
        <tabs class="mt-3">
          <tab name="Companies">
            <booking-type-companies :companies="companies" :booking_type="booking_type"></booking-type-companies>
          </tab>
          <tab name="Booking Types">
            <booking-type-services :services="services" :booking_type="booking_type"></booking-type-services>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import BookingType from "../../models/BookingType";
import Form from "form-backend-validation";

export default {
  props: {
    booking_categories: {
      type: Array
    },
    companies: {
      type: Array
    },
    services: {
        type: Array
    },
    xero_items:{
      type: Array
    },
    initial_booking_type: {
      type: Object
    }
  },
  data() {
    return {
      booking_type: new BookingType(this.initial_booking_type || {}),
      form: new Form()
    };
  },
  methods: {
    onSubmit() {
      this.booking_type
        .save()
        .then(res => {
          this.$message({
            type: "success",
            message:
              "Saved!"
          });
          this.booking_type = new BookingType(res);
          var url = window.location.toString();
          url = url.replace("create", `${res.id}/edit`);
          window.history.pushState(null, null, url);
        })
        .catch(errors => {
          this.form.errors.record(errors.response.data.errors);
        });
    },
    onDelete()
    {
      this.$confirm('You are about to delete the service type. This action is irreversible. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.booking_type.delete().then((res) => {
            window.location = '/admin/booking_types'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Service Type not deleted.'
          });          
        });;
    }
  }
};
</script>

<style>
</style>
