const items = [
  {
    group_name: 'Form fields',
    key: 'TextInput',
    icon: 'fa fa-pen',
    name: 'Short Answer',
  },
  {
    group_name: 'Form fields',
    key: 'TextArea',
    icon: 'fa fa-align-left',
    name: 'Long Answer',
  },
  {
    group_name: 'Form fields',
    key: 'Dropdown',
    icon: 'fa fa-chevron-circle-down',
    name: 'Dropdown List',
  },
  {
    group_name: 'Form fields',
    key: 'Checkboxes',
    name: 'Checkbox',
  },
  {
    group_name: 'Form fields',
    key: 'Tags',
    icon: 'fa fa-list-ul',
    name: 'Choose One',
  },
  {
    group_name: 'Form fields',
    key: 'RadioButtons',
    icon: 'fas fa-tasks',
    name: 'Choice Multiple',
  },
  {
    group_name: 'Form fields',
    key: 'EmailInput',
    icon: 'far fa-envelope',
    name: 'Email Address',
  },
  {
    group_name: 'Form fields',
    key: 'DatePicker',
    name: 'Date Select',
  },
  {
    group_name: 'Form fields',
    key: 'FileUpload',
    icon: 'fa fa-upload',
    name: 'File Upload',
  },
  {
    group_name: 'Form fields',
    key: 'Signature',
    icon: 'fa fa-signature',
    name: 'Signature',
  },
  {
    group_name: 'Layout',
    key: 'TwoColumnRow',
  },
  {
    group_name: 'Layout',
    key: 'ThreeColumnRow',
  },
  {
    group_name: 'Layout',
    key: 'FourColumnRow',
    element: 'MultiColumnRow',
  },
  {
    group_name: 'Layout',
    key: 'FiveColumnRow',
    element: 'MultiColumnRow',
  },
  {
    group_name: 'Layout',
    key: 'SixColumnRow',
    element: 'MultiColumnRow',
  },
  {
    group_name: 'Design elements',
    key: 'Header',
    name: 'Section heading',
  },
  {
    group_name: 'Design elements',
    key: 'Label',
    icon: 'fa fa-text-height',
    name: 'Formatted text',
  },
  {
    group_name: 'Design elements',
    key: 'LineBreak',
    name: 'Divider',
  },
  {
    group_name: 'Design elements',
    key: 'Image',
    name: 'Image',
  },
  {
    group_name: 'Styling',
    key: 'CustomToolbar',
    name: 'Custom Toolbar',
  }
];

export default items;