<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center" id="mk-candidate-services-modal">
          <div class="col-md-6">
            <div class="card shadow">
              <div class="row px-3 pt-3">
                <div class="col-12">
                  <h1 class="my-4">Candidate Requested Services</h1>
                  <hr />
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <p>Here you can see all the services in the Jem Health system. You are able to select and deselect any service here</p>
                  </div>
                  <div class="col-md-12">
                    <div
                      class="input-group justify-content-between align-items-center"
                      style="max-width: 260px;"
                    >
                      <input
                        class
                        style="max-width: 250px;"
                        v-model="query"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        @keyup.enter="getResults"
                      />

                      <div class="input-group-append">
                        <button @click="getResults" class="blue-icon-btn" type="button">
                          <i class="fas fa-search fa-lg"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 modal-scroll">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Requested</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(service, index) in all_booking_types" :key="index">
                            <td>
                              <span>{{service.title}}</span>
                            </td>

                            <td class="d-flex">
                              <label class="jem-checkbox-wrap">
                                <input
                                  type="checkbox"
                                  @change="onManage(service)"
                                  v-model="requested_services"
                                  :value="service.id"
                                />
                                <span class="jem-checkmark"></span>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 d-flex">
                    <span class="jem-btn-secondary" @click="$emit('close')">Close</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Candidate from "../../../../models/Candidate";
import BookingType from "../../../../models/BookingType";

export default {
  props: {
    initial_booking_types: {
      type: Array
    },
    initial_all_booking_types: {
      type: Array
    },
    candidate: {
      type: Object
    }
  },
  data() {
    return {
      all_booking_types: this.initial_all_booking_types,
      booking_types: this.initial_booking_types || [],
      query:'',
      requested_services: this.initial_booking_types.map(x => x.id)
    };
  },
  methods: {
    onManage(passed_service) {
      let booked_service = this.initial_booking_types.filter(
        x => x.id == passed_service.id
      );

      if (this.candidate.id) {
        let bookingType = new BookingType({ id: passed_service.id }).for(
          new Candidate({ id: this.candidate.id })
        );

        if (booked_service[0]) {
          bookingType.delete().then(res => {
            this.$message({
              type: "success",
              message: "Removed"
            });
          });
          this.booking_types = this.booking_types.filter(
            x => x.id != passed_service.id
          );
        } else {
          bookingType.save().then(res => {
            this.$message({
              type: "success",
              message: "Added"
            });
          });
          this.booking_types.push(new BookingType(passed_service));
        }
      } else {
        if (booked_service[0]) {
          this.booking_types = this.booking_types.filter(
            x => x.id != passed_service.id
          );
        } else {
          this.booking_types.push(new BookingType(passed_service));
        }
      }
      this.$emit("changed", this.booking_types);
    },
    getResults() 
    {
      BookingType.where('where_like', this.query).orderBy('title').params({per_page:100}).get().then((res) => {
        this.all_booking_types = res.data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1950;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>