export const applyMultiStepForm = (isSubmitBtnDisabled = false) => {
  // Find the form rendered by ReactFormGenerator
  const form = document.querySelector('.react-form-builder-form form');
  if (!form) return;

  // Get all direct child elements of the form
  const formChildren = Array.from(form.children);

  // Split the form content into pages using "alwaysbreak" as a marker
  const pages = [];
  let currentPage = document.createElement('div');
  currentPage.className = 'form-page';

  formChildren.forEach(child => {
    // Skip any existing navigation container (in case of re-render)
    if (child.classList.contains('form-navigation')) return;

    if (child.classList.contains('alwaysbreak')) {
      // If currentPage has content, push it as a page
      if (currentPage.childElementCount > 0) {
        pages.push(currentPage);
      }
      // Start a new page and include the alwaysbreak element (for header styling)
      currentPage = document.createElement('div');
      currentPage.className = 'form-page';
      currentPage.appendChild(child);
    } else {
      currentPage.appendChild(child);
    }
  });
  if (currentPage.childElementCount > 0) {
    pages.push(currentPage);
  }

  // Clear the form so we can reassemble it with pages
  form.innerHTML = '';

  // Append each page to the form (only the first page is visible initially)
  pages.forEach((page, index) => {
    page.style.display = index === 0 ? 'block' : 'none';
    form.appendChild(page);
  });

  // Create a navigation container with two sides: left (Back button) and right (Next/Submit)
  const navContainer = document.createElement('div');
  navContainer.className = 'form-navigation';
  navContainer.style.display = 'flex';
  navContainer.style.justifyContent = 'space-between';
  navContainer.style.alignItems = 'center';
  navContainer.style.marginTop = '1rem';

  // Left container for the Back button
  const leftContainer = document.createElement('div');
  leftContainer.className = 'nav-left';

  // Right container for the Next or Submit button
  const rightContainer = document.createElement('div');
  rightContainer.className = 'nav-right';

  // Create the Back button
  const backButton = document.createElement('button');
  backButton.type = 'button';
  backButton.textContent = 'Back';
  backButton.className = 'btn jem-btn-secondary-black';
  leftContainer.appendChild(backButton);

  // Create the Next button (used on non-last pages)
  const nextButton = document.createElement('button');
  nextButton.type = 'button';
  nextButton.textContent = 'Next';
  nextButton.className = 'btn form-btn-primary';
  rightContainer.appendChild(nextButton);

  // If no submit button is found, create one.
  const navSubmitButton = document.createElement('button');
  navSubmitButton.id = 'nav-submit-button';
  navSubmitButton.type = 'button';
  navSubmitButton.textContent = 'Submit';
  navSubmitButton.className = 'btn form-btn-primary primary-btn';
  navSubmitButton.visibility = 'hidden';
  navSubmitButton.disabled = isSubmitBtnDisabled;
  rightContainer.appendChild(navSubmitButton);

  // Add a hidden input to track when 'nav-submit-button' is clicked
  const navSubmitTracker = document.createElement('input');
  navSubmitTracker.type = 'hidden';
  navSubmitTracker.id = 'nav-submit-tracker';
  navSubmitTracker.name = 'nav_submit_tracker';
  navSubmitTracker.value = 'false';
  form.appendChild(navSubmitTracker);

  // After the nav submit tracker creation, add the page tracker
  const pageTracker = document.createElement('input');
  pageTracker.type = 'hidden';
  pageTracker.id = 'page-tracker';
  pageTracker.name = 'page_tracker';
  pageTracker.value = `1/${pages.length}`; // Format: "currentPage/totalPages"
  form.appendChild(pageTracker);

  // Add validation skip tracker after other trackers
  const validationSkipTracker = document.createElement('input');
  validationSkipTracker.type = 'hidden';
  validationSkipTracker.id = 'validation-skip-tracker';
  validationSkipTracker.value = 'false';
  form.appendChild(validationSkipTracker);

  // Assemble the navigation container
  navContainer.appendChild(leftContainer);
  navContainer.appendChild(rightContainer);
  form.appendChild(navContainer);

  // Track the current page index
  let currentPageIndex = 0;

  // Helper function to show error message and mark field with red border
  const showError = (group, message = 'This field is required.') => {
    let errorEl = group.querySelector('.error-message');
    if (!errorEl) {
      errorEl = document.createElement('div');
      errorEl.className = 'error-message';
      errorEl.style.color = 'red';
      errorEl.style.fontSize = '0.9em';
      errorEl.textContent = message;
      group.appendChild(errorEl);
    }
    // Set red border on the input/textarea/select element
    const field = group.querySelector('input, textarea, select');
    if (field) {
      if (field.type === 'radio') {
        // For radio groups, update all radios with the same name within the group
        const radios = group.querySelectorAll(`input[type="radio"][name="${field.name}"]`);
        radios.forEach(radio => {
          radio.style.outline = '1px solid red';
        });
      } else {
        field.style.border = '1px solid red';
      }
    }
  };

  // Helper function to remove error message and red border styling
  const removeError = (group) => {
    const errorEl = group.querySelector('.error-message');
    if (errorEl) {
      errorEl.parentElement.removeChild(errorEl);
    }
    const field = group.querySelector('input, textarea, select');
    if (field) {
      if (field.type === 'radio') {
        const radios = group.querySelectorAll(`input[type="radio"][name="${field.name}"]`);
        radios.forEach(radio => {
          radio.style.outline = '';
        });
      } else {
        field.style.border = '';
      }
    }
  };

  // Function to update navigation buttons based on the current page
  const updateNavButtons = () => {
    // Hide the Back button on the first page
    backButton.style.display = currentPageIndex === 0 ? 'none' : 'inline-block';

    if (currentPageIndex === pages.length - 1) {
      nextButton.style.display = 'none';
      navSubmitButton.disabled = isSubmitBtnDisabled == true ? true : false;
      navSubmitButton.style.display = 'inline-block';
    } else {
      nextButton.style.display = 'inline-block';
      navSubmitButton.style.display = 'none';
    }

    // Update page tracker with current/total format
    const pageTracker = document.getElementById('page-tracker');
    if (pageTracker) {
      pageTracker.value = `${currentPageIndex + 1}/${pages.length}`;
    }
  };

  updateNavButtons();

  // Navigation event listeners

  // Back button does not need validation
  backButton.addEventListener('click', () => {
    if (currentPageIndex > 0) {
      // Set validation skip to true when going back
      const validationSkipTracker = document.getElementById('validation-skip-tracker');
      if (validationSkipTracker) {
        validationSkipTracker.value = 'true';
      }
      navSubmitTracker.value = 'false';

      pages[currentPageIndex].style.display = 'none';
      currentPageIndex--;
      pages[currentPageIndex].style.display = 'block';
      updateNavButtons();
    }
  });

  // Allow retriggering by ensuring the function can be called multiple times
  nextButton.addEventListener('click', () => {
    let valid = true;
    const groups = pages[currentPageIndex].querySelectorAll('.form-group');
    groups.forEach(group => {
      if (group.querySelector('.label-required')) {
        const field = group.querySelector('input, textarea, select');
        if (field && !field.value.trim()) {
          valid = false;
          showError(group);
        } else {
          removeError(group);
        }
      }
    });

    if (valid) {
      pages[currentPageIndex].style.display = 'none';
      currentPageIndex++;
      pages[currentPageIndex].style.display = 'block';
      updateNavButtons();
    }

    // Trigger the submit button with id 'submit-button' on Next button click
    const submitButton = document.getElementById('submit-button');
    if (submitButton) {
      if (!isSubmitBtnDisabled) {
        console.log('Submit button clicked');
        submitButton.removeAttribute('disabled');
        submitButton.click();
      }
    }
  });

  // Update the hidden input value when 'nav-submit-button' is clicked
  if (navSubmitButton) {
    navSubmitButton.addEventListener('click', () => {
      navSubmitTracker.value = 'true';
      const submitButton = document.getElementById('submit-button');
      submitButton.click();
    });
  }
};
