import React from 'react';
import { createRoot } from 'react-dom/client';
import Demobar from './react/components/Demobar';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import 'sprint-digital-react-form-builder/dist/app.css';
import FormBuilder from './react/components/FormBuilder';
import * as variables from './react/components/variables';
import FormRender from './react/components/FormRender';
import FormSetting from './react/components/FormSetting';
import DoctorReviewIndex from './react/components/doctor-review/DoctorReviewIndex';
import CandidateForms from './react/components/candidates/CandidateForms';

const theme = extendTheme({
  colors: {
    primary: {
        main: "#1F7055",
        hover: "#184831",
        text: "#FFFFFF"
    },
    secondary: {
        main: "#718096",
        hover: "#0D3226",
        text: "#FFFFFF"
    },
    bgDefault: {
        main: "#FFFFFF"
    },
    boxShadow: {
        main: "0px 2px 3px 0px rgba(0, 0, 0, 0.10)"
    },
    border: "#CBD5E0",
    buttonOutline: {
      secondary: {
        color: "textNeutral",
        bg: "bgDefault.main",
        _hover: {
          bg: "#EDF2F7",
        },
      },
    },
    teal: {
      50: '#e6f6f2',
      100: '#b3e6dc',
      200: '#80d6c6',
      300: '#4dc6b0',
      400: '#1ab69a',
      500: '#1F7055', // primary
      600: '#184831', // hover
      700: '#0D3226',
      800: '#072c1f', 
      900: '#021611'
    },
    textNeutral: "#171923",
  },
  components: {
    Button: {
      variants: {
        outlineSecondary: {
          color: "buttonOutline.secondary.color",
          bg: "buttonOutline.secondary.bg",
          _hover: {
            color: "buttonOutline.secondary.color",
            bg: "buttonOutline.secondary._hover.bg",
          },
          border: "1px solid #CBD5E0",
          borderRadius: "8px",
        },
      },
    },
  },
});

if (document.getElementById('form-builder')) {
    const formBuilderElement = document.getElementById('form-builder');
    const formId = formBuilderElement.getAttribute('data-form-id');
    const root = createRoot(formBuilderElement);
    root.render(
        <ChakraProvider theme={theme}>
            <FormBuilder formId={formId} />
        </ChakraProvider>
    );
}

if (document.getElementById('demo-bar')) {
    const root = createRoot(document.getElementById('demo-bar'));
    root.render(<Demobar variables={variables} />);
}

// /admin/doctor_reviews
if (document.getElementById('doctor-review-index')) {
    const doctorReviewElement = document.getElementById('doctor-review-index');
    const root = createRoot(document.getElementById('doctor-review-index'));
    const doctorReviews = doctorReviewElement.getAttribute('data-doctor-reviews')
    ? JSON.parse(doctorReviewElement.getAttribute('data-doctor-reviews'))
    : [];
    const doctorReviewStatusId = doctorReviewElement.getAttribute('data-doctor-review-status-id') ?? null;

    root.render(
        <ChakraProvider theme={theme}>
            <DoctorReviewIndex initialDoctorReviews={doctorReviews} initialDoctorReviewStatusId={doctorReviewStatusId} />
        </ChakraProvider>
    );
}

// This page is for candidates to fill out the form.
if (document.getElementById('form-render')) {
    const root = createRoot(document.getElementById('form-render'));
    root.render(<FormRender />);
}

// This page is for the admin to manage the form settings.
if (document.getElementById('form-setting')) {
    const formSettingElement = document.getElementById('form-setting');
    const formId = formSettingElement.getAttribute('data-form-id');
    const root = createRoot(document.getElementById('form-setting'));
    root.render(
        <ChakraProvider theme={theme}>
            <FormSetting formId={formId} />
        </ChakraProvider>
    );
}

if (document.getElementById('candidate-forms')) {
    const candidateFormElement = document.getElementById('candidate-forms');
    const candidateId = candidateFormElement.getAttribute('data-candidate-id');
    const customFormResults = candidateFormElement.getAttribute('data-custom-form-results');
    const candidate = candidateFormElement.getAttribute('data-candidate');
    const doctorReview = candidateFormElement.getAttribute('data-doctor-review') 
    ? JSON.parse(candidateFormElement.getAttribute('data-doctor-review')) 
    : undefined;
    const isSubmittable = candidateFormElement.getAttribute('data-is-submittable');
    const doctorReviews = candidateFormElement.getAttribute('data-doctor-reviews') 
    ? JSON.parse(candidateFormElement.getAttribute('data-doctor-reviews')) 
    : [];
    const doctorReviewReports = candidateFormElement.getAttribute('data-doctor-review-reports') 
    ? JSON.parse(candidateFormElement.getAttribute('data-doctor-review-reports')) 
    : [];
    const bookingTypeId = candidateFormElement.getAttribute('data-booking-type-id') ?? null;
    const user = candidateFormElement.getAttribute('data-user')
    ? JSON.parse(candidateFormElement.getAttribute('data-user'))
    : undefined;

    const root = createRoot(document.getElementById('candidate-forms'));
    root.render(
        <ChakraProvider theme={theme}>
            {doctorReview && <CandidateForms candidateId={candidateId} candidate={JSON.parse(candidate)} customFormResults={JSON.parse(customFormResults)} doctorReview={doctorReview} doctorReviews={doctorReviews} bookingTypeId={bookingTypeId} doctorReviewReports={doctorReviewReports} user={user}/>}
            {!doctorReview && <CandidateForms candidateId={candidateId} candidate={JSON.parse(candidate)} customFormResults={JSON.parse(customFormResults)} doctorReviews={doctorReviews} bookingTypeId={bookingTypeId} doctorReviewReports={doctorReviewReports} user={user}/>}
        </ChakraProvider>
    );
}