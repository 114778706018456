import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, VStack, Textarea, ModalFooter, Button, useToast } from '@chakra-ui/react';
import User from '../../../../backend/models/User';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from '@choc-ui/chakra-autocomplete';
import DoctorReview from '../../../../backend/models/DoctorReview';
import { set } from 'lodash';

const CandidateSubmitDoctorReviewModal = ({ isOpen, onClose, candidateId, bookingTypeId, doctorReview }) => {
    const [doctorId, setDoctorId] = useState(undefined);
    const [defaultDoctor, setDefaultDoctor] = useState('');
    const [localDoctorReview, setLocalDoctorReview] = useState(doctorReview);
    const [doctors, setDoctors] = useState([]);
    const [comment, setComment] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();


    useEffect(() => {
        if (!isOpen) return;
        getDoctors();
    }, [isOpen]);


    function getDoctors(page = 1, sortColumn = null, query = null, per_page = 1000, desc = false) {
        let users = User.page(page).params({per_page:per_page});

        if (query) {
            users.where("where_like", query);
        }
        users.where("permission", "Manage Doctor");
        users.get().then(response => {
            setDoctors(response.data);

            const selectedDoctor = response.data.find(doc => doc.id === doctorReview.doctor_id);
            if (selectedDoctor) {
                setDoctorId(selectedDoctor.id);
                setDefaultDoctor(selectedDoctor.full_name);
            }
        });
    }

    const handleOnSubmit = () => { 
        // Add api logic here
        if (!doctorId) {
            toast({
                title: 'Error',
                description: 'Please select a doctor',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return ;
        }
        setIsSubmitting(true);

        const doctorReview = new DoctorReview({...localDoctorReview,
            doctor_id: doctorId,
            comments: comment,
            doctor_review_status_id: 1
        })
        
        doctorReview.save().then((response) => {
            setIsSubmitting(false);
            toast({
                title: 'Success',
                description: 'Candidate submitted for doctor review. Reloading page 3 seconds...',
                status: 'success',
                duration: 3000,
                isClosable: true,
                onCloseComplete: () => window.location.reload(),
            })
            onClose();
        }).catch((error) => {
            setIsSubmitting(false);
            toast({
                title: 'Error',
                description: 'An error occurred. Please try again',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottomWidth="1px" borderColor="gray.200">Submit for doctor review</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <VStack align="stretch" >
                    <Text>Assign to:</Text>
                    { doctors.length > 0 && (
                        <AutoComplete 
                            onChange={(value) => {
                                setDoctorId(value); 
                                const selectedDoctor = doctors.find(doc => doc.id == value);
                                if (selectedDoctor) {
                                    setDefaultDoctor(selectedDoctor.full_name);
                                }
                            }}
                            value={defaultDoctor}
                        >
                            <AutoCompleteInput 
                                variant="filled" 
                                placeholder="Type here to search for a doctor..."
                            />
                            <AutoCompleteList>
                                {doctors.map((doctor, cid) => (
                                <AutoCompleteItem
                                    key={`option-${cid}`}
                                    value={doctor}
                                    label={doctor.full_name}
                                >
                                {doctor.full_name}
                                </AutoCompleteItem>
                            ))}
                            </AutoCompleteList>
                        </AutoComplete>
                    )}
                    <Text fontSize="sm" color="gray.500" my={1}>The selected doctor will receive a notification that this candidate is ready for review</Text>

                    <Text mb={1}>Comment</Text>
                    <Textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}    
                    />
                </VStack>
                </ModalBody>
                <ModalFooter borderColor="gray.200" justifyContent="space-between" display="flex">
                    <Button colorScheme='teal' onClick={onClose} variant='outline'>Cancel</Button>
                    <Button colorScheme='teal' onClick={handleOnSubmit} isLoading={isSubmitting} isDisabled={doctorId === undefined || comment === ''}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CandidateSubmitDoctorReviewModal;