import { useToast } from '@chakra-ui/react';
import DoctorReviewReport from '../../backend/models/DoctorReviewReport';

const useUploadReport = () => {
    const uploadReport = async (description, doctorReview, selectedFiles, setReports, reports, onClose) => {
        try {
            const report = new DoctorReviewReport(
                {
                    description: description,
                    doctor_review_id: doctorReview.id,
                    booking_type_id: doctorReview.booking_type_id,
                    selectedFiles: selectedFiles,
                }
            );
            const response = await report.save();
            
            if (response) {
                setReports([...reports, response]);
            }

            onClose();
        } catch (error) {
        }
    };

    return uploadReport;
};

export default useUploadReport;