<template>
  <div class="jem-paginated-table">
    <div class="jem-table-controls w-100">
      <div v-if="tableData.showSearch" class="w-100">
        <div class="p-3 w-100 d-flex align-items-center">
          <div
            class="input-group justify-content-between align-items-center"
            style="max-width: 260px;"
          >
            <input
              class
              style="max-width: 250px;"
              v-model="query"
              type="text"
              placeholder="Search"
              aria-label="Search"
              @keyup.enter="getResults(1)"
            />

            <div class="input-group-append">
              <button @click="getResults(1)" class="blue-icon-btn" type="button">
                <i class="fas fa-search fa-lg"></i>
              </button>
            </div>
          </div>

          <div
            class="d-flex justify-content-between align-items-center ml-4"
            style="max-width: 160px;"
          >
            <select @change="getResults(1)" v-model="per_page">
              <option>15</option>
              <option>25</option>
              <option>100</option>
            </select>
            <p class="mb-0 ml-3 text-dark-grey">Per Page</p>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th
              scope="col"
              @click="onSort(column)"
              v-for="(column, index) in tableData.columns"
              :key="index"
              style="cursor:pointer !important;"
            >{{column.label}}</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody v-if="paginatedData.data.length > 0">
          <tr v-for="(data, index) in paginatedData.data" :key="index">
            <td v-for="(column, index) in tableData.columns" :key="index">
              <span
                v-if="column.date"
              >{{moment.utc(data[column.name], 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/Y HH:mm:ss')}}</span>
              <span v-else>{{getProperty(column.name, data)}}</span>
            </td>

            <td class="d-flex">
              <button v-if="tableData.overrideView"
                class="blue-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                @click="$emit('on-view', data)"
                title="View">
                  <i class="fas fa-eye fa-lg"></i>
              </button>
              <a
                v-else-if="tableData.viewUrl && tableData.edit"
                class="blue-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                :href="tableData.viewUrl+'/'+data.id+'/edit'"
              >
                <i class="fas fa-eye fa-lg"></i>
              </a>
              <a
                v-else-if="tableData.viewUrl"
                class="blue-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                :href="tableData.viewUrl+'/'+data.id"
              >
                <i class="fas fa-eye fa-lg"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-if="paginatedData.data.length == 0">
      <div class="col-md-12 d-flex justify-content-center">
        <h1>Whoops! Looks like there's no data here.</h1>
      </div>
    </div>

    <pagination :limit="4" :show-disabled="true" :data="paginatedData" @pagination-change-page="getResults">
      <span slot="prev-nav">
        <i class="fas fa-chevron-left"></i>
      </span>
      <span slot="next-nav">
        <i class="fas fa-chevron-right"></i>
      </span>
    </pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Object
    },
    paginatedData: {
      type: Object
    },
    initial_query: {
      type: String
    }
  },
  data() {
    return {
      sortedColumn: null,
      query: this.initial_query,
      per_page: 15,
      desc: true
    };
  },
  methods: {
    getResults(page = 1) {
      this.$emit(
        "pagination-change-page",
        page,
        this.sortedColumn,
        this.query,
        this.per_page,
        this.desc,
      );
    },
    onSort(column) {
      if (this.sortedColumn && this.sortedColumn.name == column.name) {
        this.desc = !this.desc;
      } else {
        this.desc = false;
      }
      this.sortedColumn = column;
      this.getResults(1);
    },
    getProperty(propertyName, object) {
      var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;
      for (i = 0; i < length; i++) {
        property = property[parts[i]] || "N/A";
      }
      return property;
    }
  }
};
</script>

<style>
</style>
