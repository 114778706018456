<template>
  <div>
    <candidate-invoices-modal
      v-if="showInvoiceModal"
      @close="selected_invoice = {}; showInvoiceModal = false"
      :initial_invoice="selected_invoice"
      :companies="companies"
      :invoice_payment_types="invoice_payment_types"
      :candidate="selected_invoice.candidate"
      :xero_accounts="xero_accounts"
    ></candidate-invoices-modal>
    <div class="row d-flex justify-content-end">
      <div class="col-md-5">
        <el-date-picker
          class="w-100"
          v-model="date"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          format="dd/MM/yyyy"
          start-placeholder="Start date"
          end-placeholder="End date"
        ></el-date-picker>
      </div>
      <div class="col-md-1">
        <a
          class=" mt-1 btn btn-md btn-secondary"
          :href="'/admin/invoice/export?start_date='+date[0]+'&end_date='+date[1]"
        >Export</a>
      </div>
    </div>

    <paginated-table
      v-if="invoices.data"
      :tableData="tableData"
      :paginatedData="invoices"
      @pagination-change-page="getInvoices"
      @on-view="onView"
    />
  </div>
</template>

<script>
import Invoice from "../../models/Invoice";

export default {
  props: {
    companies: {
      type: Array
    },
    xero_accounts: {
      type: Array
    },
    invoice_payment_types:{
      type: Array
    }
  },
  mounted() {
    this.getInvoices();
  },
  data() {
    return {
      invoices: {},
      date: [],
      selected_invoice: { candidate: {} },
      showInvoiceModal: false,
      tableData: {
        columns: [
          { label: "Xero ID", name: "xero_id", sortable: true },
          { label: "Ref", name: "name", sortable: true },
          { label: "Company", name: "company.name", sortable: true },
          { label: "Recruiter", name: "recruiter.full_name", sortable: true },
          { label: "Candidate", name: "candidate.name", sortable: true },
          { label: "Status", name: "status", sortable: true },
          {
            label: "Amount (excl. GST)",
            name: "dollar_total.formatted",
            sortable: true
          },
          {
            label: "Created At",
            name: "created_at",
            date: true,
            sortable: true
          }
        ],
        edit: true,
        showSearch: true,
        overrideView: true,
        showPerPage: true,
        viewUrl: "/admin/invoices"
      }
    };
  },
  methods: {
    getInvoices(
      page = 1,
      sortColumn = null,
      query = null,
      per_page = 15,
      desc = false
    ) {
      let invoices = Invoice.page(page).include(
        "candidate,xero-payments,line-items,recruiter,company,invoice-payment-type"
      );

      if (desc && sortColumn) {
        invoices.orderBy("-" + (sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        invoices.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        invoices.orderBy("-created_at");
      }

      if (query) {
        invoices.where("where_like", query);
      }

      invoices.get().then(res => {
        this.invoices = res;
      });
    },
    onView(invoice) {
      this.selected_invoice = invoice;
      this.showInvoiceModal = true;
    }
  }
};
</script>

<style>
</style>
