<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(service, index) in services" :key="index">
          <td>
            <span>{{service.name}}</span>
          </td>
          <td class="d-flex">
            <div class="d-flex align-items-center">
              <p class="mb-0 mr-3">Enable</p>
                <label class="jem-checkbox-wrap">
                  <input
                    type="checkbox"
                    @change="onTick"
                    v-model="enabled_services"
                    :value="service.id"
                  >
                  <span class="jem-checkmark"></span>
                </label>
              </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props:{
        services:{
            type:Array
        },
        booking_type:{
            type: Object
        },
    },
    data(){
        return {
            enabled_services:this.booking_type.services ? this.booking_type.services.map(item => item.id) : []
        }
    },
    methods: {
        onTick() {
            //
            axios.post(`/api/v1/admin/booking-types/${this.booking_type.id}/services`, {services: this.enabled_services}).then((res) => {
                this.$message({
                    type:'success',
                    message:'Services Synced'
                })
            });
        },
    }
};
</script>

<style>
</style>
