<template>
  <div>
    <div class="col-md-12 pt-3 d-flex align-items-end">
      <button
        class="ml mb-3 ml-auto mr-3 jem-btn-primary"
        @click="showServiceModal = true;"
      >Add Service</button>
      <button
        v-if="candidate.id"
        class="jem-btn-primary mb-3 mr-3"
        @click="showBookModal = true;"
      >Book Appointment</button>

      <candidate-booked-services-modal
        v-if="showBookModal"
        @close="showBookModal = false"
        :candidate="candidate"
        :calendars="calendars"
        :services="services"
      ></candidate-booked-services-modal>

      <candidate-services-modal
        v-if="showServiceModal"
        @changed="onChange"
        @close="showServiceModal = false"
        :candidate="candidate"
        :initial_booking_types="candidate.booking_types"
        :initial_all_booking_types="booking_types"
      ></candidate-services-modal>
    </div>

    <div class="table-responsive" style="max-height: 400px; overflow-y: scroll;">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(service, index) in candidate_services" :key="index">
            <td>
              <span>{{service.title}}</span>
            </td>
            <td class="d-flex">
              <span class="red-icon-btn" @click="handleRemove(service)">
                <i class="far fa-trash-alt"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    services: {
      type: Array
    },
    booking_types: {
      type: Array
    },
    candidate: {
      type: Object
    },
    calendars: {
      type: Array
    }
  },
  data() {
    return {
      candidate_services: this.candidate.booking_types || [],
      showServiceModal: false,
      showBookModal: false
    };
  },
  methods: {
    onChange(services) {
      this.candidate_services = services;
      this.$emit("changed", this.candidate_services);
    },
    handleRemove(service) {
      this.$confirm(
        "Are you sure you want to remove this requested service?",
        "Warning",
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let index = this.candidate_services.indexOf(service);
          if (this.candidate.id) {
            axios
              .delete(
                `/api/v1/admin/candidates/${this.candidate.id}/booking-types/${service.id}`
              )
              .then(res => {
                this.spliceService(index);
              });
          } else {
            this.spliceService(index);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Was not removed"
          });
        });
    },
    spliceService(index) {
      this.candidate_services.splice(index, 1);
      this.onChange(this.candidate_services);
    }
  }
};
</script>