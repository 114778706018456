<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8">
            <div class="card shadow">

              <div class="row px-3 pt-3">
                <div class="col-12">
                  <h1 class="my-4">Send SMS To Candidate</h1>
                  <hr>
                </div>
              </div>


              <div class="card-body pt-0">


                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message">Message (max. 140)</label>
                      <div class="input-group">
                        <input
                          maxlength="140"
                          type="text"
                          name="message"
                          :class="[{ 'is-invalid': form.errors.has('message') }, 'form-control']"
                          id="message-input"
                          v-model="message"
                          @keydown="form.errors.clear('message');"
                          placeholder="Name"
                        >
                        <div class="invalid-feedback">Please ensure you have less than 140 characters in the message.</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <span class="jem-btn-secondary" @click="$emit('close')">Close Modal</span>
                    <button class="jem-btn-primary" @click="onSend">Send</button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Form from "form-backend-validation";

export default {
  props: {
    candidate: {
      type: Object
    }
  },
  data() {
    return {
      //
      message:'',
      form: new Form(),
    };
  },
  methods: {
      onSend() {
          //
          axios.post(`/api/v1/admin/candidate/${this.candidate.id}/sms`, {message:this.message}).then((res) => {
              this.$message({
                  type:'success',
                  message:'Your message was sent'
              });
              this.$emit('close');
          })
          .catch(errors => {
            this.form.errors.record(errors.response.data.errors);
          });
      }
  }
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1950;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
