import React, { useState, useEffect, useRef } from 'react';
import { ReactFormBuilder } from 'sprint-digital-react-form-builder';
import axios from 'axios';
import items from './formItems';
import StyleEditor from './StyleEditor';
import { applyBackgroundColorAndColor, customizeToolbar, updateFormDataWithStyles, fetchAndSetFormData } from '../utils/formUtils';
import { useToast } from '@chakra-ui/react';
import DoctorReviewForm from './candidates/includes/DoctorReviewForm';
import { createRoot } from 'react-dom/client';

const getUrl = (id) => `/api/v1/admin/forms/${id}/form-template`;

const FormBuilder = (props) => {
  const [formId, setFormId] = useState(props.formId || '');
  const [customFormVersion, setCustomFormVersion] = useState(undefined);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFitslipEnabled, setIsFitslipEnabled] = useState(false);
  const formDataRef = useRef([]);
  const isFitslipEnabledRef = useRef(false);
  const [customStyles, setCustomStyles] = useState({
    heading: {
      color: "#ffffff",
      backgroundColor: "#1F7055",
    },
    button: {
      color: "#ffffff",
      backgroundColor: "#1F7055",
    },
  });
  const customStylesRef = useRef(customStyles);

  useEffect(() => {
    customStylesRef.current = customStyles;
  }, [customStyles]);

  useEffect(() => {
    const checkAndUpdateFitslipHeader = () => {
      const fitslipHeader = document.querySelector('.fitslip-header');
      if (fitslipHeader) {
        if (customFormVersion?.custom_form?.form_type_id !== 3) {
          fitslipHeader.style.display = 'none';
        } else {
          fitslipHeader.style.display = 'block';
        }
        return true;
      }
      return false;
    };
  
    // Check if document is ready
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => {
        if (!checkAndUpdateFitslipHeader()) {
          // If element not found, try again after a short delay
          setTimeout(checkAndUpdateFitslipHeader, 100);
        }
      });
    } else {
      // Document is already loaded
      if (!checkAndUpdateFitslipHeader()) {
        setTimeout(checkAndUpdateFitslipHeader, 100);
      }
    }
  }, [customFormVersion]);

  const editorRootRef = useRef(null); // Ref for the container
  const editorReactRoot = useRef(null); // Ref for the React root
  const toast = useToast();

  useEffect(() => {
    const fetchFormTemplate = async () => {
      if (!formId) return;

      await fetchAndSetFormData(formId, getUrl, setFormData, setCustomStyles, formDataRef, setLoading, customStyles, setIsFitslipEnabled, setCustomFormVersion);
      loadExistingForm();

      window.saveForm = saveForm;

      setTimeout(() => {
        customizeToolbar(editorRootRef, editorReactRoot, customStyles, handleStyleChange, isFitslipEnabled, setIsFitslipEnabled);
      }, 200);

      return () => {
        delete window.saveForm;
      };
    };

    fetchFormTemplate();
  }, [formId]);

  useEffect(() => {
    isFitslipEnabledRef.current = isFitslipEnabled;

    const previewContainer = document.querySelector('.react-form-builder-preview');
    const existingFitslip = document.querySelector('.fitslip-image');
  
    if (isFitslipEnabled) {
      if (!existingFitslip && previewContainer) {
        const fitslipImage = document.createElement('img');
        fitslipImage.src = '/img/fitslip.png';
        fitslipImage.className = 'fitslip-image';
        fitslipImage.style.cssText = `
          width: 100%;
          margin-bottom: 20px;
        `;
        previewContainer.prepend(fitslipImage);
      }
    } else {
      existingFitslip?.remove();
    }
  }, [isFitslipEnabled]);

  useEffect(() => {
    const timer = setTimeout(() => {
        if (editorReactRoot.current) {
          // Use the existing React root to update the content
          editorReactRoot.current.render(
            <StyleEditor styles={customStyles} onStyleChange={handleStyleChange} />
          );
        }
    }, 200);

    return () => clearTimeout(timer);
  }, [customStyles]);

  // Effect to apply background color for the form heading
  useEffect(() => {
    applyBackgroundColorAndColor(formDataRef.current.task_data);
  }, [formData]);

  // Effect to update formData when customStyles changes
  useEffect(() => {
    if (formDataRef.current.custom_styles !== customStyles) {
      updateFormDataWithStyles(formDataRef, customStyles, setFormData);
    }
  }, [customStyles]);

  const loadExistingForm = async () => {
    if (!formId) return;

    await fetchAndSetFormData(formId, getUrl, setFormData, setCustomStyles, formDataRef, setLoading, customStyles, setIsFitslipEnabled, setCustomFormVersion);

    setTimeout(() => {
      customizeToolbar(editorRootRef, editorReactRoot, customStyles, handleStyleChange, isFitslipEnabled, setIsFitslipEnabled);
    }, 200);
  };

  const handleFormSave = (data) => {
    const updatedFormData = {
      task_data: data.task_data || [],
      custom_styles: { ...customStyles },
    };
    formDataRef.current = updatedFormData;
    setFormData(updatedFormData);
  };

  const saveForm = async () => {
    const isFitslipEnabled = isFitslipEnabledRef.current || false;
    
    const currentFormData = {
      ...formDataRef.current,
      custom_styles: { ...customStylesRef.current },
      task_data: formDataRef.current.task_data || []
    };

    if (!formId) {
      toast({
        title: "Form ID is not set!",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      const saveUrl = getUrl(formId);
      await axios.post(saveUrl, { 
        form_id: formId, 
        is_fitslip_enabled: isFitslipEnabled,
        form_template: currentFormData
      });
      toast({
        title: "Form saved successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Failed to save the form. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleStyleChange = (componentType, styleKey, value) => {
    setCustomStyles((prevStyles) => ({
      ...prevStyles,
      [componentType]: {
        ...prevStyles[componentType],
        [styleKey]: value,
      },
    }));
  };

  return (
    <div className="App">
      {loading ? (
        <p>Loading form data...</p>
      ) : (
        <ReactFormBuilder
          data={formData}
          onPost={handleFormSave}
          toolbarItems={items}
        />
      )}
    </div>
  );
};

export default FormBuilder;