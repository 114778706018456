var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",attrs:{"id":"mk-candidate-timeline"}},[_c('div',{staticClass:"col-md-12 pt-3"},[_c('div',[(_vm.is_admin)?_c('button',{staticClass:"jem-btn-primary ml-auto mb-3",on:{"click":function($event){_vm.showTimelineModal = true}}},[_vm._v("\n          Create Entry\n        ")]):_vm._e(),_vm._v(" "),(_vm.is_admin)?_c('a',{staticClass:"jem-btn-primary ml-auto mb-3 mr-3 float-right",attrs:{"href":'/admin/candidates/' + this.candidate.id + '/export/timeline?dates=' + _vm.date}},[_vm._v("Export Timeline")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-md-4 float-right"},[_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"daterange","range-separator":"-","value-format":"yyyy-MM-dd","format":"dd/MM/yyyy","start-placeholder":"Start date","end-placeholder":"End date"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]),_vm._v(" "),(_vm.is_admin && _vm.showTimelineModal)?_c('send-activity-modal',{attrs:{"candidate":_vm.candidate},on:{"close":function($event){_vm.showTimelineModal = false}}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[(_vm.hasResultsPermission && _vm.hasAnyResult)?_c('div',[_c('h2',{staticClass:"result-files-title"},[_vm._v("Result Files")]),_vm._v(" "),_c('div',{staticClass:"result-files-container"},_vm._l((_vm.filteredActivities),function(activity){return _c('div',{key:activity.id},[(
                    activity.description && activity.properties && activity.properties.attachments
                  )?_c('div',[_c('div',{staticClass:"media-files-wrapper"},_vm._l((Object.values(
                        activity.properties.attachments
                      )),function(attachment,index){return _c('media-files',{key:attachment.uuid,attrs:{"file":attachment,"result_id":activity.properties.result_id}})}),1)]):_vm._e()])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"selected-activity-container"},[_c('div',[_c('h2',{staticClass:"mb-4 mt-5 pt-3"},[_vm._v("Notes")]),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],staticClass:"form-control",attrs:{"row":"5"},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing)return;_vm.notes=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"mt-2 btn btn-lg btn-primary",attrs:{"disabled":_vm.disabled},on:{"click":_vm.onSubmit}},[_vm._v("\n                  Save\n                ")])])])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('h1',{staticClass:"mb-4 mt-5"},[_vm._v("\n            Timeline for "+_vm._s(_vm.candidate.name)+"\n            "),(_vm.candidate.location)?_c('small',[_vm._v("("+_vm._s(_vm.candidate.location)+")")]):_vm._e()]),_vm._v(" "),_vm._l((_vm.filteredActivities),function(activity,index){return _c('div',{key:index},[_c('div',{staticClass:"timeline-row d-flex"},[_vm._m(0,true),_vm._v(" "),_c('div',{staticClass:"timeline-card p-4"},[_c('div',{staticClass:"description_container"},[_c('strong',[_vm._v(_vm._s(activity.description))])]),_vm._v(" "),_c('p',{staticClass:"mb-0 d-flex justify-content-between"},[_vm._v("\n                  "+_vm._s(_vm.moment
                      .utc(activity.created_at, 'YYYY-MM-DD HH:mm:ss')
                      .local()
                      .format('DD/MM/YY, hh:mma'))+"\n                  "),(_vm.is_admin)?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.onDelete(activity)}}},[_vm._v("\n                    Delete\n                  ")]):_vm._e()])])])])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-icon ml-2 mr-3"},[_c('div',{staticClass:"timeline-indicator"})])
}]
render._withStripped = true
export { render, staticRenderFns }