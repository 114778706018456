<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="row d-flex justify-content-center mx-2">
          <div v-loading="loading" class="col-md-12">
            <div class="card shadow scrollable-modal-container">
              <div class="row px-3 pt-3">
                <div class="col-4">
                  <h2>
                    {{invoice.id ? invoice.name : 'Suggest Invoice'}}
                    <small
                      v-if="candidate.location"
                    >({{candidate.location}})</small>
                  </h2>
                  <hr />
                </div>
                <div class="col-4">
                  <button
                    v-if="invoice.id && (invoice.status == 'DRAFT' || invoice.status == 'AUTHORISED')"
                    @click="onDelete"
                    class="btn btn-sm btn-danger"
                  >Delete</button>
                </div>
                <div class="col-4">
                  <p>Status: {{invoice.status}} | Paid At : {{invoice.paid_at ? moment.utc(invoice.paid_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY HH:mm:ss') : 'N/A'}}</p>
                </div>
              </div>

              <div class="card-body pt-0 scrollable-modal">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="alert alert-danger"
                      v-if="!invoice.company_id || !invoice.xero_contact_id || !invoice.recruiter_id"
                      role="alert"
                    >
                      You need to link the candidate to a recruiter &amp; company to a Xero account to continue.
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-md-3">
                    <label for="name">Company</label>
                    <div class="input-group">
                      <el-select
                        class="w-100"
                        :disabled="is_saved"
                        @change="onCompanyChange"
                        filterable
                        v-model="invoice.company_id"
                        placeholder="Company"
                      >
                        <el-option
                          v-for="item in companies"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <div
                        class="text-red"
                        v-if="form.errors.has('company_id')"
                      >Please enter a company to link this invoice to.</div>
                      <div
                        class="text-red"
                        v-if="!invoice.xero_contact_id"
                      >Selected Company does not have a Xero contact linked.</div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-3">
                    <label for="name">Reference</label>
                    <div class="input-group">
                      <input
                        type="text"
                        name="name"
                        :class="[{ 'is-invalid': form.errors.has('name') || !invoice.name }, 'form-control']"
                        id="name-input"
                        @keydown="form.errors.clear('name');"
                        v-model="invoice.name"
                        :disabled="is_saved"
                        placeholder="Candidate One PO 102049 Req By Jane"
                      />
                      <div class="invalid-feedback">Please provide a valid Name</div>
                    </div>
                  </div>
                  <div class="form-group col-md-2">
                    <label for>Email</label>
                    <input
                      type="text"
                      name="email"
                      :class="[{ 'is-invalid': form.errors.has('email') }, 'form-control']"
                      id="email-input"
                      @keydown="form.errors.clear('email');"
                      v-model="invoice.email"
                    />
                    <div class="invalid-feedback">Please provide a valid email</div>
                  </div>
                  <div class="form-group col-md-2">
                    <label for>Email</label>
                    <input
                      type="email"
                      name="secondary_email"
                      :class="[{ 'is-invalid': form.errors.has('secondary_email') }, 'form-control']"
                      id="secondary_email-input"
                      @keydown="form.errors.clear('secondary_email');"
                      v-model="invoice.secondary_email"
                    />
                    <div class="invalid-feedback">Please provide a valid secondary email</div>
                  </div>
                  <div class="form-group col-md-2">
                    <label for>Due At</label>
                    <el-date-picker
                      class="w-100"
                      v-model="invoice.due_date"
                      type="date"
                      :disabled="is_saved"
                      value-format="yyyy-MM-dd HH:mm:00"
                      format="dd/MM/yyyy"
                      placeholder="Select date"
                    ></el-date-picker>
                    <div
                      class="text-red"
                      v-if="form.errors.has('due_date')  || !invoice.due_date"
                    >Please enter a due date.</div>
                  </div>
                </div>
                <div class="invoice-item-belt container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <hr />
                    </div>
                    <div class="col-md-4">Item</div>
                    <div class="col-md-2">Account</div>
                    <div class="col-md-2">Quantity</div>
                    <div class="col-md-2">Price</div>
                    <div class="col-md-1">Total</div>
                    <div class="col-md-1">Actions</div>
                  </div>
                  <div
                    v-for="(line_item, index) in invoice.line_items"
                    :key="index"
                    class="row mt-2"
                  >
                    <div class="col-md-4">
                      <div class="input-group">
                        <input
                          type="text"
                          name="name"
                          :disabled="is_saved"
                          :class="[{ 'is-invalid': !line_item.description }, 'form-control']"
                          id="name-input"
                          @keydown="form.errors.clear('description');"
                          v-model="line_item.description"
                        />
                        <div class="invalid-feedback">Please provide a valid description</div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="input-group">
                        <el-select
                          class="w-100"
                          :disabled="is_saved"
                          filterable
                          v-model="line_item.xero_account_id"
                        >
                          <el-option
                            v-for="item in xero_accounts"
                            :key="item.id"
                            :label="item.name + ' ' + item.code"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                        <div v-if="!line_item.xero_account_id">Please provide a valid account</div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <el-input-number
                        :disabled="is_saved"
                        size="mini"
                        @change="onQuantityOrPriceChanged(line_item)"
                        v-model="line_item.quantity"
                        :min="1"
                      ></el-input-number>
                      <div v-if="!line_item.quantity">Please provide a valid quantity</div>
                    </div>
                    <div class="col-md-2">
                      <el-input-number
                        :disabled="is_saved"
                        size="mini"
                        @change="onQuantityOrPriceChanged(line_item)"
                        v-model="line_item.dollar_unit_price"
                        :precision="2"
                        :step="0.1"
                      ></el-input-number>
                      <div v-if="!line_item.dollar_unit_price">Please provide a valid price</div>
                    </div>
                    <div class="col-md-1">${{line_item.dollar_total}}</div>
                    <div class="col-md-1">
                      <span
                        v-if="!invoice.xero_id"
                        class="red-icon-btn"
                        @click="onRemoveLineItem(line_item)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <hr />
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <b>Sub Total:</b>
                    ${{sub_total}}
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <b>GST:</b>
                    ${{gst}}
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <b>Total:</b>
                    ${{total}}
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <hr />
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <el-select
                        class="w-100"
                        :disabled="is_saved"
                        filterable
                        v-model="selected_booking_type"
                        placeholder="Services"
                      >
                        <el-option
                          v-for="item in booking_types"
                          :key="item.id"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <button class="jem-btn-primary" :disabled="is_saved" @click="onAddLineItem">Add</button>
                  </div>
                  <div class="col-md-3">
                    <div class="input-group">
                      <el-select
                        class="w-100"
                        filterable
                        v-model="invoice.invoice_payment_type_id"
                        placeholder="Paid Via"
                      >
                        <el-option
                          v-for="item in invoice_payment_types"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <xero-payments :payments="invoice.xero_payments"></xero-payments>
                <div class="row mt-3">
                  <div class="col-12">
                    <hr />
                  </div>
                  <div class="col-12 d-flex justify-content-between">
                    <div class="mt-2">
                      <span class="jem-btn-secondary" @click="$emit('close')">Close</span>
                    </div>
                    <div>
                      <a
                        v-if="invoice.id"
                        class="jem-btn-primary"
                        :href="'/admin/invoice/pdf/save/'+invoice.xero_id"
                        target="_blank"
                      >View & Print PDF</a>
                      <button v-if="invoice.id" class="jem-btn-primary" @click="onSave">Update</button>
                      <a
                        v-else
                        class="jem-btn-primary"
                        :href="'/admin/invoice/pdf/save?invoice='+encodeURIComponent(JSON.stringify(invoice))"
                        target="_blank"
                      >View & Print PDF</a>
                      <button
                        v-if="invoice.id"
                        :disabled="!invoice.email"
                        class="jem-btn-primary"
                        @click="onSend"
                      >Send PDF</button>

                      <button
                        v-if="invoice.xero_id && (invoice.status == 'DRAFT' || invoice.status == 'AUTHORISED' || invoice.status == 'AUTHORISED') && (invoice.xero_payments && invoice.xero_payments.length == 0)"
                        class="jem-btn-primary"
                        @click="onSubmit(true, null, false)"
                      >Mark as Paid</button>

                      <button
                        v-if="invoice.status == 'PENDING' && !invoice.xero_id"
                        :disabled="!invoice.company_id"
                        class="jem-btn-primary"
                        @click="onSubmit(false, 'DRAFT', false)"
                      >Send To Xero as Draft</button>

                      <button
                        v-if="invoice.status == 'PENDING' && !invoice.xero_id"
                        :disabled="!invoice.company_id"
                        class="jem-btn-primary"
                        @click="onSubmit(false, 'AUTHORISED', true)"
                      >Send To Xero as Submitted &amp; Send PDF</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Invoice from "../../../../models/Invoice";
import Candidate from "../../../../models/Candidate";
import Form from "form-backend-validation";

export default {
  props: {
    booking_types: {
      type: Array
    },
    candidate: {
      type: Object
    },
    companies: {
      type: Array
    },
    initial_invoice: {
      type: Object
    },
    xero_accounts: {
      type: Array
    },
    invoice_payment_types: {
      type: Array
    }
  },
  computed: {
    is_saved() {
      if (this.invoice.xero_id && this.invoice.xero_id.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    sub_total() {
      if (this.invoice.line_items.length) {
        return this.reduceArray(
          this.invoice.line_items,
          "dollar_total"
        ).toFixed(2);
      }
      return 0;
    },
    gst() {
      if (this.invoice.line_items.length) {
        return (this.sub_total / 10).toFixed(2);
      }
      return 0;
    },
    total() {
      if (this.invoice.line_items.length) {
        return Number(Number(this.sub_total) + Number(this.gst)).toFixed(2);
      }
      return 0;
    }
  },
  mounted() {
    if (!this.initial_invoice.id) {
      this.invoice.company_id = this.candidate.user
        ? this.candidate.user.company_id
        : "";
      this.invoice.recruiter_id = this.candidate.user
        ? this.candidate.user.id
        : "";
      this.invoice.candidate_id = this.candidate.id;
      this.invoice.status = "PENDING";
      (this.invoice.invoice_payment_type_id = ""),
        (this.invoice.email =
          this.candidate.user && this.candidate.user.company
            ? this.candidate.user.company.email
            : ""),
        (this.invoice.type = "ACCREC");
      this.invoice.due_date = moment()
        .add(1, "month")
        .format("YYYY-MM-DD 00:00:00");
      this.invoice.xero_payments = [];
      this.invoice.xero_contact_id =
        this.candidate.user &&
        this.candidate.user.company &&
        this.candidate.user.company.xero_contact_id
          ? this.candidate.user.company.xero_contact_id
          : "";
      this.invoice.name =
        this.candidate.name +
        " PO " +
        (this.candidate.po_number || "N/A") +
        " Req By " +
        (this.candidate.user ? this.candidate.user.full_name : "");

      _.forEach(this.candidate.booking_types, booking_type => {
        if (booking_type.xero_item_id) {
          this.invoice.line_items.push({
            xero_item_id: booking_type.xero_item_id,
            description: booking_type.title,
            quantity: 1,
            xero_account_id: booking_type.xero_item.xero_account_id,
            tax_type: booking_type.xero_item.tax_type,
            booking_type_id: booking_type.id,
            total: Number(booking_type.xero_item.unit_price).toFixed(2),
            dollar_total: Number(booking_type.xero_item.unit_price).toFixed(2),
            unit_price:
              Number(booking_type.xero_item.unit_price).toFixed(2) * 100,
            dollar_unit_price: Number(
              booking_type.xero_item.unit_price
            ).toFixed(2)
          });
        } else {
          this.unlinked_booking_types.push(booking_type);
        }

        if (this.unlinked_booking_types.length) {
          this.$message({
            type: "warning",
            message:
              "Some service types were not added due to not being linked to a xero item."
          });
        }
      });
    }
  },
  data() {
    return {
      //
      invoice: new Invoice(
        this.initial_invoice.id
          ? this.initial_invoice
          : {
              company_id: "",
              email:
                this.candidate.user && this.candidate.user.company
                  ? this.candidate.user.company.email
                  : "",
              candidate_id: "",
              due_date: "",
              invoice_payment_type_id: "",
              xero_contact_id: "",
              name: "",
              line_items: [],
              xero_payments: [],
              mark_as_paid: false
            }
      ).for(new Candidate({ id: this.candidate.id })),
      form: new Form(),
      loading: false,
      unlinked_booking_types: [],
      selected_booking_type: ""
    };
  },
  methods: {
    onSave() {
      this.invoice
        .save()
        .then(res => {
          this.$message({
            type: "success",
            message: "Saved"
          });
        })
        .catch(error => {
          this.$message({
            type: "error",
            message:
              "Whoops! Something went wrong. Please try again later or contact support if this issue persists."
          });
        });
    },
    onSubmit(markAsPaid = false, invoiceStatus = null, sendPdf = false) {
      //
      let oldStatus = this.invoice.status;
      let oldMark = this.invoice.mark_as_paid;
      let oldSent = this.invoice.send_pdf;

      _.forEach(this.invoice.line_items, line_item => {
        line_item.unit_price = line_item.dollar_unit_price * 100;
      });
      if (markAsPaid) {
        this.invoice.mark_as_paid = true;
      }

      if (sendPdf) {
        this.invoice.send_pdf = true;
      }

      if (invoiceStatus) {
        this.invoice.status = invoiceStatus;
      }

      this.$confirm(
        sendPdf
          ? "Send PDF?"
          : this.invoice.mark_as_paid
          ? "You are about to mark this invoice as Paid. Continue?"
          : "You are about to save this invoice no further changes will be able to be made. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: this.invoice.mark_as_paid
            ? "Cancel"
            : "I need to make more changes first.",
          type: "warning"
        }
      )
        .then(() => {
          this.loading = true;
          this.invoice
            .save()
            .then(res => {
              this.$message({
                type: "success",
                message: this.invoice.mark_as_paid ? "Sent" : "Saved"
              });
              this.$emit("added", this.invoice);
              this.loading = false;
              if (this.invoice.mark_as_paid) {
              } else {
                this.$emit("close");
              }
            })
            .catch(errors => {
              this.loading = false;
              this.invoice.status = oldStatus;
              this.invoice.mark_as_paid = oldMark;
              this.invoice.send_pdf = oldSent;
              this.form.errors.record(errors.response.data.errors);
              this.$message({
                type: "error",
                message:
                  "Whoops! Something went wrong. Are you sure you set all information correctly? Please contact support if this issue persists."
              });
            });
        })
        .catch(() => {
          this.invoice.status = oldStatus;
          this.invoice.mark_as_paid = oldMark;
          this.invoice.send_pdf = oldSent;
        });
    },
    onSend() {
      this.onSubmit(false, null, true);
    },
    onCompanyChange() {
      let company = this.companies.filter(
        x => x.id == this.invoice.company_id
      )[0];
      if (company) {
        this.invoice.xero_contact_id = company.xero_contact_id;
        this.$confirm(
          "Would you like to make the invoice email address this companies address also?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        )
          .then(() => {
            this.invoice.email = company.email;
          })
          .catch(() => {
            this.$message({
              type: "warnings",
              message: "Invoice email not changed"
            });
          });
      }
    },
    onRemoveLineItem(line_item) {
      let index = this.invoice.line_items.indexOf(line_item);
      this.invoice.line_items.splice(index, 1);
    },
    onAddLineItem() {
      let booking_type = this.booking_types.filter(
        x => x.id == this.selected_booking_type
      )[0];
      if (booking_type && booking_type.xero_item_id) {
        this.invoice.line_items.push({
          xero_item_id: booking_type.xero_item_id,
          description: booking_type.title,
          quantity: 1,
          xero_account_id: booking_type.xero_item.xero_account_id,
          tax_type: booking_type.xero_item.tax_type,
          booking_type_id: booking_type.id,
          total: Number(booking_type.xero_item.unit_price).toFixed(2),
          dollar_total: Number(booking_type.xero_item.unit_price).toFixed(2),
          unit_price:
            Number(booking_type.xero_item.unit_price).toFixed(2) * 100,
          dollar_unit_price: Number(booking_type.xero_item.unit_price).toFixed(
            2
          )
        });
      } else {
        this.$message({
          type: "error",
          message:
            "This service is not yet linked to a Xero Cost. Please link and try again."
        });
      }
      this.selected_booking_type = "";
    },
    onQuantityOrPriceChanged(line_item) {
      line_item.unit_price = line_item.dollar_unit_price * 100;
      line_item.dollar_total = (
        (line_item.quantity * line_item.unit_price) /
        100
      ).toFixed(2);
      line_item.total = (
        (line_item.quantity * line_item.unit_price) /
        100
      ).toFixed(2);
    },
    reduceArray(object, key) {
      return object.reduce(function(accumulator, currentValue) {
        return accumulator + parseFloat(currentValue[key]);
      }, 0);
    },
    onDelete() {
      this.$confirm(
        "This action is irreversible and will delete the invoice in Xero also. Continue?",
        "Warning",
        {
          confirmButtonText: "I understand, Delete",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        this.loading = true;
        this.invoice.delete().then(res => {
          this.$message({
            type: "success",
            message: "Deleted"
          });
          this.loading = false;
          this.$emit("deleted", this.invoice);
          this.$emit("close");
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1950;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}

@media only screen and (max-height: 800px) {
  .scrollable-modal-container {
    max-height: 100vh;
  }
  .scrollable-modal {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 50px;
  }
}
.invoice-item-belt {
  max-height: 250px;
  overflow-y: auto;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>