<template>
  <div class="jem-paginated-table p-3">
    <div class="jem-table-controls w-100">
      <div v-if="tableData.showSearch" class="w-100">
        <div class="w-100 d-flex align-items-center">
          <div
            class="input-group d-flex justify-content-between align-items-center"
            style="max-width: 300px"
          >
            <div class="d-flex flex-row">
              <div class="search-container">
                <input
                  class="search-input"
                  style="min-width: 300px"
                  v-model="query"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  @keyup.enter="getResults(1)"
                />
                <i class="fas fa-search search-icon"></i>
              </div>
            </div>
          </div>

          <div
            v-if="showPerPage"
            class="d-flex justify-content-between align-items-center ml-4"
            style="max-width: 160px"
          >
            <select @change="getResults(1)" v-model="per_page">
              <option>15</option>
              <option>25</option>
              <option>100</option>
            </select>
            <p class="mb-0 ml-3 text-dark-grey">Per Page</p>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive mt-4">
      <table class="table">
        <thead>
          <tr>
            <th
              scope="col"
              @click="onSort(column)"
              v-for="(column, index) in tableData.columns"
              :key="index"
              style="cursor: pointer !important"
            >
              {{ column.label }}
            </th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody v-if="paginatedData.data.length > 0">
          <tr v-for="(data, index) in paginatedData.data" :key="index">
            <td v-for="(column, index) in tableData.columns" :key="index">
              <span v-if="column.multiple">
                <ul>
                  <li v-for="(item, itemIndex) in getProperty(column.name, data)" :key="itemIndex">
                    {{ item }}
                  </li>
                </ul>
              </span>
              <template v-else>
                <span v-if="column.date">
                  {{
                    moment
                      .utc(data[column.name], 'YYYY-MM-DD HH:mm:ss')
                      .local()
                      .format('DD/MM/Y HH:mm:ss')
                  }}
                </span>
                <span v-else-if="column.formatter">
                  {{ column.formatter(getProperty(column.name, data)) }}
                </span>
                <span v-else-if="column.name == 'booking_type_titles'">
                  <ul class="">
                    <template v-if="getProperty(column.name, data).length > 2">
                      <li
                        v-for="(item, itemIndex) in getProperty(column.name, data).slice(0, 2)"
                        :key="itemIndex"
                      >
                        {{ item }}
                      </li>
                      <button
                        class="btn btn-link p-0 text-gray-600"
                        @click="openViewMoreModal(getProperty(column.name, data))"
                      >
                        View {{ getProperty(column.name, data).length - 2 }} more...
                      </button>
                    </template>
                    <template v-else>
                      <li
                        v-for="(item, itemIndex) in getProperty(column.name, data)"
                        :key="itemIndex"
                      >
                        {{ item }}
                      </li>
                    </template>
                  </ul>
                </span>
                <span v-else>
                  {{ getProperty(column.name, data) }}
                </span>
              </template>
            </td>

            <td>
              <button
                v-if="tableData.overrideView"
                class="green-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                @click="$emit('on-view', data)"
                title="View"
              >
                <i class="fas fa-eye fa-lg"></i>
              </button>
              <a
                v-else-if="tableData.viewUrl && tableData.edit"
                class="green-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                :href="tableData.viewUrl + '/' + data.id + '/edit'"
              >
                <i class="fas fa-eye fa-lg"></i>
              </a>
              <a
                v-else-if="tableData.viewUrl"
                class="green-icon-btn"
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                :href="tableData.viewUrl + '/' + data.id"
              >
                <i class="fas fa-eye fa-lg"></i>
              </a>
              <form-actions-dropdown
                v-else-if="tableData.formActions"
                :formId="data.id"
                :isFormActive="data.is_active"
                @form-updated="$emit('form-updated')"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-if="paginatedData.data.length == 0">
      <div class="col-md-12 d-flex justify-content-center">
        <h1>Whoops! Looks like there's no data here.</h1>
      </div>
    </div>

    <pagination
      :limit="4"
      :show-disabled="true"
      :data="paginatedData"
      @pagination-change-page="getResults"
    >
      <span slot="prev-nav">
        <i class="fas fa-chevron-left"></i>
      </span>
      <span slot="next-nav">
        <i class="fas fa-chevron-right"></i>
      </span>
    </pagination>

    <view-more-modal
      :show="showViewMoreModal"
      :items="modalItems"
      @update:show="showViewMoreModal = $event"
    />
  </div>
</template>

<script>
import FormActionsDropdown from '../../backend/views/form-builder/components/FormActionsDropdown.vue'
import ViewMoreModal from '../components/ViewMoreModal.vue'

export default {
  components: {
    FormActionsDropdown,
    ViewMoreModal
  },
  props: {
    tableData: {
      type: Object
    },
    paginatedData: {
      type: Object
    },
    initial_query: {
      type: String
    },
    showPerPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortedColumn: null,
      query: this.initial_query,
      per_page: 15,
      desc: true,
      showViewMoreModal: false,
      modalItems: []
    }
  },
  methods: {
    getResults(page = 1) {
      this.$emit(
        'pagination-change-page',
        page,
        this.sortedColumn,
        this.query,
        this.per_page,
        this.desc
      )
    },
    onSort(column) {
      if (this.sortedColumn && this.sortedColumn.name == column.name) {
        this.desc = !this.desc
      } else {
        this.desc = false
      }
      this.sortedColumn = column
      this.getResults(1)
    },
    getProperty(propertyName, object) {
      var parts = propertyName.split('.'),
        length = parts.length,
        i,
        property = object || this

      for (i = 0; i < length; i++) {
        property = property[parts[i]] || 'N/A'
      }
      return property
    },
    openViewMoreModal(items) {
      this.modalItems = items
      this.showViewMoreModal = true
    }
  }
}
</script>

<style scoped lang="scss">
.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  padding-right: 30px;
  border-radius: 8px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.text-gray-600 {
  color: #718096;
  &:hover {
    color: #4a5568;
    text-decoration: none;
  }
}
</style>
