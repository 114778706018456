import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    VStack,
    Text,
    Heading,
    Divider,
    Flex,
} from '@chakra-ui/react';
import { formatLocalTime } from '../../../utils/formUtils';
import DoctorReviewComment from '../../../../backend/models/DoctorReviewComment';

const CandidateCommentDrawer = ({ isOpen, onClose, candidateId, doctorReviewId }) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (!isOpen) return;
        if (doctorReviewId) {
            DoctorReviewComment.where('doctor_review_id', doctorReviewId).include('user').get().then(response => {
                setComments(response.data);
            });
            return;
        }else if (candidateId) {
            DoctorReviewComment.where('candidate_id', candidateId).include('user').get().then(response => {
                setComments(response.data);
            });
            return;
        }
    }, [isOpen]);

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">Comments/Timeline</DrawerHeader>

                <DrawerBody>
                    <VStack spacing={0} align="stretch">
                        {comments.map((comment) => (
                            <CandidateCommentDrawerRow key={comment.id} comment={comment} />
                        ))}
                    </VStack>
                </DrawerBody>

            </DrawerContent>
        </Drawer>
    );
};

CandidateCommentDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CandidateCommentDrawer;

const CandidateCommentDrawerRow = ({ comment }) => {
    return (
        <Flex my={2} direction="column" gap={2}>
            <Heading as='h4' size='md' m={0} p={0}>
                {comment.title}
            </Heading>
            <Text m={0} p={0}>{comment.comment}</Text>
            <Text color="gray.500" fontSize="sm" m={0} p={0}>
                {comment.user?.full_name ?? 'n/a'} - {formatLocalTime(comment.created_at)}
            </Text>
            <Divider my={2} p={0} />
        </Flex>
    );
}