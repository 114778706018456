<template>
  <div id="mk-recruiter-form">

    <!-- title row -->
    <div class="row mb-4 d-flex justify-content-between">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h1>{{recruiter.full_name || 'Recruiter'}}</h1>

        <div class="d-flex justify-content-end">
          <a
            :href="'/admin/auth/user/'+recruiter.id+'/login-as'"
            v-if="recruiter.id"
            class="jem-btn-secondary ml-3"
          >Login As</a>
          <button
            v-if="recruiter.id"
            class="jem-btn-secondary ml-3"
            @click="showSmsModal = true"
          >Send SMS</button>
        <send-user-sms-modal @close="showSmsModal = false" :recruiter="recruiter" v-if="showSmsModal"></send-user-sms-modal>

          <button
            v-if="recruiter.id"
            class="jem-btn-secondary ml-3"
            @click="sendResetLink"
          >Send Reset Link</button>

          <button class="jem-btn-primary ml-3" @click="onSubmit">Save</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label for="name">First Name</label>
        <div class="input-group">
          <input
            type="text"
            name="first_name"
            :class="[{ 'is-invalid': form.errors.has('first_name') }, 'form-control']"
            id="first-name-input"
            @keydown="form.errors.clear('first_name');"
            v-model="recruiter.first_name"
            placeholder="First Name"
          >
          <div class="invalid-feedback">Please choose a first name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Last Name</label>
        <div class="input-group">
          <input
            type="text"
            name="first_name"
            :class="[{ 'is-invalid': form.errors.has('last_name') }, 'form-control']"
            id="last-name-input"
            @keydown="form.errors.clear('last_name');"
            v-model="recruiter.last_name"
            placeholder="Last Name"
          >
          <div class="invalid-feedback">Please choose a last name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Email</label>
        <div class="input-group">
          <input
            type="text"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('email') }, 'form-control']"
            id="email-input"
            @keydown="form.errors.clear('email');"
            v-model="recruiter.email"
            placeholder="Email"
          >
          <div class="invalid-feedback">Please provide a valid email</div>
        </div>
      </div>

       <div class="form-group col-12 col-md-4">
        <label for="name">Number</label>
        <div class="input-group">
          <vue-tel-input
            @onInput="form.errors.clear('number');"
            :class="[{ 'is-invalid': form.errors.has('number') }, 'w-100']"
            :onlyCountries="['AU', 'GB', 'NZ', 'US']"
            v-model="recruiter.number"
          ></vue-tel-input>
          <div class="invalid-feedback">Please provide a valid number</div>
        </div>
      </div>


      <div class="form-group col-12 col-md-4">
        <label for="name">Status</label>
        <div class="input-group">
          <el-select class="w-100" v-model="recruiter.confirmed" placeholder="Status">
            <el-option :key="0" :label="'Unconfirmed'" :value="false"></el-option>
            <el-option :key="1" :label="'Confirmed'" :value="true"></el-option>
          </el-select>
        </div>
      </div>


      <div class="form-group col-12 col-md-4">
        <label for="name">Company</label>
        <div class="input-group">
          <el-select class="w-100" v-model="recruiter.company_id" placeholder="Company">
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="name">Roles</label>
        <div class="input-group">
          <el-select class="w-100" v-model="recruiter.roles" multiple placeholder="Roles">
            <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="name">Preferences</label>
        <div class="input-group">
          <el-checkbox v-model="recruiter.send_candidate_confirmations">Send Candidate Communications</el-checkbox>
        </div>
      </div>
    </div>


    <hr class="my-5">


    <div class="row pt-3">
      <div v-if="recruiter.id" class="col-md-12">
        <tabs>
          <tab name="Candidates">  

            <div class="d-flex m-3">
              <a :href="'/admin/candidates/create?user_id='+this.recruiter.id" class="ml-auto jem-btn-primary">Create Candidate</a>
            </div>

            <candidate-index :user_id="recruiter.id"> </candidate-index>
          </tab>
          <tab name="Invoices">
            <recruiter-invoice :recruiter="recruiter" :invoice_payment_types="invoice_payment_types" :xero_accounts="xero_accounts" :companies="companies"></recruiter-invoice>
          </tab>
        </tabs>
      </div>
    </div>

  </div>
</template>


<script>
import User from "../../models/User";
import Form from "form-backend-validation";
import VueTelInput from "vue-tel-input";

export default {
  components: {
    VueTelInput
  },
  props: {
    initial_recruiter: {
      type: Object
    },
    invoice_payment_types:{
      type: Array
    },
    initial_company_id: {
      type: Number
    },
    companies: {
      type: Array
    },
    xero_accounts: {
      type: Array
    },
    roles: {
      type: Array
    }
  },
  data() {
    return {
      recruiter: new User(this.initial_recruiter || {company_id: this.initial_company_id, confirmed:true, password: 'secret', password_confirmation:'secret', active: 1, roles:['recruiter']}),
      form: new Form(),
      showSmsModal: false
    };
  },
  methods: {
    sendResetLink() {
      //
      axios.post(`/api/v1/admin/user/${this.recruiter.id}/reset`, {data: this.recruiter.email}).then((res) =>{
        this.$message({
          type:'success',
          message:'Sent Reset'
        })
      })
    },
    onSubmit() {
      let oldRoles = this.recruiter.roles
      this.recruiter
        .save()
        .then(res => {

          this.recruiter.roles = [];
          _.forEach(oldRoles, role => {
            this.recruiter.roles.push(role);
          });

          this.$message({
            type: "success",
            message: "Saved"
          });
          
          this.recruiter = new User(res);
          var url = window.location.toString();
          url = url.replace("create", `${res.id}/edit`);
          window.history.pushState(null, null, url);
        })
        .catch(errors => {
          this.form.errors.record(errors.response.data.errors);
        });
    }
  }
};
</script>