import React, { useState } from 'react';
import { Box, VStack, Text, Badge, Divider, Button, HStack, Heading } from '@chakra-ui/react';

const ChangeHistory = ({ audits, title }) => {
    const [showAll, setShowAll] = useState(false);
    
    if (!audits || audits.length === 0) {
        return (
            <Box p={4}>
                <Text>No changes recorded yet.</Text>
            </Box>
        );
    }

    const displayedAudits = showAll ? audits : audits.slice(0, 3);

    return (
        <VStack align="stretch">
            <Heading fontSize="lg" mb={2} color="#171923">{title}</Heading>

            {displayedAudits.map((audit) => {
                const oldValues = audit.old_values || {};
                const newValues = audit.new_values || {};
                
                // Filter out unchanged values
                const changes = Object.entries(oldValues).filter(([key, oldValue]) => {
                    const newValue = newValues[key];
                    return oldValue !== newValue;
                });

                if (changes.length === 0) return null;

                return (
                    <Box key={audit.id} mb={2}>
                        <Text fontWeight="bold"  fontSize="md" color="#171923"  mb={2}>{audit.tags}</Text>
                        <VStack align="start" spacing={0}>
                            {changes.map(([key, oldValue], index) => (
                                <React.Fragment key={key}>
                                    <Box width="100%">
                                        <VStack align="start" spacing={0}>
                                            <Text mb={1}>Question: {key}</Text>
                                            <Text fontSize="md" color="#171923" mb={1}>
                                                Original: {oldValue?.toString() || 'n/a'}
                                            </Text>
                                            <Text fontSize="md" color="#171923" mb={1}>
                                                Updated to: {newValues[key]?.toString() || 'n/a'}
                                            </Text>
                                        </VStack>
                                    </Box>
                                    {index < changes.length - 1 && <Divider my={2} color="#CBD5E0" />}
                                </React.Fragment>
                            ))}
                            <HStack spacing={2} mt={2}>
                                <Text fontSize="md" color="#171923">
                                    {audit.user?.full_name || audit.user?.name || 'Unknown User'}, {new Date(audit.created_at).toLocaleString()}
                                </Text>
                            </HStack>
                            <Divider my={2} color="#CBD5E0" />
                        </VStack>
                    </Box>
                );
            })}
            {audits.length > 3 && (
                <Button
                    variant="link"
                    colorScheme="teal"
                    onClick={() => setShowAll(!showAll)}
                    mt={2}
                >
                    {showAll ? 'Show Less' : `Show ${audits.length - 3} More Changes`}
                </Button>
            )}
        </VStack>
    );
};

export default ChangeHistory; 