import { render, staticRenderFns } from "./ViewMoreModal.vue?vue&type=template&id=734f05b8&scoped=true"
import script from "./ViewMoreModal.vue?vue&type=script&lang=js"
export * from "./ViewMoreModal.vue?vue&type=script&lang=js"
import style0 from "./ViewMoreModal.vue?vue&type=style&index=0&id=734f05b8&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734f05b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.jemhealth.2mm.cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('734f05b8')) {
      api.createRecord('734f05b8', component.options)
    } else {
      api.reload('734f05b8', component.options)
    }
    module.hot.accept("./ViewMoreModal.vue?vue&type=template&id=734f05b8&scoped=true", function () {
      api.rerender('734f05b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/global/components/ViewMoreModal.vue"
export default component.exports