import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import DoctorReviewTable from './DoctorReviewTable';
import DoctorReviewFilters from './DoctorReviewFilters';

const DoctorReviewTabPanel = ({
    loading,
    doctorReviews,
    selectedDoctor,
    setSelectedDoctor,
    selectedLocation,
    setSelectedLocation,
    showComments = false,
    commentField = '',
    currentPage,
    itemsPerPage,
    lastPage,
    setCurrentPage,
    searchQuery,
    setSearchQuery,
    doctor_review_status_id = 1
}) => {
    return (
        <>
            <DoctorReviewFilters
                selectedDoctor={selectedDoctor}
                setSelectedDoctor={setSelectedDoctor}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
            {loading ? (
                <Flex justifyContent="center" alignItems="center" height="100%">
                    <Spinner size="xl" color="teal.500" />
                </Flex>
            ) : (
                <>
                    <DoctorReviewTable
                        data={doctorReviews}
                        showComments={showComments}
                        commentField={commentField}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        lastPage={lastPage}
                        setCurrentPage={setCurrentPage}
                        doctorReviews={doctorReviews}
                        doctor_review_status_id={doctor_review_status_id}
                    />
                </>
            )}
        </>
    );
};

export default DoctorReviewTabPanel;
