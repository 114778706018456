import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

const PaginationButtons = ({ currentPage, totalPages, setCurrentPage }) => {
  const buttons = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
      buttons.push(
        <Button
          key={i}
          onClick={() => setCurrentPage(i)}
          variant={currentPage === i ? 'solid' : 'outline'}
          bg={currentPage === i ? 'teal.500' : 'transparent'}
          color={currentPage === i ? 'white' : 'black'}
          mx={0}
          borderRadius="0"
        >
          {i}
        </Button>
      );
    } else if (i === currentPage - 2 || i === currentPage + 2) {
      buttons.push(
        <Button 
          key={i} 
          mx={0} 
          variant="solid" 
          isDisabled
          borderRadius="0"
        >
          ...
        </Button>
      );
    }
  }

  return (
    <Flex justifyContent="center" alignItems="center" mt={4}>
      <Button
        isDisabled={currentPage === 1}
        onClick={() => setCurrentPage((prev) => prev - 1)}
        mr={0}
        borderRadius="8px 0 0 8px"
        background={'#ffffff'}
        border="1px solid #CBD5E0"
      >
        <i className="fas fa-chevron-left"></i>
      </Button>
      {buttons}
      <Button
        isDisabled={currentPage === totalPages}
        onClick={() => setCurrentPage((prev) => prev + 1)}
        ml={0}
        borderRadius="0 8px 8px 0"
        background={'#ffffff'}
        border="1px solid #CBD5E0"
      >
        <i className="fas fa-chevron-right"></i>
      </Button>
    </Flex>
  );
};

export default PaginationButtons;