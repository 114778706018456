import React from 'react';
import { createRoot } from "react-dom/client";
import StyleEditor from '../components/StyleEditor';
import axios from 'axios';
import moment from 'moment';

export const applyBackgroundColorAndColor = (taskData) => {
  const _formData = taskData || [];
  // Find the task with element "Header" and get its background color and color
  const headerItem = _formData.find(item => item.element === "Header");
  if (headerItem) {
    const { background_color, content } = headerItem;

    // Find all SortableItem divs with "Section heading"
    const sortableItemDivs = Array.from(document.querySelectorAll('.SortableItem')).filter(item =>
      item.querySelector('.toolbar-header span.badge')?.textContent === 'Section heading'
    );

    sortableItemDivs.forEach(sortableItemDiv => {
      // Apply background color if the div exists
      if (background_color) {
        sortableItemDiv.style.backgroundColor = background_color;
      }

      // Apply heading color if the content exists
      const headingSpan = sortableItemDiv.querySelector('h3 span') || sortableItemDiv.querySelector('h3');
      if (headingSpan && content.includes('style="color:')) {
        const colorMatch = content.match(/color:\s*([^;"]+)/);
        if (colorMatch) {
          headingSpan.style.color = colorMatch[1];
        }
      }
    });
  }
};

export const applyCustomStyleForHeading = (formTemplate, formCustomStyles) => {
  const taskData = formTemplate || [];
  
  // Find the tasks with element "Header" and extract the content
  const headerItems = taskData.filter(item => item.element === "Header");

  if (headerItems.length > 0) {
    // Convert headerContents from HTML to plain text for comparison
    const headerPlainTexts = headerItems.map(item => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = item.content;
      return tempDiv.textContent.trim(); // Extracts plain text
    });

    // Select all .SortableItem elements and filter those that match headerPlainTexts
    const sortableItemDivs = Array.from(document.querySelectorAll('.SortableItem')).filter(item => {
      const heading = item.querySelector('h3');
      const headingText = heading?.textContent.trim(); // Extract plain text from h3
      return headerPlainTexts.includes(headingText);
    });

    const customBackground = formCustomStyles.heading.backgroundColor;
    const customColor = formCustomStyles.heading.color;

    // Apply styles to matching elements
    sortableItemDivs.forEach(sortableItemDiv => {
      sortableItemDiv.setAttribute(
        "style",
        `background-color: ${customBackground}; -webkit-print-color-adjust: exact; padding: .75rem;`
      );

      const heading = sortableItemDiv.querySelector('h3');
      if (heading) {
        heading.style.color = customColor;

        // Ensure nested <span> elements inside h3 also get updated
        heading.querySelectorAll('span').forEach(span => {
          span.style.color = customColor;
        });
      }
    });
  }
};

// Constants
const COLORS = {
  teal: '#319795',
  gray: '#CBD5E0',
  text: {
    dark: '#171923',
    muted: '#4A5568'
  }
};

const STYLES = {
  header: `
    margin-bottom: 16px;
    margin-top: 16px;
  `,
  title: `
    font-size: 1.25rem;
    font-weight: 600;
    color: ${COLORS.text.dark};
    margin-bottom: 16px;
  `,
  toggleContainer: `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  label: `
    font-size: 0.875rem;
    color: ${COLORS.text.muted};
  `,
  toggle: `
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  `,
  input: `
    opacity: 0;
    width: 0;
    height: 0;
  `,
  slider: `
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${COLORS.gray};
    transition: .4s;
    border-radius: 34px;
    width: 36px;
  `,
  circle: `
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  `
};

// Helper Functions
const createElementWithStyles = (tag, className, styles, text = '') => {
  const element = document.createElement(tag);
  if (className) element.className = className;
  if (styles) element.style.cssText = styles;
  if (text) element.textContent = text;
  return element;
};

const updateToggleStyles = (isEnabled, slider, circle) => {
  slider.style.backgroundColor = isEnabled ? COLORS.teal : COLORS.gray;
  circle.style.transform = isEnabled ? 'translateX(16px)' : 'translateX(0)';
};

// Main Function
export const customizeToolbar = (editorRootRef, editorReactRoot, customStyles, handleStyleChange, isFitslipEnabled, setIsFitslipEnabled) => {
  const toolbar = document.querySelector('.react-form-builder-toolbar');
  if (!toolbar || toolbar.querySelector('.fitslip-header')) return;

  // Create Elements
  const headerSection = createElementWithStyles('div', 'fitslip-header', STYLES.header);
  const title = createElementWithStyles('h2', '', STYLES.title, 'Fitslip');
  const toggleContainer = createElementWithStyles('div', '', STYLES.toggleContainer);
  const label = createElementWithStyles('span', '', STYLES.label, 'Show Candidate Suitability Fit Slip');
  const toggle = createElementWithStyles('label', 'switch', STYLES.toggle);
  const input = createElementWithStyles('input', '', STYLES.input);
  const slider = createElementWithStyles('span', 'slider', STYLES.slider);
  const circle = createElementWithStyles('span', '', STYLES.circle);

  // Configure Input
  input.type = 'checkbox';
  input.checked = isFitslipEnabled;
  input.id = 'fitslip-toggle';

  // Add Event Listener
  input.addEventListener('change', (e) => {
    updateToggleStyles(e.target.checked, slider, circle);
    setIsFitslipEnabled(e.target.checked);
  });

  // Initial State
  if (isFitslipEnabled) {
    updateToggleStyles(true, slider, circle);
  }

  // Assemble Elements
  slider.appendChild(circle);
  toggle.appendChild(input);
  toggle.appendChild(slider);
  toggleContainer.appendChild(label);
  toggleContainer.appendChild(toggle);
  headerSection.appendChild(title);
  headerSection.appendChild(toggleContainer);
  toolbar.prepend(headerSection);

  // Update the toolbar buttons
  document.querySelectorAll('.toolbar-group-item button').forEach((button) => {
    const h3 = document.createElement('h3');
    h3.className = 'toolbar-group-name';
    h3.innerHTML = button.innerHTML;
    button.parentNode.replaceChild(h3, button);
  });

  // Ensure toolbar groups are expanded
  document
    .querySelectorAll('.toolbar-group-item .collapse')
    .forEach((el) => el.classList.add('show'));

  const toolbarGroupItems = document.querySelectorAll('.toolbar-group-item');

  if (toolbarGroupItems.length > 0) {
    const lastToolbarGroupItem = toolbarGroupItems[toolbarGroupItems.length - 1];

    const ulElement = lastToolbarGroupItem.querySelector('ul');
    if (ulElement) {
      ulElement.remove();
    }

    if (!editorRootRef.current) {
      // Create the container and React root once
      const editorContainer = document.createElement('div');
      editorContainer.className = 'custom-style-editor';
      lastToolbarGroupItem.appendChild(editorContainer);

      editorRootRef.current = editorContainer;
      editorReactRoot.current = createRoot(editorContainer);
    }

    // Initial render of StyleEditor
    editorReactRoot.current.render(
      <StyleEditor styles={customStyles} onStyleChange={handleStyleChange} />
    );
  }
};

export const applyButtonStyles = (formTemplate, formCustomStyles) => {
  // Select all button elements in the form
  const buttons = document.querySelectorAll('.form-btn-primary');
  
  const customColor = formCustomStyles.button.color;
  const customBackground = formCustomStyles.button.backgroundColor;

  // Helper function to adjust color brightness
  const adjustColor = (color, amount) => {
    // Convert hex to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Adjust each component
    const newR = Math.min(255, Math.max(0, r + amount));
    const newG = Math.min(255, Math.max(0, g + amount));
    const newB = Math.min(255, Math.max(0, b + amount));

    // Convert back to hex
    return '#' + ((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1);
  };

  // Apply styles to all buttons
  buttons.forEach(button => {
    button.style.color = customColor;
    button.style.backgroundColor = customBackground;
    
    // Add hover effect
    button.addEventListener('mouseenter', () => {
      button.style.backgroundColor = adjustColor(customBackground, -20); // Slightly darker
      button.style.color = adjustColor(customColor, 20); // Slightly lighter
    });
    
    button.addEventListener('mouseleave', () => {
      button.style.backgroundColor = customBackground;
      button.style.color = customColor;
    });
  });
};

export const updateFormDataWithStyles = (formDataRef, customStyles, setFormData) => {
  const currentFormData = formDataRef.current;

  if (currentFormData.task_data) {
    const updatedTaskData = currentFormData.task_data.map((item) => {
      if (item.element === "Header") {
        const previousContent = item.content;
        if (!previousContent) return item;
        let updatedContent;

        if (previousContent.includes('<span')) {
          if (previousContent.includes('style=')) {
            // Check if the style includes color property
            updatedContent = previousContent.includes('color:')
              ? previousContent.replace(/color: #[0-9a-fA-F]{6}/, `color: ${customStyles.heading.color}`)
              : previousContent.replace(/style="(.*?)"/, `style="$1; color: ${customStyles.heading.color}"`);
          } else {
            // Add the style attribute with color property
            updatedContent = previousContent.replace('<span', `<span style="color: ${customStyles.heading.color};"`);
          }
        } else {
          // Wrap the content in a span tag, add the style
          updatedContent = `<span style="color: ${customStyles.heading.color};">${previousContent}</span>`;
        }

        return {
          ...item,
          content: updatedContent,
          background_color: customStyles.heading.backgroundColor,
        };
      }
      return item;
    });

    // Add/update the button style at the root level of formData
    const updatedFormData = {
      ...currentFormData,
      task_data: updatedTaskData,
      custom_styles: customStyles
    };

    formDataRef.current = updatedFormData;
    setFormData(updatedFormData);

    // Apply button styles
    applyButtonStyles(updatedFormData.task_data, customStyles);
  }
};

export const fetchAndSetFormData = async (formId, getUrl, setFormData, setCustomStyles, formDataRef, setLoading, customStyles, setIsFitslipEnabled, setCustomFormVersion) => {
  try {
    const url = getUrl(formId);
    const response = await axios.get(url);
    const formTemplate = response.data.form_template;
    const _formData = formTemplate ? formTemplate.task_data || [] : [];
    const _customStyles = formTemplate ? formTemplate.custom_styles || customStyles : customStyles;
    setIsFitslipEnabled(response.data.is_fitslip_enabled);
    setTimeout(() => {
      updateToggleFitslipState(response.data.is_fitslip_enabled);
    }, 1000);
    setFormData(_formData);
    setCustomStyles(_customStyles);
    setCustomFormVersion(response.data);
    formDataRef.current = { task_data: _formData };
    setLoading(false);
  } catch (error) {
    console.error('Failed to load form data:', error);
    setLoading(false);
  }
};

export const updateToggleFitslipState = (isEnabled) => {
  const toggleElement = document.getElementById('fitslip-toggle');
  if (toggleElement) {
    toggleElement.checked = isEnabled;
    const slider = toggleElement.nextElementSibling;
    const circle = slider.querySelector('span');
    
    if (isEnabled) {
      slider.style.backgroundColor = '#319795';
      circle.style.transform = 'translateX(16px)';
    } else {
      slider.style.backgroundColor = '#CBD5E0';
      circle.style.transform = 'translateX(0)';
    }
  }
};

export const formatLocalTime = (dateString) => {
  return moment.utc(dateString).local().format('DD/MM/YYYY h:mm A');
};

export const doctorReviewChecklists =  [
  {
    value: 'is_suitable',
    label: '<b>SUITABLE:</b> Candidate has been assessed as suitable to safely carry out the duties of the proposed employment role without significant risk to self or others with appropriate OH&S training.'
  },
  {
    value: 'is_suitable_with_risk',
    label: '<b>SUITABLE WITH INCREASED RISK:</b> Candidate has been assessed as suitable for the proposed employment role, although may be at increased risk for the below reasons.'
  },
  {
    value: 'is_suitable_with_restrictions',
    label: '<b>SUITABLE WITH RESTRICTIONS:</b> Candidate has been assessed as suitable for the proposed employment role with the below restrictions.'
  },
  {
    value: 'is_unsuitable',
    label: '<b>UNSUITABLE:</b> Candidate had been assessed as currently not able to safely carry out routine duties that are associated to the proposed employment role without having significant risk to themselves and / or others for the below reasons.'
  },
  {
    value: 'is_assessment_on_hold',
    label: '<b>ASSESSMENT ON HOLD:</b> Final decision requires further assessment/information for complete, fair and accurate assessment:',
    subChecklists: [
      {
        value: 'is_gp_requested',
        label: 'Request for further information from GP/Specialist',
        descriptionValue: 'gp_description'
      },
      {
        value: 'is_musculoskeletal_test_requested',
        label: 'Further musculoskeletal/fitness testing recommended:',
        descriptionValue: 'musculoskeletal_description'
      },
      {
        value: 'is_other_testing_requested',
        label: 'Other health/medical testing required:',
        descriptionValue: 'other_testing_description'
      },
    ]
  },
];
