<template>
  <div>
    <div class="col-md-12 pt-3 d-flex align-items-end">
      <candidate-invoices-modal
        v-if="showInvoiceModal"
        @close="selected_invoice = {}; showInvoiceModal = false"
        @added="onAdd"
        @deleted="onDelete"
        :initial_invoice="selected_invoice"
        :companies="companies"
        :invoice_payment_types="invoice_payment_types"
        :candidate="candidate"
        :xero_accounts="xero_accounts"
      ></candidate-invoices-modal>
    </div>
    <div class="col-md-12">
      <div class="table-responsive" style="max-height: 400px; overflow-y: scroll;">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Ref</th>
              <th>Company</th>
              <th>Status</th>
              <th>Total (excl. GST)</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(invoice, index) in invoices" :key="index">
              <td>
                <span>{{invoice.xero_id}}</span>
              </td>
              <td>
                <span>{{invoice.name}}</span>
              </td>
              <td>
                <span>{{invoice.company.name}}</span>
              </td>
              <td>
                <span>{{invoice.status}}</span>
              </td>
              <td>
                <span>{{invoice.dollar_total.formatted}}</span>
              </td>
              <td>
                <button
                  class="blue-icon-btn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View"
                  @click="candidate = invoice.candidate; selected_invoice = invoice; showInvoiceModal = true"
                >
                  <i class="fas fa-eye fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recruiter: {
      type: Object
    },
    companies:{
        type: Array
    },
    xero_accounts: { 
        type: Array
    },
    invoice_payment_types: { 
        type: Array
    }
  },
  data() {
    return {
      showInvoiceModal: false,
      selected_invoice: {},
      invoices: this.recruiter.invoices,
      candidate:'',
    };
  },
  methods: {
    onAdd(invoice) {
      this.invoices.push(invoice);
    },
    onDelete(passed_invoice)
    {
      this.invoices = this.invoices.filter(x => x.id != passed_invoice.id);
    }
  }
};
</script>

<style>
</style>
