<template>
<div>

    <div class="row mt-3 mx-3" id="mk-candidate-index">
        <div class="col-md-12 px-0">
            <a :href="`/admin/companies/${company_id}/export/candidates?status=${candidate_status_id}`" class="jem-btn-primary float-right ml-2">Export Candidates</a>

            <a :href="`/admin/companies/${company_id}/export/candidate-timelines?status=${candidate_status_id}&dates=${date}`" class="jem-btn-primary float-right">Export Timelines</a>

            <div class="col-md-3 float-right">
                <el-date-picker
                class="w-100"
                v-model="date"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                start-placeholder="Start date"
                end-placeholder="End date"
                ></el-date-picker>
            </div>

            <el-select v-model="candidate_status_id" @change="getCandidates(1)" placeholder="Status" clearable>
                <el-option v-for="(status, index) in candidate_statuses" :key="index" :label="status.name" :value="status.id"></el-option>
            </el-select>

        </div>
    </div>

  <paginated-table
    v-if="candidates.data"
    :tableData="tableData"
    :paginatedData="candidates"
    @pagination-change-page="getCandidates"
  />

</div>
</template>

<script>
import Candidate from "../../models/Candidate";

export default {
    mounted() {
        this.getCandidates();
    },
    props:{
        user_id:{
            type: Number
        },
        candidate_statuses:{
            type: Array
        },
        initial_candidate_status_id:{
            type: Number,
            default: 0,
        },
        company_id:{
            type: Number,
            default: 0,
        },
    },
    data(){
        return {
            candidates: {},
            date: [],
            candidate_status_id:this.initial_candidate_status_id ? this.initial_candidate_status_id : '',
            showAll:false,
            tableData: {
                columns:
                [
                    { label: "First Name", name: "first_name", sortable: true },
                    { label: "Last Name", name: "last_name", sortable: true },
                    { label: "Email", name: "email", sortable: true },
                    { label: "PO Number", name: "po_number", sortable: true },
                    { label: "Priority", name: "priority", sort_name: 'urgent', sortable: true },
                    { label: "Site Code", name: "site_code", sortable: true },
                    { label: "Status", name: "candidate_status.name", sortable: true },
                    { label: "Created At", name: "created_at", date:true, sortable: true },
                ],
                edit:true,
                showSearch: true,
                showPerPage: true,
                viewUrl: "/admin/candidates"
            }
        }
    },
    methods: {
        getCandidates(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
            let candidates = Candidate.page(page).include('candidate-status')
            if(this.user_id){
                candidates.where('user_id', this.user_id);
            }
            if(this.company_id != ''){
                candidates.where('company', this.company_id);
            }
            if(desc && sortColumn) {
                candidates.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
            } else if (sortColumn) {
                candidates.orderBy(sortColumn.sort_name || sortColumn.name);
            } else {
                candidates.orderBy('-created_at');
            }

            if (query) {
                candidates.where("where_like", query);
            }

            if(this.candidate_status_id) {
                candidates.where('candidate_status_id', this.candidate_status_id)
            }

            candidates.get().then((res) => {
                this.candidates = res;
            })
        },
    }
}
</script>

<style>
</style>
