<template>
  <el-dialog
    :visible="isVisible"
    title="Services enabled"
    width="40%"
    custom-class="services-modal"
    @close="handleClose"
    
  >
    <p class="text-gray-600 mb-3">Enabled services:</p>
    <div class="services-box">
      <ul>
        <li v-for="(item, index) in items" :key="index" class="py-1">
          {{ item }}
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ViewMoreModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isVisible() {
      return this.show;
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false);
    }
  }
}
</script>

<style scoped lang="scss">
.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.services-box {
  max-height: 400px;
  overflow-y: auto;
  padding: 12px;
  border-radius: 6px;
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 3px;
  }
}

.py-1 {
  padding: 4px 0;
}

.text-gray-600 {
  color: #171923;
}

.mb-3 {
  margin-bottom: 1rem;
}

.el-dialog__body {
  padding-top: 15px;
  padding-bottom: 15px;
}

.services-modal {
  min-width: 500px;
  min-height: 300px;
  
  :deep(.el-dialog__body) {
    padding: 20px 24px;
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>