<template>
  <div id="mk-be-candidate-form">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <div>
          <h1 class="d-inline mr-2">{{candidate.first_name || 'Candidate'}} {{candidate.last_name}}</h1>
          <span v-if="candidate.no_contact" style="color:red">Candidate is set to not get automated communications from the portal.</span>
        </div>
        <div>
          <button v-if="candidate.id" class="jem-btn-danger" @click="onDelete">Delete</button>
          <button v-if="candidate.id && !candidate.no_contact" class="jem-btn-primary" @click="showSmsModal = true">Send SMS</button>
          <button v-if="candidate.id && !candidate.no_contact" class="jem-btn-primary" @click="showEmailModal = true">Send Email</button>
          <button class="jem-btn-primary" @click="onSubmit">Save</button>
        </div>
        <send-sms-modal @close="showSmsModal = false" :candidate="candidate" v-if="showSmsModal"></send-sms-modal>
        <send-email-modal
          @close="showEmailModal = false"
          :candidate="candidate"
          v-if="showEmailModal"
        ></send-email-modal>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <small
          v-if="candidate.first_follow_up_sent_at"
        >First Reminder Sent At: {{moment.utc(candidate.first_follow_up_sent_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY HH:mm:ssa')}}</small>
      </div>
      <div class="col-md-12">
        <small
          v-if="candidate.second_follow_up_sent_at"
        >Second Reminder Sent At: {{moment.utc(candidate.second_follow_up_sent_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY HH:mm:ssa')}}</small>
      </div>
      <div class="col-md-12">
        <small
          v-if="candidate.incomplete_pending_further_results_at"
        >Incomplete Pending Further Results At: {{moment.utc(candidate.incomplete_pending_further_results_at, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY HH:mm:ssa')}}</small>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label for="name">First Name</label>
        <div class="input-group">
          <input
            type="text"
            name="first_name"
            :class="[{ 'is-invalid': form.errors.has('first_name') }, 'form-control']"
            id="first-name-input"
            @keydown="form.errors.clear('first_name');"
            v-model="candidate.first_name"
            placeholder="First Name"
          />
          <div class="invalid-feedback">Please enter a name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Last Name</label>
        <div class="input-group">
          <input
            type="text"
            name="last_name"
            :class="[{ 'is-invalid': form.errors.has('last_name') }, 'form-control']"
            id="last_name-input"
            @keydown="form.errors.clear('last_name');"
            v-model="candidate.last_name"
            placeholder="Name"
          />
          <div class="invalid-feedback">Please enter a last name.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Position Applied For</label>
        <div class="input-group">
          <input
            type="text"
            name="last_name"
            :class="[{ 'is-invalid': form.errors.has('position_applied_for') }, 'form-control']"
            id="position_applied_for-input"
            @keydown="form.errors.clear('position_applied_for');"
            v-model="candidate.position_applied_for"
          />
          <div class="invalid-feedback">Please enter a position.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Email</label>
        <div class="input-group">
          <input
            type="text"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('email') }, 'form-control']"
            id="email-input"
            @keydown="form.errors.clear('email');"
            v-model="candidate.email"
            placeholder="Email"
          />
          <div class="invalid-feedback">Please provide a valid email</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Number</label>
        <div class="input-group">
          <vue-tel-input
            @onInput="form.errors.clear('number');"
            :class="[{ 'is-invalid': form.errors.has('number') }, 'w-100']"
            :onlyCountries="['AU', 'GB', 'NZ', 'US']"
            v-model="candidate.number"
          ></vue-tel-input>
          <div class="invalid-feedback">Please provide a valid number</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">PO Number</label>
        <div class="input-group">
          <input
            type="text"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('po_number') }, 'form-control']"
            id="email-input"
            @keydown="form.errors.clear('po_number');"
            v-model="candidate.po_number"
            placeholder="PO Number"
          />
          <div class="invalid-feedback">Please provide a valid PO Number</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Site Code</label>
        <div class="input-group">
          <input
            type="text"
            name="email"
            :class="[{ 'is-invalid': form.errors.has('site_code') }, 'form-control']"
            id="email-input"
            @keydown="form.errors.clear('site_code');"
            v-model="candidate.site_code"
            placeholder="Site code"
          />
          <div class="invalid-feedback">Please provide a valid Site Code</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Status</label>
        <div class="input-group">
          <el-select
            v-model="candidate.candidate_status_id"
            placeholder="Candidate Status"
            :class="[{ 'is-invalid': form.errors.has('candidate_status_id') }, 'w-100']"
          >
            <el-option
              v-for="item in candidate_statuses"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div
            v-if="form.errors.has('candidate_status_id')"
            class="invalid-feedback"
          >Please enter a candidate status.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Recruiter</label>
        <div class="input-group">
          <el-select class="w-100" filterable v-model="candidate.user_id" placeholder="Recruiter">
            <el-option
              v-for="item in recruiters"
              :key="item.id"
              :label="item.full_name + ' - ' + (item.company ? item.company.name : 'N/A')"
              :value="item.id"
            ></el-option>
          </el-select>
          <div
            v-if="form.errors.has('user_id')"
            class="invalid-feedback"
          >Please enter a recruiter to link this candidate to.</div>
        </div>
      </div>
      <div class="form-group col-12 col-md-2">
        <label for="name">Preferences</label>
        <div class="input-group">
          <el-checkbox v-model="candidate.urgent">Urgent</el-checkbox>
        </div>
      </div>
      <div class="form-group col-12 col-md-2">
        <label for="name"></label>
        <div class="input-group">
          <el-checkbox v-model="candidate.no_contact">Do Not Communicate</el-checkbox>
        </div>
      </div>
      <div class="form-group col-12 col-md-8">
        <label for="notes">Recruiter Notes</label>
        <textarea v-model="candidate.notes" class="form-control"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-12">
        <label for="notes">Internal Notes</label>
        <textarea v-model="candidate.internal_notes" class="form-control" rows="5"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-12">
        <label for="notes">On Hold / Results Follow Up Notes</label>
        <textarea v-model="candidate.on_hold_notes" class="form-control" rows="5"></textarea>
      </div>
    </div>

    <hr class="my-5" />
    <div class="row">
      <div class="col-md-12 mb-2">
        <h3>Booking Preferences</h3>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="name">Location</label>
        <div class="input-group">
          <input
            type="text"
            name="location"
            :class="[{ 'is-invalid': form.errors.has('location') }, 'form-control']"
            id="last_name-input"
            @keydown="form.errors.clear('location');"
            v-model="candidate.location"
            placeholder="Name"
          />
          <div class="invalid-feedback">Please enter a location.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Preferred Time</label>
        <div class="input-group">
          <input
            type="text"
            name="preferred_time"
            :class="[{ 'is-invalid': form.errors.has('preferred_time') }, 'form-control']"
            id="preferred_time-input"
            @keydown="form.errors.clear('preferred_time');"
            v-model="candidate.preferred_time"
            placeholder="Name"
          />
          <div class="invalid-feedback">Please enter a preferred time.</div>
        </div>
      </div>

      <div class="form-group col-12 col-md-4">
        <label for="name">Preferred Day</label>
        <div class="input-group">
          <input
            type="text"
            name="day"
            :class="[{ 'is-invalid': form.errors.has('preferred_day') }, 'form-control']"
            id="preferred_day-input"
            @keydown="form.errors.clear('preferred_day');"
            v-model="candidate.preferred_day"
          />
          <div class="invalid-feedback">Please enter a day.</div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row pt-3 mb-5">
      <div class="col-md-12">
        <tabs>
          <tab name="Requested Services">
            <candidate-services-form
              :candidate="candidate"
              @changed="onCandidateServicesChanged"
              :booking_types="booking_types"
              :services="services"
              :calendars="calendars"
            ></candidate-services-form>
          </tab>
          <tab v-if="candidate.id" name="Booked Services">
            <candidate-booked-services-form
              :calendars="calendars"
              :candidate="candidate"
              :services="services"
            ></candidate-booked-services-form>
          </tab>
          <tab v-if="candidate.id" name="Timeline">
            <candidate-timeline :candidate="candidate" :is_admin="true"></candidate-timeline>
          </tab>
          <tab v-if="candidate.id && invoice_enabled" name="Invoices">
            <candidate-invoice
              :xero_accounts="xero_accounts"
              :invoice_payment_types="invoice_payment_types"
              :candidate="candidate"
              :booking_types="booking_types"
              :companies="companies"
            ></candidate-invoice>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>


<script>
import Candidate from "../../models/Candidate";
import Form from "form-backend-validation";
import VueTelInput from "vue-tel-input";

export default {
  components: {
    VueTelInput
  },
  props: {
    initial_candidate: {
      type: Object
    },
    companies: {
      type: Array
    },
    initial_recruiter: {
      type: Object
    },
    calendars: {
      type: Array
    },
    recruiters: {
      type: Array
    },
    candidate_statuses: {
      type: Array
    },
    invoice_payment_types: {
      type: Array
    },
    services: {
      type: Array
    },
    booking_types: {
      type: Array
    },
    xero_accounts: {
      type: Array
    }
  },
  data() {
    return {
      candidate: new Candidate(
        this.initial_candidate || {
          urgent: 0,
          user_id: this.initial_recruiter,
          booking_types: [],
          booked_services: []
        }
      ),
      invoice_enabled: process.env.MIX_INVOICES_ENABLED == "true",
      form: new Form(),
      showSmsModal: false,
      showEmailModal: false
    };
  },
  methods: {
    onSubmit() {
      this.candidate
        .save()
        .then(res => {
          this.$message({
            type: "success",
            message: "Saved"
          });
          this.candidate = new Candidate(res);
          var url = window.location.toString();
          url = url.replace("create", `${res.id}/edit`);
          window.history.pushState(null, null, url);
        })
        .catch(errors => {
          this.form.errors.record(errors.response.data.errors);
        });
    },
    onDelete() {
      this.$confirm(
        "You are about to delete the candidate. This action is irreversible. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.candidate.delete().then(res => {
            window.location = "/admin/candidates";
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Candidate not deleted."
          });
        });
    },
    onCandidateServicesChanged(services) {
      this.candidate.booking_types = services;
    }
  }
};
</script>
