import React, { useState, useRef, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    HStack,
    Icon,
    Text,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import { FaFilePdf, FaFileImage, FaFileUpload, FaTimes } from 'react-icons/fa';
import useUploadReport from '../../../hooks/useUploadReport';
import useEditReport from '../../../hooks/useEditReport';

const CandidateReportModal = ({ isOpen, onClose, doctorReview, report, setReports, reports }) => {
    const [description, setDescription] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef();
    const toast = useToast();
    const uploadReport = useUploadReport();
    const editReport = useEditReport();

    useEffect(() => {
        if (report) {
            setDescription(report.description);
            setSelectedFiles(report.attachments);
        } else {
            setDescription('');
            setSelectedFiles([]);
            setFilesToDelete([]);
        }
    }, [report]);

    const handleFileChange = (e) => {
        setSelectedFiles((files) => [...files, ...Array.from(e.target.files)]);
    };

    const removeFile = (index) => {
        setSelectedFiles((files) => files.filter((_, i) => i !== index));
        setFilesToDelete((files) => [...files, selectedFiles[index]]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            if (report) {
                await editReport(description, doctorReview, selectedFiles, filesToDelete, report, setReports, reports, onClose);
            } else {
                await uploadReport(description, doctorReview, selectedFiles, setReports, reports, onClose);
            }
            toast({
                title: `Report ${report ? 'updated' : 'uploaded'} successfully.`,
                status: 'success',
                duration: 3000,
                position: 'top',
            });
            onClose();
        } catch (error) {
            toast({
                title: `Error ${report ? 'updating' : 'uploading'} report.`,
                status: 'error',
                duration: 3000,
                position: 'top',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottomWidth="1px" borderColor="gray.200">
                    {report ? 'Edit Report' : 'Upload Report'}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <VStack spacing={4} align="stretch">
                        <FormControl isRequired>
                            <FormLabel>Description</FormLabel>
                            <Input
                                placeholder="Enter report description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel>Attachments</FormLabel>
                            <Input
                                type="file"
                                multiple
                                accept=".pdf,.jpg,.png"
                                onChange={handleFileChange}
                                hidden
                                ref={fileInputRef}
                            />
                            <Button
                                onClick={() => fileInputRef.current.click()}
                                leftIcon={<FaFileUpload />}
                                width="100%"
                                variant="outline"
                            >
                                Choose Files
                            </Button>
                            {selectedFiles.length > 0 && (
                                <VStack mt={2} align="stretch">
                                    {selectedFiles.map((file, index) => (
                                        <HStack key={index} justify="space-between">
                                            <HStack>
                                                <Icon
                                                    as={file.type.includes('pdf') ? FaFilePdf : FaFileImage}
                                                    color={file.type.includes('pdf') ? 'red.500' : 'blue.500'}
                                                />
                                                <Text fontSize="sm">{file.name}</Text>
                                            </HStack>
                                            <IconButton
                                                icon={<FaTimes />}
                                                size="sm"
                                                onClick={() => removeFile(index)}
                                            />
                                        </HStack>
                                    ))}
                                </VStack>
                            )}
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                        Cancel
                    </Button>
                    <Button colorScheme="teal" onClick={handleSubmit} isLoading={isLoading}>
                        {report ? 'Update' : 'Upload'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CandidateReportModal;