import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, InputGroup, InputRightElement, Icon, Input } from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { FaAngleDown, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import User from '../../../backend/models/User';
import Location from '../../../backend/models/Location';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

const DoctorReviewFilters = ({
    selectedDoctor,
    setSelectedDoctor,
    selectedLocation,
    setSelectedLocation,
    searchQuery,
    setSearchQuery
}) => {
    const [uniqueDoctors, setUniqueDoctors] = useState([]);
    const [uniqueLocations, setUniqueLocations] = useState([]);

    const getDoctors = async () => {
        const params = {
            page: 1,
            per_page: 1000
        };
        const response = await User.params(params).where("permission", "Manage Doctor").get();
        setUniqueDoctors(response.data);
    };

    const getLocations = async () => {
        const params = {
            page: 1,
            per_page: 1000,
        };
        const response = await Location.params(params).get();
        setUniqueLocations(response.data);
    };

    useEffect(() => {
        getDoctors();
        getLocations();
    }, []);

    return (
        <HStack spacing={4} mb={4}>
            <Box>
                <Text mb={2}>Search</Text>
                <InputGroup>
                    <Input
                        variant="filled"
                        placeholder="Search"
                        sx={{
                            border: 'solid 1px #CBD5E0',
                            borderRadius: '8px',
                            width: '320px',
                            height: '41px',
                            backgroundColor: '#FFFFFF'
                        }}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                    />
                    <InputRightElement height="100%">
                        <Icon as={SearchIcon} color="#718096" />
                    </InputRightElement>  
                </InputGroup>
            </Box>
            <Box>
                <Text mb={2}>Assigned to</Text>
                <DoctorSelect 
                    doctors={uniqueDoctors} 
                    onChange={(value) => setSelectedDoctor(value)} 
                    defaultValue={selectedDoctor}
                />
            </Box>
            <Box>
                <Text mb={2}>Location</Text>
                <LocationSelect 
                    locations={uniqueLocations} 
                    onChange={(value) => setSelectedLocation(value)} 
                    defaultValue={selectedLocation}
                />
            </Box>
        </HStack>
    );
};

export default DoctorReviewFilters;

const DoctorSelect = ({ 
    doctors, 
    onChange, 
    defaultValue 
}) => {
    return (
        <AutoComplete 
            rollNavigation
            openOnFocus
            onChange={onChange}
            value={defaultValue}
        >
            {({ isOpen }) => (
                <>
                    <InputGroup>
                        <AutoCompleteInput 
                            variant="filled"
                            placeholder="All doctors"
                            sx={{
                                border: 'solid 1px #CBD5E0',
                                borderRadius: '8px',
                                width: '320px',
                                height: '41px',
                                backgroundColor: '#FFFFFF',
                                paddingRight: '60px'
                            }}
                        />
                        <InputRightElement height="100%" right={2}>
                            <HStack spacing={2}>
                                {defaultValue && (
                                    <Icon 
                                        as={CloseIcon} 
                                        color="#718096" 
                                        w={3} 
                                        h={3} 
                                        cursor="pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChange('');
                                        }}
                                    />
                                )}
                                <Icon as={isOpen ? FaAngleUp : FaAngleDown} color="#718096" />
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                    <AutoCompleteList>
                        {doctors.map((doctor, cid) => (
                            <AutoCompleteItem
                                key={`option-${cid}`}
                                value={doctor}
                                label={doctor.full_name}
                            >
                                {doctor.full_name}
                            </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                </>
            )}
        </AutoComplete>
    );
}

const LocationSelect = ({ 
    locations, 
    onChange, 
    defaultValue 
}) => {
    return (
        <AutoComplete 
            rollNavigation
            openOnFocus
            onChange={onChange}
            value={defaultValue}
        >
            {({ isOpen }) => (
                <>
                    <InputGroup>
                        <AutoCompleteInput 
                            variant="filled"
                            placeholder="All locations"
                            sx={{
                                border: 'solid 1px #CBD5E0',
                                borderRadius: '8px',
                                width: '320px',
                                height: '41px',
                                backgroundColor: '#FFFFFF',
                                paddingRight: '60px'
                            }}
                        />
                        <InputRightElement height="100%" right={2}>
                            <HStack spacing={2}>
                                {defaultValue && (
                                    <Icon 
                                        as={CloseIcon} 
                                        color="#718096" 
                                        w={3} 
                                        h={3} 
                                        cursor="pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChange('');
                                        }}
                                    />
                                )}
                                <Icon as={isOpen ? FaAngleUp : FaAngleDown} color="#718096" />
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                    <AutoCompleteList>
                        {locations.map((location, cid) => (
                            <AutoCompleteItem
                                key={`option-${cid}`}
                                value={location}
                                label={location.title}
                            >
                                {location.title}
                            </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                </>
            )}
        </AutoComplete>
    );
}