<template>
  <div class="row">
    <div v-if="overdue_candidates.length > 0" class="col-md-4">
      <h4>Overdue – Standard New Requests (7.5hrs from request)</h4>
      <dashboard-box :candidates="overdue_candidates" />
    </div>
    <div v-if="urgent_overdue_candidates.length > 0" class="col-md-4">
      <h4>Overdue – Urgent New Requests (2 hrs from request)</h4>
      <dashboard-box :candidates="urgent_overdue_candidates" />
    </div>
    <div v-if="on_hold_candidates.length > 0" class="col-md-4">
      <h4>On Hold – Follow up required (3 days from being marked as on hold sent)</h4>
      <dashboard-box :candidates="on_hold_candidates" />
    </div>
    <div v-if="booked_service_overdue_candidates.length > 0" class="col-md-4">
      <h4>Overdue Alliance Medicals – Follow up results (24 hrs from appointment time)</h4>
      <dashboard-box :candidates="booked_service_overdue_candidates" />
    </div>
    <div v-if="pending_bloods_candidates.length > 0" class="col-md-4">
      <h4>Bloods not received – Follow up</h4>
      <dashboard-box :candidates="pending_bloods_candidates" />
    </div>
    <div v-if="pending_das_candidates.length > 0" class="col-md-4">
      <h4>DAS not received – follow up (24 hrs from apt time)</h4>
      <dashboard-box :candidates="pending_das_candidates" />
    </div>
    <div v-if="incomplete_candidates.length > 0" class="col-md-4">
      <h4>Additional tests not received – Follow up (24 hrs from apt time)</h4>
      <dashboard-box :candidates="incomplete_candidates" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    overdue_candidates: {
      type: Array
    },
    booked_service_overdue_candidates: {
      type: Array
    },
    urgent_overdue_candidates: {
      type: Array
    },
    on_hold_candidates: {
      type: Array
    },
    pending_bloods_candidates: {
      type: Array
    },
    pending_das_candidates: {
      type: Array
    },
    incomplete_candidates: {
      type: Array
    }
  },
  data() {
    return {
      //
    };
  }
};
</script>