import React, { useState } from 'react';
import { VStack, Heading, Text, Button } from '@chakra-ui/react';

const CandidateCustomFormTableOfContent = ({ customFormResult, title }) => {
    const [activeHeading, setActiveHeading] = useState(null);

    // Had to use jquery because i didn't want to change the form builder library
    const handleScroll = (content) => {
        document.querySelectorAll('.SortableItem').forEach(item => {
            if (item?.textContent === stripHtml(content)) {
                item.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        });
    };
    const stripHtml = (html) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <VStack align="stretch">
            <Heading fontSize="md" mb={2}>{title}</Heading>
            <Text fontSize="sm" color="gray.500" my={1}>Jump to section</Text>
            <VStack align="stretch">
                {customFormResult.custom_form_version.form_template.task_data
                    .filter(section => section.element === 'Header')
                    .map((section, index) => (
                        <Button 
                            key={`headings-${index}`}
                            fontSize="sm"
                            colorScheme="teal"
                            textAlign="left"
                            justifyContent="flex-start"
                            variant={activeHeading === index ? 'solid' : 'ghost'}
                            onClick={() => {
                                setActiveHeading(index);
                                handleScroll(section.content);
                            }}
                        >
                            {stripHtml(section.content)}
                        </Button>  
                    ))
                }
            </VStack>
        </VStack>
    );
};

export default CandidateCustomFormTableOfContent;