import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    VStack,
    Heading,
    Flex,
    Spinner,
    useToast,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import DoctorReviewTabPanel from './DoctorReviewTabPanel';
import DoctorReview from '../../../backend/models/DoctorReview';
import User from '../../../backend/models/User';
import DoctorReviewStatus from '../../../enums/DoctorReviewStatus';
import debounce from 'lodash/debounce';
import { orderBy } from 'lodash';

const DoctorReviewIndex = ({ initialDoctorReviews, initialDoctorReviewStatusId }) => {
    const toast = useToast();
    const [doctorReviews, setDoctorReviews] = useState(initialDoctorReviews);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentTab, setCurrentTab] = useState(() => {
        const statusToTabIndex = {
            [DoctorReviewStatus.PENDING]: 0,
            [DoctorReviewStatus.MORE_INFO_REQUIRED]: 1,
            [DoctorReviewStatus.PENDING_REPORTS]: 2,
            [DoctorReviewStatus.FINALISED]: 3
        };
        return statusToTabIndex[initialDoctorReviewStatusId] || 0;
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const itemsPerPage = 25;

    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(
        initialDoctorReviewStatusId || DoctorReviewStatus.PENDING
    );
    const [loading, setLoading] = useState(true);

    // Update debouncedFetch to use the passed query parameter
    const debouncedFetch = useCallback(
        debounce((query) => {
            const params = {
                'filter[query]': query,
                page: currentPage,
                per_page: itemsPerPage,
                'filter[doctor_id]': selectedDoctor || undefined,
                'filter[location_id]': selectedLocation || undefined,
                'filter[doctor_review_status_id]': selectedStatus,
                include: 'candidate,location,doctor,bookedService,bookingType,doctorReviewStatus',
                sort: '-candidate.is_urgent_results,-doctor_reviews.created_at'
            };
            fetchDoctorReviews(params);
        }, 500),
        [currentPage, selectedDoctor, selectedLocation, selectedStatus]
    );

    useEffect(() => {
        if (searchQuery !== undefined) {
            debouncedFetch(searchQuery);
        }
        return () => {
            debouncedFetch.cancel();
        };
    }, [searchQuery]);

    // Fetch doctor reviews on param change
    useEffect(() => {
        fetchDoctorReviews();
    }, [currentPage, selectedDoctor, selectedLocation, selectedStatus]);

    // Update fetchDoctorReviews to accept params
    const fetchDoctorReviews = async (params = null) => {
        setLoading(true);
        try {
            const defaultParams = {
                'filter[query]': searchQuery,
                page: currentPage,
                per_page: itemsPerPage,
                'filter[doctor_id]': selectedDoctor || undefined,
                'filter[location_id]': selectedLocation || undefined,
                'filter[doctor_review_status_id]': selectedStatus,
                include: 'candidate,location,doctor,bookedService,bookingType,doctorReviewStatus',
                sort: '-candidate.is_urgent_results,-doctor_reviews.created_at'
            };
            const response = await DoctorReview.params(params || defaultParams).get();
            setLastPage(response.last_page);
            setDoctorReviews(response.data);
        } catch (error) {
            toast({
                title: 'Error fetching doctor reviews',
                description: 'An error occurred while fetching doctor reviews. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const tabStyles = {
        border: "1px",
        borderColor: "gray.200",
        fontWeight: 700,
        color: "secondary.main",
        bg: "bgDefault.main",
        _selected: { 
            bg: 'teal.500',
            color: 'primary.text',
            borderColor: 'gray.200'
        },
        _focus: {
            boxShadow: 'none',
            outline: 'none'
        }
    };

    // Update the handleTabChange function
    const handleTabChange = (index) => {
        setCurrentTab(index);
        setCurrentPage(1);
        const statusMap = [
            DoctorReviewStatus.PENDING,
            DoctorReviewStatus.MORE_INFO_REQUIRED,
            DoctorReviewStatus.PENDING_REPORTS,
            DoctorReviewStatus.FINALISED
        ];
        const newStatus = statusMap[index];
        setSelectedStatus(newStatus);
        
        // Update URL without page reload
        const url = new URL(window.location);
        url.searchParams.set('doctor_review_status_id', newStatus);
        window.history.pushState({}, '', url);
    };

    return (
        <VStack align="stretch" p={7} gap={0}>
            <Heading size="lg" mb={4} color="textNeutral">
                Doctor Review
            </Heading>

            <Tabs 
                index={currentTab} 
                onChange={handleTabChange}
                variant="enclosed"
                colorScheme="teal"
                pt={4}
                rounded="md"
            >
                <TabList>
                    <Tab {...tabStyles}>Pending Review</Tab>
                    <Tab {...tabStyles}>More Info Required</Tab>
                    <Tab {...tabStyles}>Pending Reports</Tab>
                    <Tab {...tabStyles}>Finalised</Tab>
                </TabList>
            </Tabs>

            <Box px={4} p={6} bg="bgDefault.main" boxShadow="boxShadow.main">
                <DoctorReviewTabPanel
                    loading={loading}
                    doctorReviews={doctorReviews}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    itemsPerPage={itemsPerPage}
                    setCurrentPage={setCurrentPage}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    showComments={currentTab === 1 || currentTab === 2}
                    commentField='more_info_comments'
                    doctor_review_status_id={selectedStatus}
                />
            </Box>
        </VStack>
    );
};

export default DoctorReviewIndex;

