<template>
  <paginated-table
    v-if="services.data"
    :tableData="tableData"
    :paginatedData="services"
    @pagination-change-page="getServices"
  />
</template>

<script>
import Service from "../../models/Service";

export default {
  mounted() {
    this.getServices();
  },
  data() {
    return {
      services: {},
      tableData: {
        columns: [
          { label: "Name", name: "name", sortable: true },
        ],
        showSearch: true,
        showPerPage: true,
        edit:true,
        viewUrl: "/admin/services"
      }
    };
  },
  methods: {
    getServices(page = 1, sortColumn = null, query = null, per_page = 15, desc = false) {
      let services = Service.page(page).params({per_page:per_page});
      
      if(desc && sortColumn) {
        services.orderBy('-'+(sortColumn.sort_name || sortColumn.name));
      } else if (sortColumn) {
        services.orderBy(sortColumn.sort_name || sortColumn.name);
      } else {
        services.orderBy('name');
      }

      if (query) {
        services.where("where_like", query);
      }
      services.get().then(response => {
        this.services = response;
      });
    }
  }
};
</script>